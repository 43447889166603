import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { headerTitle } from '../../store/actions';
import { connect } from 'react-redux';
import {
  Grid,
  withStyles,
  CircularProgress,
  Typography,
  Link,
  Avatar,
} from '@material-ui/core';
import Axios from 'axios';
import { API_URL } from '../../config';
import MUIDataTable from 'mui-datatables';
import SelectSearch from '../../components/SelectSearch';
import { textLabels } from '../../locales/DataTables';
import DialogObra from './DialogObra';
import DialogObjeto from './DialogObjeto';
import DialogEspecialidad from './DialogEspecialidad';
import FormActividad from './FormActividad';
import moment from 'moment';
import EditActividad from './EditActividad';
import MaterialesActividad from '../MaterialesActividad';
import UsuariosActividad from '../UsuariosActividad';
import Reportes from '../Reportes';

class Planificacion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      obra: null,
      objeto: null,
      objetos: [],
      especialidad: null,
      especialidades: [],
      actividad: null,
      actividades: [],
      actividadesObra: [],
      tipos: [],
      reportes: [],
      reporte: null,
      materiales: [],
      material: null,
      reportesColumns: [
        {
          name: 'fecha',
          label: 'Fecha',
          options: {
            customBodyRender: (value, tableMeta, updateValue) =>
              moment(value).format('YYYY-MM-DD'),
          },
        },
        {
          name: 'valor',
          label: 'Costo Reportado',
          options: {
            customBodyRender: (value) => `$${value?.toFixed(2)}`,
          },
        },
        {
          name: 'foto',
          options: {
            filter: false,
            sort: false,
            empty: true,
            customBodyRender: (value, tableMeta, updateValue) => {
              return value ? (
                <Typography>
                  <Link href={value} target='_blank' rel='noopener'>
                    <Avatar alt={value} src={value} />
                  </Link>
                </Typography>
              ) : (
                ''
              );
            },
          },
        },
      ],
      actividadesColumns: [
        { name: 'tipoActividad.codigo', label: 'Código' },
        { name: 'tipoActividad.nombre', label: 'Actividad' },
        {
          name: 'inicio',
          label: 'Inicio',
          options: {
            customBodyRender: (value, tableMeta, updateValue) =>
              moment(value).format('YYYY-MM-DD HH:mm'),
          },
        },
        {
          name: 'fin',
          label: 'Fin',
          options: {
            customBodyRender: (value, tableMeta, updateValue) =>
              moment(value).format('YYYY-MM-DD HH:mm'),
          },
        },
        {
          name: 'plan',
          label: 'Plan',
          options: {
            customBodyRender: (value, tableMeta, updateValue) =>
              value?.toFixed(2),
          },
        },
        {
          name: 'real',
          label: 'Real',
          options: {
            customBodyRender: (value, tableMeta, updateValue) =>
              value?.toFixed(2),
          },
        },
        {
          name: 'costo',
          label: 'Costo',
          options: {
            customBodyRender: (value, tableMeta, updateValue) => '$' + value,
          },
        },
      ],
      materialesColumns: [
        { name: 'materiales_actividad.nombre', label: 'Material' },
        { name: 'materiales_actividad.unidadMedida.nombre', label: 'U/M' },
        { name: 'cantidad', label: 'Cantidad' },
      ],
      obraDialog: false,
      objetoDialog: false,
      especialidadDialog: false,
      loading: false,
    };
  }

  render() {
    const { classes, permissions } = this.props;

    const {
      loading,
      obra,
      obras,
      objeto,
      objetos,
      especialidad,
      especialidades,
      actividades,
      actividadesObra,
      actividad,
      tipos,
      actividadesColumns,
      obraDialog,
      objetoDialog,
      especialidadDialog,
    } = this.state;

    // console.log(actividad);
    return loading ? (
      <div className={classes.centered}>
        <CircularProgress />
      </div>
    ) : (
      <Grid container spacing={2}>
        {/* Filtros */}
        <Grid container spacing={2}>
          {/* Filtro Obras */}
          {obras && (
            <Grid item xs={12} md={3}>
              <SelectSearch
                label='Obra'
                opciones={obras}
                selected={obra}
                setSelected={(obra) => this.setObra(obra)}
                openDialog={(opened) => this.setState({ obraDialog: opened })}
                dialog={
                  permissions.crearObra && (
                    <DialogObra
                      getObras={this.getObras}
                      setObra={this.setObra}
                      open={obraDialog}
                      handleClose={() => this.setState({ obraDialog: false })}
                    />
                  )
                }
                granted={permissions.crearObra}
              />
            </Grid>
          )}
          {/* Filtro Objetos */}
          {obra && objetos && (
            <Grid item xs={12} md={3}>
              <Grid item>
                <SelectSearch
                  label='Objeto de Obra'
                  opciones={objetos && objetos}
                  selected={objeto && objeto}
                  setSelected={(objeto) => this.setObjeto(objeto)}
                  openDialog={(opened) =>
                    this.setState({ objetoDialog: opened })
                  }
                  dialog={
                    permissions.crearObjetoObra && (
                      <DialogObjeto
                        getObjetosObra={this.getObjetosObra}
                        setObjeto={this.setObjeto}
                        setObra={this.setObra}
                        open={objetoDialog}
                        obra={obra}
                        handleClose={() =>
                          this.setState({ objetoDialog: false })
                        }
                      />
                    )
                  }
                  granted={permissions.crearObjetoObra}
                />
              </Grid>
            </Grid>
          )}
          {/* Filtro Especialidades */}
          {obra && objeto && especialidades && (
            <Grid item xs={12} md={3}>
              <SelectSearch
                label='Especialidad'
                opciones={especialidades}
                selected={especialidad}
                setSelected={(esp) => this.setEspecialidad(esp)}
                openDialog={(opened) =>
                  this.setState({ especialidadDialog: opened })
                }
                dialog={
                  permissions.crearEspecialidad && (
                    <DialogEspecialidad
                      getEspecialidades={this.getEspecialidades}
                      setEspecialidad={this.setEspecialidad}
                      open={especialidadDialog}
                      handleClose={() =>
                        this.setState({ especialidadDialog: false })
                      }
                    />
                  )
                }
                granted={permissions.crearEspecialidad}
              />
            </Grid>
          )}
        </Grid>
        {/* Actividades Reportes y Materiales */}
        {obra && objeto && especialidad && (
          <Fragment>
            <Grid item xs={12} md={6}>
              {actividad ? (
                <EditActividad
                  actividad={actividad}
                  objeto={objeto}
                  tipos={tipos}
                  actividades={actividadesObra}
                  getActividades={() => this.getActividades(especialidad?.id)}
                  handleClose={() => this.setState({ actividadDialog: false })}
                />
              ) : (
                <FormActividad
                  objeto={objeto}
                  tipos={tipos}
                  especialidad={especialidad}
                  actividades={actividadesObra}
                  getActividades={() => this.getActividades(especialidad?.id)}
                />
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                {actividades && (
                  <Grid item xs={12}>
                    {permissions.listarActividad && (
                      <MUIDataTable
                        title={'Actividades'}
                        data={actividades}
                        columns={actividadesColumns}
                        options={this.optionsActividades}
                      />
                    )}
                  </Grid>
                )}
              </Grid>
            </Grid>
            {actividad?.id && (
              <Grid
                container
                direction='row'
                justify='flex-start'
                alignItems='stretch'
                spacing={2}
              >
                <Grid item md={5} lg={6}>
                  {permissions.listarMaterialesActividad && (
                    <MaterialesActividad actividad={actividad} />
                  )}
                </Grid>
                <Grid
                  item
                  md={permissions.listarMaterialesActividad ? 7 : 12}
                  lg={permissions.listarMaterialesActividad ? 6 : 12}
                >
                  {permissions.listarUsuariosActividad && (
                    <UsuariosActividad actividad={actividad} />
                  )}
                </Grid>
                <Grid item md={12}>
                  {permissions.listarReporte && (
                    <Reportes actividad={actividad} />
                  )}
                </Grid>
              </Grid>
            )}
          </Fragment>
        )}
      </Grid>
    );
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(headerTitle('Planificación'));
    this.getObras();
  }

  getObras = () => {
    this.setState({ loading: true, obra: null });
    const { token, userId, permissions } = this.props;
    let url = permissions.esAdmin
      ? '/obras'
      : permissions.esJefe
      ? `/obras_jefe/${userId}`
      : `/obras_usuario/${userId}`;
    Axios({
      method: 'get',
      url: `${API_URL}${url}`,
      headers: {
        token: token,
      },
    })
      .then((res) => {
        this.setState({ obras: res.data });
        this.setObra(res.data.length > 0 ? res.data[0] : []);
      })
      .catch((err) => console.log(err.message));
  };

  setObra = (obra) => {
    this.setState({ obra: null });
    this.setState({ obra: obra });
    obra?.id ? this.getObjetosObra(obra.id) : this.setState({ loading: false });
  };

  getObjetosObra = async (id) => {
    const { token } = this.props;
    await Axios({
      method: 'get',
      url: `${API_URL}/objetos_obra/obra/${id}`,
      headers: {
        token: token,
      },
    })
      .then((res) => {
        this.setState({ objetos: res.data });
        this.setObjeto(res.data.length > 0 ? res.data[0] : []);
      })
      .catch((err) => console.log(err.message));
  };

  setObjeto = (objeto) => {
    this.setState({ objeto: objeto });
    objeto?.id ? this.getEspecialidades() : this.setState({ loading: false });
  };

  getEspecialidades = async () => {
    const { token } = this.props;
    await Axios({
      method: 'get',
      url: `${API_URL}/especialidades`,
      headers: {
        token: token,
      },
    })
      .then((res) => {
        this.setState({ especialidades: res.data });
        this.setEspecialidad(res.data.length > 0 ? res.data[0] : []);
      })
      .catch((err) => console.log(err.message));
  };

  setEspecialidad = (especialidad) => {
    this.setState({
      especialidad: especialidad,
      actividad: null,
      reportes: false,
    });
    especialidad?.id
      ? this.getActividades(especialidad.id) && this.getTipos(especialidad.id)
      : this.setState({ loading: false });
  };

  getActividades = async (espId) => {
    const { token } = this.props,
      { objeto } = this.state,
      objId = objeto?.id;

    await Axios({
      method: 'get',
      url: `${API_URL}/actividades/objeto/${objId}/especialidad/${espId}`,
      headers: { token: token },
    })
      .then((res) => {
        this.setState({ actividades: res.data, loading: false });
        !(res.data.length > 0) &&
          this.setState({ actividad: null, reportes: [] });
      })
      .catch((err) => console.log(err.message));

    await Axios({
      method: 'get',
      url: `${API_URL}/actividades/obra/${objeto?.obraId}`,
      headers: { token: token },
    })
      .then((res) => {
        this.setState({ actividadesObra: res.data, loading: false });
        !(res.data.length > 0) &&
          this.setState({ actividad: null, reportes: [] });
      })
      .catch((err) => console.log(err.message));
  };

  setActividad = (index) => {
    const { actividades } = this.state;
    let act = actividades[index];
    act?.id && this.setState({ actividad: act, reportes: act.reportes });
  };

  editarActividad = (value) => {
    const { actividades } = this.state;
    var act = actividades.filter((a) => a.id === value);
    console.log(act);
    this.setState({ actividad: act, actividadDialog: true });
  };

  getTipos(espId) {
    const { token } = this.props;

    Axios({
      method: 'get',
      url: `${API_URL}/tipos_actividad/especialidad/${espId}`,
      headers: {
        token: token,
      },
    })
      .then((res) => {
        this.setState({ tipos: res.data });
      })
      .catch((error) => console.log(error));
  }

  setReporte = (index) => {
    const { reportes } = this.state;
    let rep = reportes[index];
    console.log(rep);
    this.setState({ reporte: rep, materiales: rep.materiales_reportes });
  };

  optionsActividades = {
    filter: true,
    selectableRows: 'single',
    filterType: 'dropdown',
    responsive: 'vertical',
    rowsPerPage: 5,
    selectableRowsOnClick: true,
    rowsPerPageOptions: [5, 10, 25],
    textLabels: textLabels,
    enableNestedDataAccess: '.',
    selectToolbarPlacement: 'none',
    onTableChange: (action, tableState) => {
      action === 'rowSelectionChange' &&
      tableState.selectedRows.data.length === 1
        ? this.setActividad(tableState.selectedRows.data[0].dataIndex)
        : this.setState({ actividad: null });
    },
  };
  optionsReportes = {
    filter: false,
    download: false,
    print: false,
    viewColumns: false,
    selectToolbarPlacement: 'none',
    selectableRows: 'single',
    filterType: 'dropdown',
    responsive: 'simple',
    selectableRowsOnClick: true,
    textLabels: textLabels,
    enableNestedDataAccess: '.',
    customToolbarSelect: () => undefined,
    onTableChange: (action, tableState) => {
      action === 'rowSelectionChange' &&
      tableState.selectedRows.data.length === 1
        ? this.setReporte(tableState.selectedRows.data[0].dataIndex)
        : this.setState({ reporte: { id: null } });
    },
  };
  optionsMateriales = {
    filter: false,
    download: false,
    print: false,
    viewColumns: false,
    selectableRows: 'none',
    filterType: 'dropdown',
    responsive: 'simple',
    selectableRowsOnClick: true,
    textLabels: textLabels,
    enableNestedDataAccess: '.',
    customToolbarSelect: () => undefined,
    onTableChange: (action, tableState) => {
      action === 'rowSelectionChange' &&
      tableState.selectedRows.data.length === 1
        ? this.setReporte(tableState.selectedRows.data[0].dataIndex)
        : this.setState({ reporte: { id: null } });
    },
  };
}

const styles = (theme) => ({
  centered: {
    marginTop: theme.spacing(20),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 300,
  },
  filterHeight: {
    height: 120,
  },
});

Planificacion.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    userId: state.auth.userId,
    permissions: state.auth.permissions,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(Planificacion));
