import React, { Component, Fragment } from 'react';
import axios from 'axios';

import {
  Button,
  DialogActions,
  TextField,
  DialogTitle,
  DialogContent,
  Dialog,
  InputLabel,
  FormControl,
  Typography,
  Grid,
  CircularProgress,
} from '@material-ui/core';

import MomentUtils from '@date-io/moment';
import moment from 'moment';
import 'moment/locale/es';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { connect } from 'react-redux';
import { API_URL } from '../../config';
import SimpleReactValidator from 'simple-react-validator';
import '../../locales/SimpleReactValidator';
import ImagePicker from '../../components/ImagePicker';
import MensajeFlotante from '../../components/MensajeFlotante';
import FormData from 'form-data';

moment.locale('es');

class ReporteForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.reporte.id,
      fecha: props.reporte.fecha,
      foto: props.reporte.foto,
      cantidadAnterior: props.reporte.cantidad,
      cantidad: props.reporte.cantidad,
      fotoUrl: props.reporte.fotoUrl,
      actividadId: props.reporte.actividadId,
      observaciones: props.reporte.observaciones,
      open: false,
      loading: false,
      actividades: [],
      participantes: [],
      usuarios: [],
      alerta: {
        tipo: '',
        mensaje: '',
      },
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validator = new SimpleReactValidator({ locale: 'es' });
  }

  render() {
    const {
      open,
      cantidad,
      fecha,
      foto,
      observaciones,
      alerta,
      loading,
    } = this.state;
    const { permissions, actividad } = this.props;

    return !permissions.crearReporte ? (
      <MensajeFlotante mensaje='Sin acceso' tipo='warning' />
    ) : (
      <Fragment>
        <Button
          variant='contained'
          color='primary'
          onClick={this.handleClickOpen}
          m={2}
        >
          Editar
        </Button>
        <Dialog
          fullWidth
          maxWidth='md'
          open={open}
          onClose={this.handleClose}
          aria-labelledby='form-dialog-title'
        >
          <DialogTitle id='form-dialog-title'>Editar Reporte</DialogTitle>
          <DialogContent>
            <Grid
              container
              display='flex'
              direction='row'
              justify='center'
              alignItems='baseline'
              spacing={2}
            >
              <Grid item sm={8}>
                <Grid
                  container
                  direction='row'
                  justify='center'
                  alignItems='flex-start'
                  spacing={2}
                >
                  {/* cantidad */}
                  <Grid item xs={3}>
                    <FormControl fullWidth>
                      <TextField
                        margin='dense'
                        id='cantidad'
                        name='cantidad'
                        label={
                          'Realizado (' +
                          actividad?.tipoActividad?.unidadMedida?.nombre +
                          ')'
                        }
                        type='number'
                        fullWidth
                        value={cantidad}
                        onChange={this.handleInputChange}
                      />
                      <Typography align='center' color='error'>
                        {this.validator.message(
                          'cantidad',
                          cantidad,
                          'numeric|min:0,num'
                        )}
                      </Typography>
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl fullWidth>
                      <TextField
                        margin='dense'
                        id='um'
                        name='um'
                        label='Pendiente'
                        type='text'
                        fullWidth
                        value={`${(actividad?.plan - actividad?.real).toFixed(
                          2
                        )} ${actividad?.tipoActividad?.unidadMedida?.nombre}`}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl fullWidth>
                      <TextField
                        margin='dense'
                        id='um'
                        name='um'
                        label='T. Ejecución (Hrs)'
                        type='text'
                        helperText='ejecutado - pendiente'
                        fullWidth
                        value={
                          (cantidad * actividad?.tipoActividad?.horas)?.toFixed(
                            2
                          ) +
                          ' - ' +
                          (actividad?.plan - actividad?.real) *
                            actividad?.tipoActividad?.horas
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl fullWidth>
                      <TextField
                        margin='dense'
                        id='valor'
                        name='valor'
                        label='Valor'
                        type='number'
                        fullWidth
                        value={(
                          cantidad * actividad?.tipoActividad?.tarifa
                        ).toFixed(2)}
                      />
                    </FormControl>
                  </Grid>
                  {/* fecha */}
                  <Grid item xs={6}>
                    <InputLabel id='fechalbl'>Fecha</InputLabel>
                    <FormControl fullWidth>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DateTimePicker
                          labelId='fechalbl'
                          name='fecha'
                          value={fecha}
                          onChange={(date) => this.handleDateChange(date)}
                          locale={'es'}
                          format={'YYYY-MM-DD hh:mm'}
                        />
                      </MuiPickersUtilsProvider>
                      <Typography align='center' color='error'>
                        {this.validator.message('fecha', fecha, 'required')}
                      </Typography>
                    </FormControl>
                  </Grid>

                  {/* observaciones */}
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <TextField
                        margin='dense'
                        id='observaciones'
                        name='observaciones'
                        label='Observaciones'
                        multiline
                        type='text'
                        fullWidth
                        value={observaciones}
                        onChange={this.handleInputChange}
                      />
                      <Typography align='center' color='error'>
                        {this.validator.message(
                          'observaciones',
                          observaciones,
                          'string|min:4|max:250'
                        )}
                      </Typography>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              {/* fotos */}
              <Grid item sm={4}>
                <FormControl fullWidth>
                  <ImagePicker
                    setFoto={this.setFoto}
                    nombre='Adjunto'
                    foto={foto}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleClose}
              variant={'outlined'}
              color='primary'
            >
              Cancelar
            </Button>
            <Button
              onClick={this.handleSubmit}
              variant={'contained'}
              color='primary'
              disabled={loading}
            >
              {loading ? <CircularProgress /> : 'Editar'}
            </Button>
          </DialogActions>
        </Dialog>
        {alerta.mensaje !== '' && (
          <MensajeFlotante mensaje={alerta.mensaje} tipo={alerta.tipo} />
        )}
      </Fragment>
    );
  }

  handleInputChange(event) {
    event.target.name !== 'cantidad'
      ? this.setState({ [event.target.name]: event.target.value })
      : event.target.value >= 0 &&
        this.setState({ [event.target.name]: event.target.value });
  }
  handleDateChange(event) {
    this.setState({
      fecha: moment(event).format('YYYY-MM-DD HH:mm:ss'),
    });
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    const {
      id,
      fecha,
      foto,
      observaciones,
      cantidad,
      cantidadAnterior,
    } = this.state;
    const { actividad, token, getReportes } = this.props;

    const bodyFormData = new FormData();
    bodyFormData.append('fecha', fecha);
    bodyFormData.append('foto', foto);
    bodyFormData.append('cantidad', cantidad);
    bodyFormData.append('valor', cantidad * actividad?.tipoActividad?.tarifa);
    bodyFormData.append('actividadId', actividad?.id);
    bodyFormData.append('observaciones', observaciones);

    if (this.validator.allValid()) {
      await axios({
        method: 'put',
        url: `${API_URL}/reportes/${id}`,
        data: bodyFormData,
        headers: {
          'Content-Type': 'multipart/form-data',
          token: token,
        },
      })
        .then(async (res) => {
          await axios({
            method: 'put',
            url: `${API_URL}/actividades/${res.data.actividadId}`,
            data: {
              ...actividad,
              ...{ real: actividad.real + cantidad - cantidadAnterior },
            },
            headers: {
              token: token,
            },
          }).catch((err) => console.log(err.message));
          this.setState({ open: false, loading: false });
          getReportes();
        })
        .catch((error) => console.log(error.message));
    } else {
      this.validator.showMessages();
      this.forceUpdate();
      this.setState({ loading: false });
    }
  };
  handleClickOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
    this.props.getReportes();
  };
  setFoto = (url) => {
    this.setState({ foto: url });
  };
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    permissions: state.auth.permissions,
  };
};
export default connect(mapStateToProps)(ReporteForm);
