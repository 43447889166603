import React, { Component, Fragment } from 'react';

import {
  Button,
  FormControl,
  InputLabel,
  Typography,
  Grid,
  TextField,
  Card,
  CardContent,
  CardActions,
  ListItemSecondaryAction,
  ListItem,
  List,
  IconButton,
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CardHeader,
} from '@material-ui/core';
import Axios from 'axios';
import { connect } from 'react-redux';
import { API_URL } from '../../config';
import SimpleReactValidator from 'simple-react-validator';
import '../../locales/SimpleReactValidator';
import SelectSearch from '../../components/SelectSearch';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import DialogMaterial from './DialogMaterial';
import ClearIcon from '@material-ui/icons/Clear';
import MultipleSelect from '../../components/MultiSelect';
import VinculacionActividades from '../Actividades/ViculacionActividades';

import moment from 'moment';
import 'moment/locale/es';
import business from 'moment-business-time';

business.locale('es', {
  workinghours: {
    0: null,
    1: ['08:00:00', '12:00:00', '13:00:00', '17:00:00'],
    2: ['08:00:00', '12:00:00', '13:00:00', '17:00:00'],
    3: ['08:00:00', '12:00:00', '13:00:00', '17:00:00'],
    4: ['08:00:00', '12:00:00', '13:00:00', '17:00:00'],
    5: ['08:00:00', '12:00:00', '13:00:00', '17:00:00'],
    6: null,
  },
});

class ActividadForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cantidad: '',
      tipoActividad: null,
      inicio: moment().startOf('day'),
      fin: moment().endOf('day'),
      participantes: [],
      materialesActividad: [],
      materiales: [],
      material: null,
      editar: false,
      dialog: false,
      loading: false,
      usuarios: [],
    };
    this.handleCantidad = this.handleCantidad.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validator = new SimpleReactValidator({ locale: 'es' });
  }

  render() {
    const { permissions, tipos, actividades, objeto, token } = this.props;
    const {
      tipoActividad,
      usuarios,
      cantidad,
      participantes,
      materiales,
      materialesActividad,
      material,
      dialog,
      editar,
      loading,
      inicio,
      fin,
    } = this.state;
    // console.log(actividades);
    return (
      <Card fullWidth elevation={4}>
        <CardHeader subheader='Agregar Actividad' />
        <CardContent>
          <Grid spacing={2}>
            {/* Formulario Actividad */}
            <Grid item xs={12}>
              <Grid
                container
                direction='row'
                justify='flex-end'
                alignItems='flex-end'
                spacing={2}
              >
                {/* Tipo */}
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    {tipos.length > 0 ? (
                      <SelectSearch
                        label='Tipo de Actividad'
                        opciones={tipos}
                        selected={tipoActividad}
                        setSelected={(val) => {
                          this.setState({
                            tipoActividad: val,
                            materialesActividad: val?.materiales_tipoActividads,
                          });
                        }}
                      />
                    ) : (
                      'Esta especialidad no tiene Tipos de actividad'
                    )}

                    <Typography align='center' color='error'>
                      {this.validator.message(
                        'tipoActividad',
                        tipoActividad,
                        'required'
                      )}
                    </Typography>
                  </FormControl>
                </Grid>
                {tipoActividad !== null && (
                  <Fragment>
                    {/* UM */}
                    <Grid item xs={4}>
                      <FormControl fullWidth>
                        <Typography>
                          U/M:{' '}
                          {tipoActividad && tipoActividad?.unidadMedida?.nombre}
                        </Typography>
                      </FormControl>
                    </Grid>
                    {/* Tarifa */}
                    <Grid item xs={4}>
                      <FormControl fullWidth>
                        <Typography>
                          Tarifa:
                          {tipoActividad &&
                            '$' + tipoActividad.tarifa?.toFixed(2)}
                        </Typography>
                      </FormControl>
                    </Grid>
                    {/* Cantidad */}
                    <Grid item xs={4}>
                      <FormControl fullWidth>
                        <TextField
                          margin='dense'
                          id='cantidad'
                          name='cantidad'
                          label='Cantidad'
                          type='number'
                          fullWidth
                          disabled={!tipoActividad}
                          value={cantidad}
                          onChange={this.handleCantidad}
                        />
                        <Typography align='center' color='error'>
                          {this.validator.message(
                            'cantidad',
                            cantidad,
                            'numeric|min:0,num'
                          )}
                        </Typography>
                      </FormControl>
                    </Grid>
                    {/* Participantes */}
                    <Grid item xs={12}>
                      <InputLabel id='participanteslbl'>
                        Participantes
                      </InputLabel>
                      <FormControl fullWidth>
                        <MultipleSelect
                          opciones={usuarios}
                          selecteds={participantes}
                          setSelecteds={(selecteds) =>
                            this.handleParticipantes(selecteds)
                          }
                          disabled={!tipoActividad}
                        />
                      </FormControl>
                    </Grid>
                    {/* VinculacionActividades */}
                    <Grid item xs={12}>
                      <InputLabel>
                        {`Duración de la Actividad ${(
                          ((tipoActividad?.horas || 0) * cantidad) /
                          (participantes.length || 1)
                        ).toFixed(2)} Hrs`}
                      </InputLabel>
                      <VinculacionActividades
                        tipoActividad={tipoActividad}
                        actividades={actividades}
                        obraId={objeto?.obraId}
                        token={token}
                        inicio={inicio}
                        setInicio={(inicio) => this.handleInicio(inicio)}
                      />
                      <InputLabel>
                        Fin de la Actividad: {fin?.format('YYYY-MM-DD HH:mm')}
                      </InputLabel>
                    </Grid>
                    {/* formulario material */}
                    <Card style={{ marginTop: 20 }}>
                      <CardHeader
                        subheader={
                          editar ? 'Editar Material' : 'Añadir Material'
                        }
                      />
                      <CardContent>
                        <List>
                          <ListItem>
                            <Grid
                              container
                              direction='row'
                              justify='flex-start'
                              alignItems='center'
                              spacing={2}
                            >
                              <Grid item xs={10}>
                                <Typography>Material</Typography>
                                <SelectSearch
                                  opciones={materiales}
                                  selected={material}
                                  setSelected={(material) =>
                                    this.setState({ material })
                                  }
                                  openDialog={(opened) =>
                                    this.setState({ dialog: opened })
                                  }
                                  dialog={
                                    <DialogMaterial
                                      getMateriales={this.getMateriales}
                                      open={dialog}
                                      handleClose={() =>
                                        this.setState({ dialog: false })
                                      }
                                    />
                                  }
                                  granted={true}
                                />
                              </Grid>
                              <Grid item xs={5}>
                                <Typography>U/M</Typography>
                                <FormControl fullWidth>
                                  <TextField
                                    fullWidth
                                    value={
                                      material?.unidadMedida?.nombre ||
                                      material?.material?.unidadMedida?.nombre
                                    }
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item xs={5}>
                                <Typography>Cantidad</Typography>
                                <FormControl fullWidth>
                                  <TextField
                                    type='number'
                                    fullWidth
                                    max={2}
                                    value={material?.cantidad || 0}
                                    onChange={(e) =>
                                      e.target.value > 0 &&
                                      this.setState({
                                        material: {
                                          ...material,
                                          cantidad: e.target.value,
                                        },
                                      })
                                    }
                                  />
                                </FormControl>
                              </Grid>
                            </Grid>
                            <ListItemSecondaryAction>
                              <IconButton
                                edge='end'
                                aria-label='Guardar'
                                onClick={material?.id && this.guardarMaterial}
                                disabled={!material?.id}
                              >
                                <SaveIcon />
                              </IconButton>
                              <IconButton
                                edge='end'
                                aria-label='Cancelar'
                                onClick={() =>
                                  this.setState({
                                    material: {
                                      id: null,
                                      cantidad: '',
                                      unidadMedida: { nombre: '' },
                                      material: {
                                        unidadMedida: { nombre: '' },
                                      },
                                    },
                                    editar: false,
                                  })
                                }
                              >
                                <ClearIcon />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        </List>
                      </CardContent>
                      <CardActions>
                        {/* Listado materiales */}
                        {tipoActividad && (
                          <TableContainer>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell>Materiales Asignados</TableCell>
                                  <TableCell align='right'>U/M</TableCell>
                                  <TableCell align='right'>Plan</TableCell>
                                  <TableCell align='center'></TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {materialesActividad?.map((row) => (
                                  <TableRow key={row.name}>
                                    <TableCell component='th' scope='row'>
                                      {row.nombre || row.material?.nombre}
                                    </TableCell>
                                    <TableCell align='right'>
                                      {row.unidadMedida?.nombre ||
                                        row.material?.unidadMedida?.nombre}
                                    </TableCell>
                                    <TableCell align='right'>
                                      {row.cantidad}
                                    </TableCell>
                                    <TableCell align='center'>
                                      <IconButton
                                        edge='end'
                                        aria-label='Editar'
                                        onClick={() =>
                                          this.setState({
                                            editar: true,
                                            material: row,
                                          })
                                        }
                                      >
                                        <EditIcon />
                                      </IconButton>
                                      <IconButton
                                        edge='end'
                                        aria-label='Eliminar'
                                        onClick={() => {
                                          this.eliminarMaterial(row);
                                        }}
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        )}
                      </CardActions>
                    </Card>
                  </Fragment>
                )}
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Button
            onClick={this.handleSubmit}
            variant={'contained'}
            color='primary'
            disabled={!permissions.crearActividad || !tipoActividad || loading}
          >
            {!tipoActividad ? (
              'Seleccione un tipo de Actividad'
            ) : loading ? (
              <CircularProgress />
            ) : (
              'Agregar'
            )}
          </Button>
        </CardActions>
      </Card>
    );
  }

  componentDidMount() {
    Axios({
      method: 'get',
      url: `${API_URL}/usuarios`,
      headers: {
        token: this.props.token,
      },
    })
      .then((res) => this.setState({ usuarios: res.data }))
      .catch((error) => console.log(error));
    Axios({
      method: 'get',
      url: `${API_URL}/materiales`,
      headers: {
        token: this.props.token,
      },
    })
      .then((res) => this.setState({ materiales: res.data }))
      .catch((error) => console.log(error));
  }

  handleCantidad(event) {
    const target = event.target;
    const value = target.value;
    const { inicio, participantes } = this.state;
    this.handleFin(inicio, value, participantes);
    this.setState({ cantidad: value });
  }

  handleParticipantes(selecteds) {
    const { inicio, cantidad } = this.state;
    this.handleFin(inicio, cantidad, selecteds);
    if (this.disponilbe(selecteds)) this.setState({ participantes: selecteds });
  }

  disponilbe(participantes) {
    const { inicio, fin } = this.state;
    let disp = true;
    if (this.props.objeto?.obra?.estado === '2') {
      participantes.map((p) =>
        p.usuarios_actividads.map((ua) => {
          if (
            moment(inicio).isBetween(
              moment(ua.actividad.inicio),
              moment(ua.actividad.fin)
            ) ||
            moment(fin).isBetween(
              moment(ua.actividad.inicio),
              moment(ua.actividad.fin)
            )
          ) {
            disp = false;
            alert(
              `El obrero: ${p.nombre} ${p.apellidos} está vinculado a una actividad que coincide con el rango de fechas actual`
            );
          }
          return false;
        })
      );
      return disp;
    }
    return true;
  }

  handleInicio(inicio) {
    const { participantes, cantidad } = this.state;
    this.handleFin(inicio, cantidad, participantes);
    this.setState({ inicio });
  }

  handleFin(inicio, cantidad, participantes) {
    const { tipoActividad } = this.state;
    const start = inicio.format('YYYY-MM-DD HH:mm');
    const cantParticipantes = participantes.length;
    let addTime = 0;
    cantParticipantes === 0
      ? (addTime = cantidad * tipoActividad.horas)
      : (addTime = (cantidad * tipoActividad.horas) / cantParticipantes);
    let fin = business(start).addWorkingTime(addTime, 'hours');
    this.setState({ fin });
  }

  guardarMaterial = () => {
    const { materialesActividad, material } = this.state;
    var upd = materialesActividad.filter((m) => m.nombre !== material.nombre);
    upd.push(material);
    this.setState({
      materialesActividad: upd,
      material: {
        id: null,
        cantidad: '',
        unidadMedida: { nombre: '' },
      },
      editar: false,
    });
  };

  eliminarMaterial = (material) => {
    const { materialesActividad } = this.state;
    var upd = materialesActividad.filter((m) => m.id !== material.id);
    this.setState({
      materialesActividad: upd,
      material: {
        cantidad: '',
        unidadMedida: { nombre: '' },
      },
    });
  };

  handleChangeMultiple = (event) => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    this.setState({ participantes: value });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ loading: true });

    const {
      tipoActividad,
      participantes,
      cantidad,
      materialesActividad,
      inicio,
      fin,
    } = this.state;
    const { objeto, token, getActividades, especialidad } = this.props;

    if (tipoActividad?.especialidadId !== especialidad?.id) {
      alert(
        'La actividad seleccionada no pertenece a la Especialidad seleccionada'
      );
      this.setState({ loading: false });
    } else if (this.validator.allValid()) {
      await Axios({
        method: 'post',
        url: `${API_URL}/actividades`,
        data: {
          objetoObraId: objeto?.id,
          tipoActividadId: tipoActividad.id,
          plan: cantidad,
          real: 0,
          costo: cantidad * tipoActividad.tarifa,
          inicio,
          fin,
        },
        headers: {
          token: token,
        },
      })
        .then(async (act) => {
          participantes.map(async (user) => {
            return await Axios({
              method: 'post',
              url: `${API_URL}/usuarios_actividad`,
              data: {
                actividadId: act.data.id,
                usuarioId: user.id,
              },
              headers: {
                token: token,
              },
            }).catch((error) => console.log(error.message));
          });
          materialesActividad.map(async (mta) => {
            return await Axios({
              method: 'post',
              url: `${API_URL}/materiales_actividad`,
              data: {
                nombre: mta.material?.nombre || mta.nombre,
                plan: mta.cantidad * cantidad,
                real: 0,
                unidadMedidaId:
                  mta.material?.unidadMedidaId || mta.unidadMedidaId,
                actividadId: act.data.id,
              },
              headers: {
                token: token,
              },
            }).catch((error) => console.log(error.message));
          });
          this.setState({
            cantidad: 0,
            tipoActividad: null,
            participantes: [],
            materialesActividad: [],
            material: null,
            editar: false,
            dialog: false,
          });
          this.validator.hideMessages();
          await getActividades();
          this.setState({ loading: false });
        })
        .catch((err) => console.log(err.message));
    } else {
      this.validator.showMessages();
      this.forceUpdate();
      this.setState({ loading: false });
    }
  };
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    permissions: state.auth.permissions,
    userId: state.auth.userId,
  };
};
export default connect(mapStateToProps)(ActividadForm);
