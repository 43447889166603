import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import YearMonthPicker from '../../components/YearMonthPicker';
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
// import SelectSearch from '../../components/SelectSearch';
import { headerTitle } from '../../store/actions';
import Axios from 'axios';
import { API_URL } from '../../config';
import { connect } from 'react-redux';
import moment from 'moment';

class PagosQuincenales extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: 'panel1',
      obras: [],
      obras1: [],
      obras2: [],
      obra: null,
      inicio1: moment(),
      inicio2: moment(),
      fin1: moment(),
      fin2: moment(),
      objetos: [],
      objeto: null,
    };
  }

  render() {
    const { obras1, obras2, inicio1, inicio2, fin1, fin2 } = this.state;

    return (
      <div>
        <Grid
          container
          spacing={2}
          direction='row'
          justify='flex-start'
          alignItems='flex-end'
        >
          <Grid item xs={12} style={{ paddingBottom: 20 }}>
            <YearMonthPicker
              setSelected={(date) => this.setFechas(date.month())}
            />
          </Grid>
          {/* <Grid item xs={12} md={3} style={{ paddingBottom: 20 }}>
            <SelectSearch
              label='Obra'
              opciones={obras}
              selected={obra}
              setSelected={(o) => this.handleObra(o)}
            />
          </Grid>
          <Grid item xs={12} md={3} style={{ paddingBottom: 20 }}>
            <SelectSearch
              label='Objeto'
              opciones={objetos}
              selected={objeto}
              setSelected={(o) => this.handleObjeto(o)}
            />
          </Grid> */}
          <Grid item md={6}>
            <Typography>
              Quincena Actual ({inicio1.format('YYYY-MM-DD')} -{' '}
              {fin1.format('YYYY-MM-DD')})
            </Typography>
            <TableContainer component={Paper} style={{ marginTop: 20 }}>
              <Table size='small' aria-label='a dense table'>
                <TableHead>
                  <TableRow>
                    <TableCell>Obra</TableCell>
                    <TableCell align='right'>plan</TableCell>
                    <TableCell align='right'>real</TableCell>
                    <TableCell align='right'>reportado</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {obras1.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell component='th' scope='row'>
                        {row.nombre}
                      </TableCell>
                      <TableCell align='right'>
                        ${row.plan.toFixed(2)}
                      </TableCell>
                      <TableCell align='right'>
                        ${row.real.toFixed(2)}
                      </TableCell>
                      <TableCell align='right'>
                        ${row.reportado.toFixed(2)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item md={6}>
            <Typography>
              Quincena Anterior ({inicio2.format('YYYY-MM-DD')} -{' '}
              {fin2.format('YYYY-MM-DD')})
            </Typography>
            <TableContainer component={Paper} style={{ marginTop: 20 }}>
              <Table size='small' aria-label='a dense table'>
                <TableHead>
                  <TableRow>
                    <TableCell>Obra</TableCell>
                    <TableCell align='right'>plan</TableCell>
                    <TableCell align='right'>real</TableCell>
                    <TableCell align='right'>reportado</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {obras2.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell component='th' scope='row'>
                        {row.nombre}
                      </TableCell>
                      <TableCell align='right'>
                        ${row.plan.toFixed(2)}
                      </TableCell>
                      <TableCell align='right'>
                        ${row.real.toFixed(2)}
                      </TableCell>
                      <TableCell align='right'>
                        ${row.reportado.toFixed(2)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </div>
    );
  }

  componentDidMount = () => {
    this.props.dispatch(headerTitle('Panel de Gestión'));
    this.getObras();
    this.getObjetos();
    this.setFechas(moment().month());
  };

  setFechas = (mes) => {
    let inicio1 = null;
    let inicio2 = null;
    let fin1 = null;
    let fin2 = null;

    const es1ra = moment()
      .set('month', mes)
      .isBetween(
        moment().set('month', mes).startOf('month'),
        moment().set('month', mes).startOf('month').add(15, 'days')
      );

    if (es1ra) {
      inicio1 = moment().set('month', mes).startOf('month');
      fin1 = moment().set('month', mes).endOf('day').set('date', 15);
      inicio2 = moment()
        .set('month', mes)
        .subtract(1, 'months')
        .set('date', 16);
      fin2 = moment().set('month', mes).subtract(1, 'months').endOf('month');
    } else {
      inicio1 = moment().set('month', mes).startOf('day').set('date', 16);
      fin1 = moment().set('month', mes).endOf('month');
      inicio2 = moment().set('month', mes).startOf('months');
      fin2 = moment().set('month', mes).set('date', 15);
    }

    this.setState({
      inicio1,
      fin1,
      inicio2,
      fin2,
    });

    this.cargarDatos(
      inicio1,
      fin1,
      inicio2,
      fin2,
      this.state.obra,
      this.state.objeto
    );
  };

  cargarDatos = (inicio1, fin1, inicio2, fin2, obra, objeto) => {
    Axios({
      method: 'get',
      url: `${API_URL}/obras`,
      headers: { token: this.props.token },
    })
      .then((res) => {
        var obras = [];
        res.data.map((o) => {
          let plan = 0;
          let real = 0;
          let reportado = 0;
          o.objetoObras.map((obj) =>
            obj.actividads.map((act) =>
              act.reportes.map((r) => {
                if (moment(r.fecha).isBetween(inicio1, fin1)) {
                  plan += act.plan * act.tipoActividad.tarifa;
                  real += act.real * act.tipoActividad.tarifa;
                  reportado += r.valor;
                  return null;
                }
                return null;
              })
            )
          );
          return obras.push({
            nombre: o.nombre,
            plan,
            real,
            reportado,
          });
        });
        this.setState({ obras1: obras });
      })
      .catch((err) => console.log(err.message));
    Axios({
      method: 'get',
      url: `${API_URL}/obras`,
      headers: { token: this.props.token },
    })
      .then((res) => {
        var obras = [];
        res.data.map((o) => {
          let plan = 0;
          let real = 0;
          let reportado = 0;
          o.objetoObras.map((obj) =>
            obj.actividads.map((act) =>
              act.reportes.map((r) => {
                if (moment(r.fecha).isBetween(inicio2, fin2)) {
                  plan += act.plan * act.tipoActividad.tarifa;
                  real += act.real * act.tipoActividad.tarifa;
                  reportado += r.valor;
                  return null;
                }
                return null;
              })
            )
          );
          return obras.push({
            nombre: o.nombre,
            plan,
            real,
            reportado,
          });
        });
        this.setState({ obras2: obras });
      })
      .catch((err) => console.log(err.message));
  };

  getObras = () => {
    Axios({
      method: 'get',
      url: `${API_URL}/obras`,
      headers: { token: this.props.token },
    })
      .then((res) => this.setState({ obras: res.data }))
      .catch((err) => console.log(err.message));
  };

  getObjetos = async (id) => {
    const { token } = this.props;

    await Axios({
      method: 'get',
      url: `${API_URL}/objetos_obra/obra/${id}`,
      headers: {
        token: token,
      },
    })
      .then((res) => this.setState({ objetos: res.data }))
      .catch((err) => console.log(err.message));
  };

  handleObra = (obra) => {
    obra && this.setState({ obra: obra });
    obra && this.getObjetos(obra.id);
  };

  handleObjeto = (objeto) => {
    this.setState({ objeto: objeto });
  };

  handleChange = (panel) => (event, newExpanded) => {
    this.setState({ expanded: newExpanded ? panel : false });
  };
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    userId: state.auth.userId,
    permissions: state.auth.permissions,
  };
};
export default connect(mapStateToProps)(PagosQuincenales);
