import React, { Component } from 'react';
import Axios from 'axios';
import MUIDataTable from 'mui-datatables';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { headerTitle } from '../../store/actions';

import { textLabels } from '../../locales/DataTables';
import Dialog from '../../components/Dialog';
import EspecialidadEdit from './Edit';
import EspecialidadForm from './Form';
import { CircularProgress, Grid, Button } from '@material-ui/core';
import { connect } from 'react-redux';

import { API_URL } from '../../config';
import MensajeFlotante from '../../components/MensajeFlotante';

const styles = (theme) => ({
  centered: {
    marginTop: theme.spacing(20),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
});

class Especialidades extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rows: [],
      columns: [{ name: 'nombre', label: 'Nombre' }],
      selectedRows: [],
      open: false,
      especialidad: {
        nombre: '',
      },
      loading: false,
      sinAcceso: false,
    };
  }

  componentDidMount() {
    this.getEspecialidades();
    this.props.dispatch(headerTitle('Especialidades'));
  }

  getEspecialidades = () => {
    this.setState({ loading: true });
    const config = {
      headers: {
        token: this.props.token,
      },
    };
    Axios.get(API_URL + '/especialidades', config)
      .then((res) =>
        this.setState({
          loading: false,
          rows: res.data,
          especialidad: { id: null },
        })
      )
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err.message);
      });
  };

  handleDelete = () => {
    this.setState({ loading: true });
    this.state.selected.map((row) => {
      return this.eliminarEspecialidad(this.state.rows[row.dataIndex].id);
    });
  };

  eliminarEspecialidad = (id) => {
    this.handleClose();
    const config = {
      headers: {
        token: this.props.token,
      },
    };
    Axios.delete(API_URL + `/especialidades/${id}`, config)
      .then((res) => {
        this.getEspecialidades();
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  handleClose = () => {
    this.setState({ open: false });
    this.getEspecialidades();
  };

  sinAcceso() {
    this.setState({ sinAcceso: true });
    this.getEspecialidades();
  }

  options = {
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'rowSelectionChange':
          this.setState({
            selected: tableState.selectedRows.data,
          });
          if (tableState.selectedRows.data.length === 1) {
            let data = this.state.rows[
              tableState.selectedRows.data[0].dataIndex
            ];
            this.setState({
              especialidad: data,
            });
          } else
            this.setState({
              especialidad: {
                id: null,
              },
            });
          break;
        case 'rowDelete':
          this.props.permissions.eliminarEspecialidad
            ? this.setState({ open: true })
            : this.sinAcceso();
          break;
        default:
          break;
      }
    },
    filter: true,
    selectableRows: 'multiple',
    filterType: 'dropdown',
    responsive: 'vertical',
    rowsPerPage: 5,
    selectableRowsOnClick: true,
    rowsPerPageOptions: [5, 10, 25, 50, 100],
    textLabels: textLabels,
    enableNestedDataAccess: '.',
  };

  render() {
    const { classes, permissions } = this.props;
    const {
      rows,
      columns,
      especialidad,
      open,
      loading,
      sinAcceso,
    } = this.state;

    return loading ? (
      <div className={classes.centered}>
        <CircularProgress />
      </div>
    ) : (
      <Grid
        container
        direction='row'
        justify='flex-start'
        alignItems='stretch'
        spacing={2}
      >
        {permissions.crearEspecialidad && (
          <Grid item>
            <EspecialidadForm getEspecialidades={this.getEspecialidades} />
          </Grid>
        )}
        {permissions.editarEspecialidad && (
          <Grid item>
            {especialidad.id ? (
              <EspecialidadEdit
                getEspecialidades={this.getEspecialidades}
                especialidad={this.state.especialidad}
              />
            ) : (
              <Button variant='contained' color='primary' disabled m={2}>
                Editar
              </Button>
            )}
          </Grid>
        )}
        {permissions.listarEspecialidad && (
          <Grid item sm={12}>
            <MUIDataTable
              title={'Listado de Especialidades'}
              data={rows}
              columns={columns}
              options={this.options}
            />
          </Grid>
        )}
        <Dialog
          fullWidth
          open={open}
          dialogTitle={'Eliminar Especialidades seleccionados'}
          dialogText={
            'ATENCIÓN! Esta acción es irreversible. ¿Desea continuar?'
          }
          handleClose={this.handleClose}
          handleConfirm={this.handleDelete}
        />
        {sinAcceso && (
          <MensajeFlotante
            mensaje='No tiene permisos para realizar esta acción'
            tipo='warning'
          />
        )}
      </Grid>
    );
  }
}

Especialidades.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    permissions: state.auth.permissions,
  };
};
export default connect(mapStateToProps)(withStyles(styles)(Especialidades));
