import React, { Component } from 'react';

import {
  Button,
  DialogActions,
  TextField,
  DialogTitle,
  DialogContent,
  Dialog,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import axios from 'axios';
import { connect } from 'react-redux';
import { API_URL } from '../../config';
import SimpleReactValidator from 'simple-react-validator';
import '../../locales/SimpleReactValidator';
import ImagePicker from '../../components/ImagePicker';
import MensajeFlotante from '../../components/MensajeFlotante';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

class UsuarioEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.usuario.id,
      ci: props.usuario.ci,
      nombre: props.usuario.nombre,
      apellidos: props.usuario.apellidos,
      usuario: props.usuario.usuario,
      contrasena: '',
      rolId: props.usuario.rolId,
      telefono: props.usuario.telefono,
      direccion: props.usuario.direccion,
      foto: props.usuario.foto,
      licencia: props.usuario.licencia,
      contrato: props.usuario.contrato,
      open: false,
      error: '',
      loading: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validator = new SimpleReactValidator({ locale: 'es' });
  }

  render() {
    const { permissions } = this.props;
    const mediumRegex = new RegExp(
      '(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})'
    );
    return !permissions.editarUsuario ? (
      <MensajeFlotante mensaje='Sin acceso' tipo='warning' />
    ) : (
      <div>
        <Button
          variant='contained'
          color='primary'
          onClick={this.handleClickOpen}
          m={2}
        >
          Editar
        </Button>
        <Dialog
          fullWidth
          maxWidth='lg'
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby='form-dialog-title'
        >
          <DialogTitle id='form-dialog-title'>Editar usuario</DialogTitle>
          <DialogContent>
            {this.state.error === '' && (
              <MensajeFlotante mensaje={this.state.error} tipo='error' />
            )}
            <Grid container spacing={3}>
              <Grid item md={8} xs={12}>
                <Grid container spacing={3}>
                  <Grid item md={4} xs={12}>
                    <FormControl fullWidth>
                      <TextField
                        autoFocus
                        margin='dense'
                        id='ci'
                        name='ci'
                        label='Carnet de Identidad'
                        type='text'
                        fullWidth
                        value={this.state.ci}
                        onChange={this.handleChange}
                      />
                      <Typography align='center' color='error'>
                        {this.validator.message(
                          'ci',
                          this.state.ci,
                          'required|integer|size:11'
                        )}
                      </Typography>
                    </FormControl>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <FormControl fullWidth>
                      <TextField
                        margin='dense'
                        id='nombre'
                        name='nombre'
                        label='Nombre'
                        type='text'
                        fullWidth
                        value={this.state.nombre}
                        onChange={this.handleChange}
                      />
                      <Typography align='center' color='error'>
                        {this.validator.message(
                          'nombre',
                          this.state.nombre,
                          'required|string|min:4|max:50'
                        )}
                      </Typography>
                    </FormControl>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <FormControl fullWidth>
                      <TextField
                        margin='dense'
                        id='apellidos'
                        name='apellidos'
                        label='Apellidos'
                        type='text'
                        fullWidth
                        value={this.state.apellidos}
                        onChange={this.handleChange}
                      />
                      <Typography align='center' color='error'>
                        {this.validator.message(
                          'apellidos',
                          this.state.apellidos,
                          'string|min:4|max:64'
                        )}
                      </Typography>
                    </FormControl>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <FormControl fullWidth>
                      <TextField
                        margin='dense'
                        id='usuario'
                        name='usuario'
                        label='Usuario'
                        type='text'
                        fullWidth
                        value={this.state.usuario}
                        onChange={this.handleChange}
                      />
                      <Typography align='center' color='error'>
                        {this.validator.message(
                          'usuario',
                          this.state.usuario,
                          'required|alpha_num_space|min:4|max:50'
                        )}
                      </Typography>
                    </FormControl>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <FormControl fullWidth>
                      <TextField
                        margin='dense'
                        id='contrasena'
                        name='contrasena'
                        label='Contraseña'
                        type='password'
                        fullWidth
                        value={this.state.contrasena}
                        onChange={this.handleChange}
                      />
                      <Typography align='center' color='error'>
                        {this.validator.message(
                          'contrasena',
                          this.state.contrasena,
                          [{ regex: mediumRegex }]
                        )}
                      </Typography>
                    </FormControl>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id='rolIdlbl'>Rol</InputLabel>
                      <Select
                        labelId='rolIdlbl'
                        id='rolId'
                        name='rolId'
                        value={this.state.rolId}
                        onChange={this.handleChange}
                      >
                        <MenuItem value={1}>Administrador</MenuItem>
                        <MenuItem value={2}>Jefe de Obra</MenuItem>
                        <MenuItem value={3}>Obrero</MenuItem>
                      </Select>
                      <Typography align='center' color='error'>
                        {this.validator.message(
                          'rolId',
                          this.state.rolId,
                          'required'
                        )}
                      </Typography>
                    </FormControl>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <FormControl fullWidth>
                      <TextField
                        margin='dense'
                        id='direccion'
                        name='direccion'
                        label='Dirección'
                        type='text'
                        fullWidth
                        value={this.state.direccion}
                        onChange={this.handleChange}
                      />
                      <Typography align='center' color='error'>
                        {this.validator.message(
                          'direccion',
                          this.state.direccion,
                          'string|min:4|max:255'
                        )}
                      </Typography>
                    </FormControl>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <FormControl fullWidth>
                      <TextField
                        margin='dense'
                        id='licencia'
                        name='licencia'
                        label='Licencia'
                        type='text'
                        fullWidth
                        value={this.state.licencia}
                        onChange={this.handleChange}
                      />
                      <Typography align='center' color='error'>
                        {this.validator.message(
                          'licencia',
                          this.state.licencia,
                          'string|min:4|max:16'
                        )}
                      </Typography>
                    </FormControl>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <FormControl fullWidth>
                      <TextField
                        margin='dense'
                        id='contrato'
                        name='contrato'
                        label='Contrato'
                        type='text'
                        fullWidth
                        value={this.state.contrato}
                        onChange={this.handleChange}
                      />
                      <Typography align='center' color='error'>
                        {this.validator.message(
                          'contrato',
                          this.state.contrato,
                          'string|min:4|max:16'
                        )}
                      </Typography>
                    </FormControl>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <InputLabel id='telefonolbl'>Teléfono</InputLabel>
                    <FormControl fullWidth>
                      <PhoneInput
                        labelId='telefonolbl'
                        country={'cu'}
                        value={this.state.telefono}
                        onChange={(telefono) => this.setState({ telefono })}
                      />
                      <Typography align='center' color='error'>
                        {this.validator.message(
                          'telefono',
                          this.state.telefono,
                          'phone'
                        )}
                      </Typography>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={4} xs={12}>
                <FormControl fullWidth>
                  <ImagePicker
                    setFoto={this.setFoto}
                    nombre='Foto de Perfil'
                    foto={this.state.foto}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleClose}
              variant={'outlined'}
              color='primary'
            >
              Cancelar
            </Button>
            <Button
              onClick={this.handleSubmit}
              variant={'contained'}
              color='primary'
              disabled={this.state.loading}
            >
              {this.state.loading ? <CircularProgress /> : 'Editar'}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const {
      id,
      ci,
      nombre,
      apellidos,
      usuario,
      contrasena,
      rolId,
      telefono,
      direccion,
      foto,
      licencia,
      contrato,
    } = this.state;

    const bodyFormData = new FormData();
    bodyFormData.append('ci', ci);
    bodyFormData.append('nombre', nombre);
    bodyFormData.append('apellidos', apellidos);
    bodyFormData.append('usuario', usuario);
    bodyFormData.append('contrasena', contrasena);
    bodyFormData.append('rolId', rolId);
    bodyFormData.append('telefono', telefono);
    bodyFormData.append('direccion', direccion);
    typeof foto !== 'string' && bodyFormData.append('foto', foto);
    bodyFormData.append('licencia', licencia);
    bodyFormData.append('contrato', contrato);

    if (this.validator.allValid()) {
      this.setState({ loading: true });
      axios({
        method: 'put',
        url: `${API_URL}/usuarios/${id}`,
        data: bodyFormData,
        headers: {
          token: this.props.token,
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(() => {
          this.props.getUsuarios();
          this.setState({ open: false, loading: false, error: '' });
        })
        .catch((err) => {
          this.setState({ error: err.message });
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
      this.setState({ loading: false });
    }
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  setFoto = (url) => {
    this.setState({ foto: url });
  };
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    permissions: state.auth.permissions,
  };
};
export default connect(mapStateToProps)(UsuarioEdit);
