import React, { Component } from 'react';

import {
  Button,
  DialogActions,
  TextField,
  DialogTitle,
  DialogContent,
  Dialog,
  InputLabel,
  FormControl,
  Typography,
  Grid,
  CircularProgress,
  Select,
  MenuItem,
} from '@material-ui/core';
import axios from 'axios';
import { connect } from 'react-redux';
import { API_URL } from '../../config';
import SimpleReactValidator from 'simple-react-validator';
import '../../locales/SimpleReactValidator';
import ImagePicker from '../../components/ImagePicker';
import MensajeFlotante from '../../components/MensajeFlotante';
import FormData from 'form-data';
import SelectSearch from '../../components/SelectSearch';

import MomentUtils from '@date-io/moment';
import moment from 'moment';
import 'moment/locale/es';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
moment.locale('es');

class ObraForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nombre: '',
      direccion: '',
      usuario: '',
      estado: 1,
      inicio: Date().toString(),
      foto: null,
      open: false,
      loading: false,
      usuarios: [],
      alerta: {
        tipo: 'info',
        mensaje: '',
      },
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validator = new SimpleReactValidator({ locale: 'es' });
  }

  render() {
    const { permissions } = this.props;

    return !permissions.crearObra ? (
      <MensajeFlotante mensaje='Sin acceso' tipo='warning' />
    ) : (
      <div>
        <MensajeFlotante
          mensaje={this.state.alerta.mensaje}
          tipo={this.state.alerta.tipo}
        />
        <Button
          variant='contained'
          color='primary'
          onClick={this.handleClickOpen}
          m={2}
        >
          Nueva
        </Button>
        <Dialog
          fullWidth
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby='form-dialog-title'
          maxWidth='md'
        >
          <DialogTitle id='form-dialog-title'>Agregar Obra</DialogTitle>
          <DialogContent>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <TextField
                        autoFocus
                        margin='dense'
                        id='nombre'
                        name='nombre'
                        label='Nombre'
                        type='text'
                        fullWidth
                        value={this.state.nombre}
                        onChange={this.handleChange}
                      />
                      <Typography align='center' color='error'>
                        {this.validator.message(
                          'nombre',
                          this.state.nombre,
                          'required|string|min:4|max:50'
                        )}
                      </Typography>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <TextField
                        autoFocus
                        margin='dense'
                        id='direccion'
                        name='direccion'
                        label='Dirección'
                        type='text'
                        fullWidth
                        multiline
                        rows={2}
                        value={this.state.direccion}
                        onChange={this.handleChange}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id='estadolbl'>Estado</InputLabel>
                      <Select
                        labelId='estadolbl'
                        id='estado'
                        name='estado'
                        value={this.state.estado}
                        onChange={this.handleChange}
                      >
                        <MenuItem value={1}>En Oferta</MenuItem>
                        <MenuItem value={2}>En Ejecución</MenuItem>
                      </Select>
                      <Typography align='center' color='error'>
                        {this.validator.message(
                          'estado',
                          this.state.estado,
                          'required'
                        )}
                      </Typography>
                    </FormControl>
                  </Grid>
                  {(this.state.estado === '2' || this.state.estado === 2) && (
                    <Grid item xs={12}>
                      <InputLabel id='iniciolbl'>Inicio</InputLabel>
                      <FormControl fullWidth>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <DateTimePicker
                            labelId='iniciolbl'
                            name='inicio'
                            value={this.state.inicio}
                            onChange={(date) => this.handleDateChange(date)}
                            locale={'es'}
                            format={'YYYY-MM-DD hh:mm'}
                          />
                        </MuiPickersUtilsProvider>
                      </FormControl>
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <InputLabel id='usuariolbl'>Jefe de Obra</InputLabel>
                    <FormControl fullWidth>
                      <SelectSearch
                        opciones={this.state.usuarios}
                        selected={this.state.usuario}
                        setSelected={(usuario) => this.setState({ usuario })}
                      />
                      <Typography align='center' color='error'>
                        {this.validator.message(
                          'usuario',
                          this.state.usuario,
                          'required'
                        )}
                      </Typography>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item md={6} xs={12}>
                <FormControl fullWidth>
                  <ImagePicker
                    setFoto={this.setFoto}
                    nombre='Foto de Obra'
                    foto={null}
                  />
                  {/* <Typography align='center' color='error'>
                    {this.validator.message(
                      'foto',
                      this.state.foto,
                      'required'
                    )}
                  </Typography> */}
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleClose}
              variant={'outlined'}
              color='primary'
            >
              Cancelar
            </Button>
            <Button
              onClick={this.handleSubmit}
              variant={'contained'}
              color='primary'
              disabled={this.state.loading}
            >
              {this.state.loading ? <CircularProgress /> : 'Agregar'}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  componentDidMount() {
    const config = {
      headers: {
        token: this.props.token,
      },
    };
    axios
      .get(API_URL + '/usuarios/jefes', config)
      .then((res) => {
        this.setState({ usuarios: res.data });
      })
      .catch((error) => console.log(error));
    axios
      .get(API_URL + '/objetos_obra', config)
      .then((res) => {
        let objetos = res.data.map((item) => {
          return {
            value: item.id,
            display: item.nombre,
          };
        });
        this.setState({
          objetos: objetos,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  handleDateChange(event) {
    this.setState({
      inicio: moment(event).format('YYYY-MM-DD HH:mm'),
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    if (this.validator.allValid()) {
      const { nombre, direccion, usuario, foto, estado, inicio } = this.state;
      const bodyFormData = new FormData();
      bodyFormData.append('nombre', nombre);
      bodyFormData.append('direccion', direccion);
      bodyFormData.append('estado', estado);
      bodyFormData.append('inicio', inicio);
      bodyFormData.append('usuarioId', usuario?.id);
      bodyFormData.append('foto', foto);

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          token: this.props.token,
        },
      };
      axios
        .post(`${API_URL}/obras`, bodyFormData, config)
        .then((res) => {
          this.props.getObras();
          this.setState({
            open: false,
            mensaje: {
              tipo: 'success',
              mensaje: 'Insertado satisfactoriamente',
            },
            loading: false,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
      this.setState({ loading: false });
    }
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
    this.props.getObras();
  };

  setFoto = (url) => {
    this.setState({ foto: url });
  };
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    permissions: state.auth.permissions,
  };
};
export default connect(mapStateToProps)(ObraForm);
