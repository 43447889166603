import React, { Component } from 'react';
import {
  Button,
  DialogActions,
  DialogTitle,
  DialogContent,
  Dialog,
  CircularProgress,
} from '@material-ui/core';
import { connect } from 'react-redux';
import '../../locales/SimpleReactValidator';
import MensajeFlotante from '../../components/MensajeFlotante';
import moment from 'moment';
import 'moment/locale/es';
import * as XLSX from 'xlsx';
import MUIDataTable from 'mui-datatables';
import { textLabels } from '../../locales/DataTables';
import Axios from 'axios';
import { API_URL } from '../../config';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

moment.locale('es');

class ImportarObra extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      file: '',
      rows: [],
      cols: [],
      alerta: {
        tipo: 'info',
        mensaje: '',
      },
    };
  }

  render() {
    const { classes, permissions } = this.props;
    const { rows, cols, loading, alerta, open } = this.state;

    return !permissions.editarObra ? (
      <MensajeFlotante mensaje='Sin acceso' tipo='warning' />
    ) : (
      <div>
        <MensajeFlotante mensaje={alerta?.mensaje} tipo={alerta?.tipo} />
        <Button
          variant='contained'
          color='primary'
          onClick={this.handleClickOpen}
          m={2}
        >
          Importar Obra
        </Button>
        <Dialog
          fullWidth
          open={open}
          onClose={this.handleClose}
          aria-labelledby='form-dialog-title'
          maxWidth='md'
        >
          <DialogTitle id='form-dialog-title'>Importar Obra</DialogTitle>
          <DialogContent>
            <input
              type='file'
              id='file'
              ref='fileUploader'
              onChange={this.filePathset.bind(this)}
            />
            <button onClick={() => this.readFile()}>Cargar datos</button>
            <br />
            <br />
            <button onClick={() => this.ImportarObjetos()}>
              Importar Objetos
            </button>
            <button onClick={() => this.ImportarActividades()}>
              Importar Actividades
            </button>
            <br />
            <br />
            {loading ? (
              <div className={classes.centered}>
                Cargando... <br />
                <CircularProgress />
              </div>
            ) : (
              <MUIDataTable
                title={'Importación de Obra'}
                data={rows}
                columns={cols}
                options={{
                  filter: true,
                  selectableRows: 'none',
                  filterType: 'dropdown',
                  responsive: 'vertical',
                  rowsPerPage: 5,
                  selectableRowsOnClick: true,
                  rowsPerPageOptions: [5, 25, 50, 100],
                  textLabels: textLabels,
                  enableNestedDataAccess: '.',
                }}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleClose}
              variant={'outlined'}
              color='primary'
            >
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  componentDidMount() {
    this.getObjetos();
    this.getTipos();
  }

  getObjetos = () => {
    this.setState({ loading: true });
    Axios({
      method: 'get',
      url: `${API_URL}/objetos_obra/obra/${this.props.obraId}`,
      headers: { token: this.props.token },
    })
      .then((res) =>
        this.setState({
          loading: false,
          objetos: res.data,
        })
      )
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err.message);
      });
  };

  getTipos = () => {
    this.setState({ loading: true });
    Axios({
      method: 'get',
      url: `${API_URL}/tipos_actividad`,
      headers: { token: this.props.token },
    })
      .then((res) =>
        this.setState({
          loading: false,
          tipos: res.data,
        })
      )
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err.message);
      });
  };

  handleClick(e) {
    this.refs.fileUploader.click();
  }

  filePathset(e) {
    e.stopPropagation();
    e.preventDefault();
    var file = e.target.files[0];
    // console.log(file);
    this.setState({ file });
    // this.readFile();
    // console.log(this.state.file);
  }

  readFile() {
    this.setState({ loading: true });
    var f = this.state.file;
    // var name = f.name;
    const reader = new FileReader();
    reader.onload = (evt) => {
      // evt = on_file_select event
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      // const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      const data = XLSX.utils.sheet_to_row_object_array(ws, { header: 1 });
      // console.log(XLSX.utils.sheet_to_row_object_array(ws, { header: 1 }));
      /* Convert to Json */
      const JData = this.convertToJson(data);
      /* Update state */
      // JData.map((row) => console.log(row));
      this.setState({ rows: JData, loading: false });
    };
    reader.readAsBinaryString(f);
  }

  convertToJson(lines) {
    // var lines = csv.split('\n');

    var result = [];

    // var headers = lines[0].split(',');
    var headers = lines[0];

    for (var i = 1; i < lines.length; i++) {
      var obj = {};
      // var currentline = lines[i].split(',');
      var currentline = lines[i];

      for (var j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentline[j];
      }

      result.push(obj);
    }
    // console.log(headers, result);
    this.setState({
      cols: headers.map((col) => {
        // console.log(col);
        return { name: col, label: col };
      }),
    });
    return result; //JSON
  }

  ImportarObjetos = () => {
    const { rows, objetos } = this.state;
    const { token, obraId } = this.props;
    console.log(rows);
    let objs = [];

    //llenar los arreglos con los valores a insertar
    rows.map(async (row) => {
      this.setState({ loading: true });
      //comprobar si existe la especialidad
      if (
        !objetos.find((element) => element.nombre === row.objeto) &&
        !objs.find((element) => element.nombre === row.objeto) &&
        row.objeto !== '' &&
        row.objeto !== undefined
      ) {
        objs.push({ nombre: row.objeto });
        Axios({
          method: 'post',
          url: `${API_URL}/objetos_obra`,
          data: { nombre: row.objeto, obraId },
          headers: { token },
        })
          .then((res) => {
            this.getObjetos();
            this.setState({
              alerta: {
                tipo: 'success',
                mensaje: `Objeto insertado satisfactoriamente >> ${res.data.nombre}`,
              },
              loading: false,
            });
          })
          .catch((err) => {
            console.log(err);
            this.setState({ loading: false });
          });
      } else this.setState({ loading: false });
    });
  };

  ImportarActividades = () => {
    const { rows, objetos, tipos } = this.state;
    const { token } = this.props;

    rows.map((row) => {
      this.setState({ loading: true });
      let objId = objetos.find((element) => element.nombre === row.objeto)?.id;
      let tipo = tipos.find((element) => element.codigo === `${row.codigo}`);
      Axios({
        method: 'post',
        url: `${API_URL}/actividades`,
        data: {
          objetoObraId: objId,
          tipoActividadId: tipo?.id,
          plan: row.cantidad,
          real: 0,
          costo: row.cantidad * tipo?.tarifa,
          inicio: moment().format('YYYY-MM-DD HH:mm'),
          fin: moment().add('hours', tipo?.horas).format('YYYY-MM-DD HH:mm'),
        },
        headers: { token },
      })
        .then((res) => {
          this.setState({
            alerta: {
              tipo: 'success',
              mensaje: `Insertado satisfactoriamente >> ${JSON.stringify(
                res.data
              )}`,
            },
            loading: false,
          });
        })
        .catch((err) =>
          this.setState({
            alerta: {
              tipo: 'error',
              mensaje: err.message,
            },
            loading: false,
          })
        );
      return true;
    });
    this.setState({ loading: false });
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
    this.props.getObras();
  };
}

const styles = (theme) => ({
  centered: {
    marginTop: theme.spacing(20),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
});

ImportarObra.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    permissions: state.auth.permissions,
  };
};
export default connect(mapStateToProps)(withStyles(styles)(ImportarObra));
