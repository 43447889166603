import React, { Component } from 'react';

import {
  Button,
  DialogActions,
  TextField,
  DialogTitle,
  DialogContent,
  Dialog,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import axios from 'axios';
import { connect } from 'react-redux';
import { API_URL } from '../../config';
import SimpleReactValidator from 'simple-react-validator';
import '../../locales/SimpleReactValidator';

class MaterialForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nombre: '',
      unidadMedidaId: '',
      cantidad: '',
      unidades_medida: [],
      loading: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validator = new SimpleReactValidator({ locale: 'es' });
  }

  render() {
    const { nombre, unidadMedidaId, cantidad, unidades_medida } = this.state;

    return (
      <div>
        <Dialog
          fullWidth
          maxWidth='md'
          open={this.props.open}
          onClose={this.props.handleClose}
          aria-labelledby='form-dialog-title'
        >
          <DialogTitle id='form-dialog-title'>Agregar material</DialogTitle>
          <DialogContent>
            <FormControl fullWidth>
              <TextField
                autoFocus
                margin='dense'
                id='nombre'
                name='nombre'
                label='Nombre'
                type='text'
                fullWidth
                value={nombre}
                onChange={this.handleChange}
              />
              <Typography align='center' color='error'>
                {this.validator.message(
                  'nombre',
                  nombre,
                  'required|string|min:4|max:50'
                )}
              </Typography>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id='unidadMedidaIdlbl'>Unidad de Medida</InputLabel>
              <Select
                labelId='unidadMedidaIdlbl'
                id='unidadMedidaId'
                name='unidadMedidaId'
                value={unidadMedidaId}
                onChange={this.handleChange}
              >
                {unidades_medida.map(function (item) {
                  return <MenuItem value={item.value}>{item.display}</MenuItem>;
                })}
              </Select>
              <Typography align='center' color='error'>
                {this.validator.message(
                  'unidadMedidaId',
                  unidadMedidaId,
                  'required'
                )}
              </Typography>
            </FormControl>
            <FormControl fullWidth>
              <TextField
                margin='dense'
                id='cantidad'
                name='cantidad'
                label='Cantidad'
                type='text'
                fullWidth
                value={cantidad}
                onChange={this.handleChange}
              />
              <Typography align='center' color='error'>
                {this.validator.message(
                  'cantidad',
                  cantidad,
                  'required|numeric'
                )}
              </Typography>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.props.handleClose}
              variant={'outlined'}
              color='primary'
            >
              Cancelar
            </Button>
            <Button
              onClick={this.handleSubmit}
              variant={'contained'}
              color='primary'
              disabled={this.state.loading}
            >
              {this.state.loading ? <CircularProgress /> : 'Agregar'}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  componentDidMount() {
    const config = { headers: { token: this.props.token } };
    axios
      .get(API_URL + '/unidades_medida', config)
      .then((res) => {
        let unidades_medida = res.data.map((item) => {
          return {
            value: item.id,
            display: item.nombre,
          };
        });
        this.setState({
          unidades_medida: unidades_medida,
        });
      })
      .catch((error) => console.log(error));
    axios
      .get(API_URL + '/tipos_actividad', config)
      .then((res) => {
        let tipos = res.data.map((item) => {
          return {
            value: item.id,
            display: item.nombre,
          };
        });
        this.setState({ tipos: tipos });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    const { nombre, unidadMedidaId, cantidad } = this.state;
    const model = {
      nombre,
      unidadMedidaId,
      cantidad,
      tipoActividadId: this.props.tipo.id,
    };
    const config = {
      headers: {
        token: this.props.token,
      },
    };
    if (this.validator.allValid()) {
      axios
        .post(API_URL + '/materiales', model, config)
        .then(() => {
          this.props.getMateriales();
          this.props.handleClose();
          this.setState({ open: false, loading: false });
        })
        .catch((err) => {
          console.log(err);
          this.props.handleClose();
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
      this.setState({ loading: false });
    }
  };
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    permissions: state.auth.permissions,
  };
};
export default connect(mapStateToProps)(MaterialForm);
