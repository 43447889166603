import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import Pizarra from '../containers/Pizarra';
import Planificacion from '../containers/Planificacion';
import Gestion from '../containers/Gestion';
import Usuarios from '../containers/Usuarios';
import Actividades from '../containers/Actividades';
import TiposActividad from '../containers/TiposActividad';
import Especialidades from '../containers/Especialidades';
import Materiales from '../containers/Materiales';
import UnidadesMedida from '../containers/UnidadesMedida';
import ObjetosObra from '../containers/ObjetosObra';
import Obras from '../containers/Obras';
import Reportes from '../containers/Reportes';
import EstadReportes from '../containers/Estadisticas/Reportes';
import EstadCostos from '../containers/Estadisticas/Costos';
import EstadPagos from '../containers/Estadisticas/Pagos';
import EstadMateriales from '../containers/Estadisticas/Materiales';
import Importacion from '../containers/Importacion';
import Login from '../containers/Auth/Login';
import Logout from '../containers/Auth/Logout';
import NotFound from '../components/NotFound';
import NoAccess from '../components/NoAccess';

class Routes extends Component {
  render() {
    const { authed, permissions } = this.props;
    return (
      <Switch>
        <PrivateRoute
          authed={authed}
          path='/'
          exact
          component={permissions.esObrero ? Obras : Pizarra}
        />
        <PrivateRoute
          authed={authed && !permissions.esObrero}
          path='/planificacion'
          component={Planificacion}
        />
        <PrivateRoute
          authed={authed && !permissions.esObrero}
          path='/gestion'
          component={Gestion}
        />
        <PrivateRoute
          authed={authed && permissions.esAdmin}
          path='/usuarios'
          component={Usuarios}
        />
        <PrivateRoute
          authed={authed && permissions.esAdmin}
          path='/actividades/tipos'
          component={TiposActividad}
        />
        <PrivateRoute
          authed={authed && !permissions.esObrero}
          path='/actividades'
          component={Actividades}
        />
        <PrivateRoute
          authed={authed && permissions.esAdmin}
          path='/especialidades'
          component={Especialidades}
        />
        <PrivateRoute
          authed={authed && permissions.esAdmin}
          path='/materiales'
          component={Materiales}
        />
        <PrivateRoute
          authed={authed && !permissions.esObrero}
          path='/obras/objetos'
          component={ObjetosObra}
        />
        <PrivateRoute authed={authed} path='/obras' component={Obras} />
        <PrivateRoute
          authed={authed && !permissions.esObrero}
          path='/reportes'
          component={Reportes}
        />
        <PrivateRoute
          authed={authed && !permissions.esObrero}
          path='/estadisticas/reportes'
          component={EstadReportes}
        />
        <PrivateRoute
          authed={authed && !permissions.esObrero}
          path='/estadisticas/costos'
          component={EstadCostos}
        />
        <PrivateRoute
          authed={authed && !permissions.esObrero}
          path='/estadisticas/materiales'
          component={EstadMateriales}
        />
        <PrivateRoute
          authed={authed}
          path='/estadisticas/pagos'
          component={EstadPagos}
        />
        <PrivateRoute
          authed={authed && permissions.esAdmin}
          path='/um'
          component={UnidadesMedida}
        />
        <PrivateRoute
          authed={authed && permissions.esAdmin}
          path='/importacion'
          component={Importacion}
        />
        <Route path='/login' component={Login} />
        <Route path='/logout' component={Logout} />

        <Route path='/sinAcceso' component={NoAccess} />

        {/* LA 404 TIENE QUE SER LA ULTIMA SIEMPRE */}
        <Route path='/404' component={NotFound} />
        <Redirect from='*' to='/404' />
      </Switch>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authed: state.auth.token != null,
    permissions: state.auth.permissions,
  };
};

export default connect(mapStateToProps)(Routes);

function PrivateRoute({ component: Component, authed, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        authed === true ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )
      }
    />
  );
}
