import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
  CircularProgress,
  Grid,
  TableCell,
  TableFooter,
  TableRow,
} from '@material-ui/core';
import DateRange from '../../components/DateRange';
import moment from 'moment';
import { connect } from 'react-redux';
import Axios from 'axios';
import { API_URL } from '../../config';
import MUIDataTable from 'mui-datatables';
import { headerTitle } from '../../store/actions';
import { textLabels } from '../../locales/DataTables';

class PagosObreros extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      itemsPerPage: 10,
      rows: [],
      columns: [
        {
          name: 'usuario',
          label: 'Trabajador',
          options: {
            customBodyRender: (value) => value?.nombre + ' ' + value?.apellidos,
            sortDirection: 'asc',
          },
        },
        { name: 'reporte.actividad.objetoObra.obra.nombre', label: 'Obra' },
        {
          name: 'reporte.actividad.objetoObra.nombre',
          label: 'Objeto de Obra',
        },
        {
          name: 'reporte.actividad.tipoActividad.nombre',
          label: 'Actividad',
        },
        {
          name: 'reporte.valor',
          label: 'Pago',
          options: {
            customBodyRender: (value) => `$${value?.toFixed(2)}`,
            filter: false,
          },
        },
      ],
      selectedRows: [],
      open: false,
      reporte: { id: null },
      loading: false,
      sinAcceso: false,
    };
  }

  render() {
    const { rows, columns, loading } = this.state;
    const { classes } = this.props;
    return (
      <Grid container spacing={2}>
        <Grid item sm={3}>
          <DateRange setRange={(s, e) => this.getUsuariosReportes(s, e)} />
        </Grid>
        <Grid item sm={9}>
          {loading ? (
            <div className={classes.centered}>
              <CircularProgress />
            </div>
          ) : (
            <MUIDataTable
              data={rows}
              columns={columns}
              options={this.options}
            />
          )}
        </Grid>
      </Grid>
    );
  }

  componentDidMount() {
    this.getUsuariosReportes();
    this.props.dispatch(headerTitle('Pagos a Trabajadores'));
  }

  getUsuariosReportes = (
    s = moment().startOf('month'),
    e = moment().endOf('day')
  ) => {
    this.setState({ loading: true });
    const { token, permissions, userId } = this.props;
    Axios({
      method: 'post',
      url: `${API_URL}/usuarios_reporte/filtrados`,
      data: {
        start: s,
        end: e,
      },
      headers: {
        token: token,
      },
    })
      .then((res) => {
        let arr = permissions.esAdmin
          ? res.data
          : permissions.esJefe
          ? res.data.filter(
              (item) =>
                item.reporte.actividad.objetoObra.obra.usuarioId ===
                parseInt(userId)
            )
          : res.data.filter((item) => item.usuarioId === parseInt(userId));

        this.setState({
          loading: false,
          rows: arr,
        });
      })
      .catch((err) => console.log(err.response.data.message || err.message));
  };

  sumSalery = (columns) => {
    return columns
      .map((a) => parseFloat(a.data[4].replace('$', '')))
      .reduce((total, valor) => (total += valor), 0);
  };

  options = {
    filter: true,
    selectableRows: 'none',
    filterType: 'multiselect',
    responsive: 'vertical',
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 25, 50],
    textLabels: textLabels,
    enableNestedDataAccess: '.',
    customTableBodyFooterRender: (opts) => (
      <TableFooter>
        <TableRow>
          <TableCell colSpan={3} />
          <TableCell colSpan={1} align='right'>
            Total de Pagos
          </TableCell>
          <TableCell colSpan={1} variant='head'>
            ${this.sumSalery(opts?.data)?.toFixed(2)}
          </TableCell>
        </TableRow>
      </TableFooter>
    ),
  };
}

const styles = (theme) => ({
  centered: {
    marginTop: theme.spacing(20),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
});

PagosObreros.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    userId: state.auth.userId,
    permissions: state.auth.permissions,
    actividad: state.actividades.actividad,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(PagosObreros));
