import React, { Component } from 'react';

import {
  Button,
  DialogActions,
  TextField,
  DialogTitle,
  DialogContent,
  Dialog,
  FormControl,
  Grid,
  List,
  ListItem,
  CircularProgress,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { API_URL } from '../../config';
import SimpleReactValidator from 'simple-react-validator';
import '../../locales/SimpleReactValidator';
import MensajeFlotante from '../../components/MensajeFlotante';
import Axios from 'axios';

class MaterialForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      materialesActividad: props.actividad?.materiales_actividads,
      open: false,
      loading: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validator = new SimpleReactValidator({ locale: 'es' });
  }

  render() {
    const { permissions } = this.props;
    const { open, materialesActividad } = this.state;

    return !permissions.crearMaterialesReporte ? (
      <MensajeFlotante mensaje='Sin acceso' tipo='warning' />
    ) : (
      <div>
        <Button
          variant='contained'
          color='primary'
          onClick={this.handleClickOpen}
          m={2}
        >
          Editar
        </Button>
        <Dialog
          fullWidth
          open={open}
          onClose={this.handleClose}
          aria-labelledby='form-dialog-title'
        >
          <DialogTitle id='form-dialog-title'>
            Asignar material usados al Reporte
          </DialogTitle>
          <DialogContent>
            {/* listado materiales */}
            <Grid item xs={12}>
              <List>
                {materialesActividad?.map((m) => {
                  return (
                    <ListItem key={m.id}>
                      <Grid
                        container
                        display='flex'
                        direction='row'
                        justify='space-between'
                        alignItems='center'
                        spacing={2}
                      >
                        <Grid item xs={12}>
                          <FormControl fullWidth>
                            <TextField
                              label='Nombre'
                              fullWidth
                              value={m.nombre}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                          <FormControl fullWidth>
                            <TextField
                              label='U/M'
                              fullWidth
                              value={m.unidadMedida.nombre}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                          <FormControl fullWidth>
                            <TextField
                              label='Disponible'
                              type='number'
                              fullWidth
                              value={m.plan}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                          <FormControl fullWidth>
                            <TextField
                              label='Usado'
                              type='number'
                              fullWidth
                              max={m.real}
                              value={m.value}
                              onChange={(event) =>
                                this.handleMaterialChange(event, m)
                              }
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </ListItem>
                  );
                })}
              </List>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleClose}
              variant={'outlined'}
              color='primary'
            >
              Cancelar
            </Button>
            <Button
              onClick={this.handleSubmit}
              variant={'contained'}
              color='primary'
              disabled={this.state.loading}
            >
              {this.state.loading ? <CircularProgress /> : 'Guardar'}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  handleMaterialChange(event, material) {
    const { materialesActividad } = this.state;
    const val = event.target.value;
    let mat = materialesActividad.map((m) => {
      if (m.id === material.id && val <= material.cantidad) m.value = val;
      return m;
    });

    this.setState({ materialesActividad: mat });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    const { reporte, token, getMateriales } = this.props,
      reporteId = reporte.id;
    if (this.validator.allValid()) {
      this.state.materialesActividad.map((m) => {
        return Axios({
          method: 'post',
          url: `${API_URL}/materiales_reporte`,
          data: {
            cantidad: m.value,
            materialesActividadId: m.id,
            reporteId: reporteId,
          },
          headers: {
            token: token,
          },
        })
          .then(async () => {
            await Axios({
              method: 'post',
              url: `${API_URL}/materiales_actividad`,
              data: {
                ...m,
                ...{ real: m.real + m.value },
              },
              headers: {
                token: token,
              },
            }).catch((error) => console.log(error));
            this.setState({ open: false, loading: false });
            getMateriales();
          })
          .catch((error) => console.log(error));
      });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
      this.setState({ loading: false });
    }
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
    this.props.getMateriales();
  };
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    permissions: state.auth.permissions,
  };
};
export default connect(mapStateToProps)(MaterialForm);
