import React, { Component, Fragment } from 'react';
import axios from 'axios';

import {
  Button,
  DialogActions,
  TextField,
  DialogTitle,
  DialogContent,
  Dialog,
  InputLabel,
  FormControl,
  Typography,
  Grid,
  List,
  ListItem,
  CircularProgress,
  FormControlLabel,
  Switch,
} from '@material-ui/core';

import MomentUtils from '@date-io/moment';
import moment from 'moment';
import 'moment/locale/es';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { connect } from 'react-redux';
import { API_URL } from '../../config';
import SimpleReactValidator from 'simple-react-validator';
import '../../locales/SimpleReactValidator';
import ImagePicker from '../../components/ImagePicker';
import MensajeFlotante from '../../components/MensajeFlotante';
import FormData from 'form-data';
import MultipleSelect from '../../components/MultiSelect';

moment.locale('es');

class ReporteForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fecha: Date().toString(),
      foto: null,
      fotoUrl: '',
      cantidad: 0,
      actividad: props.actividad,
      observaciones: '',
      open: false,
      actividades: [],
      participantes: [],
      usuarios: [],
      materialesActividad: props.actividad?.materiales_actividads,
      finalizada: props.actividad?.finalizada,
      editar: false,
      material: null,
      dialog: false,
      loading: false,
      alerta: {
        tipo: '',
        mensaje: '',
      },
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleMaterialChange = this.handleMaterialChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validator = new SimpleReactValidator({ locale: 'es' });
  }

  render() {
    const {
      open,
      cantidad,
      participantes,
      usuarios,
      fecha,
      foto,
      observaciones,
      alerta,
      finalizada,
      loading,
    } = this.state;
    const { permissions, actividad } = this.props;

    return !permissions.crearReporte ? (
      <MensajeFlotante mensaje='Sin acceso' tipo='warning' />
    ) : (
      <Fragment>
        <Button
          variant='contained'
          color='primary'
          onClick={this.handleClickOpen}
          m={2}
        >
          Nuevo
        </Button>
        <Dialog
          fullWidth
          maxWidth='md'
          open={open}
          onClose={this.handleClose}
          aria-labelledby='form-dialog-title'
        >
          <DialogTitle id='form-dialog-title'>
            Nuevo Reporte para la Actividad: {actividad?.tipoActividad?.nombre}
          </DialogTitle>
          <DialogContent>
            <Grid
              container
              display='flex'
              direction='row'
              justify='center'
              alignItems='flex-start'
              spacing={2}
            >
              <Grid item sm={8}>
                <Grid
                  container
                  direction='row'
                  justify='center'
                  alignItems='flex-start'
                  spacing={2}
                >
                  {/* cantidad */}
                  <Grid item xs={3}>
                    <FormControl fullWidth>
                      <TextField
                        margin='dense'
                        id='cantidad'
                        name='cantidad'
                        label={
                          'Realizado (' +
                          actividad?.tipoActividad?.unidadMedida?.nombre +
                          ')'
                        }
                        type='number'
                        fullWidth
                        value={cantidad}
                        onChange={this.handleInputChange}
                      />
                      <Typography align='center' color='error'>
                        {this.validator.message(
                          'cantidad',
                          cantidad,
                          'numeric|min:0,num'
                        )}
                      </Typography>
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl fullWidth>
                      <TextField
                        margin='dense'
                        id='um'
                        name='um'
                        label='Pendiente'
                        type='text'
                        fullWidth
                        value={`${(actividad?.plan - actividad?.real).toFixed(
                          2
                        )} ${actividad?.tipoActividad?.unidadMedida?.nombre}`}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl fullWidth>
                      <TextField
                        margin='dense'
                        id='um'
                        name='um'
                        label='T. Ejecución (Hrs)'
                        type='text'
                        helperText='ejecutado - pendiente'
                        fullWidth
                        value={
                          (cantidad * actividad?.tipoActividad?.horas)?.toFixed(
                            2
                          ) +
                          ' - ' +
                          (
                            (actividad?.plan - actividad?.real) *
                            actividad?.tipoActividad?.horas
                          )?.toFixed(2)
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl fullWidth>
                      <TextField
                        margin='dense'
                        id='valor'
                        name='valor'
                        label='Valor'
                        type='number'
                        fullWidth
                        value={(
                          cantidad * actividad?.tipoActividad?.tarifa
                        ).toFixed(2)}
                      />
                    </FormControl>
                  </Grid>

                  {/* fecha */}
                  <Grid item xs={6}>
                    <InputLabel id='fechalbl'>Fecha</InputLabel>
                    <FormControl fullWidth>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DateTimePicker
                          labelId='fechalbl'
                          name='fecha'
                          value={fecha}
                          onChange={(date) => this.handleDateChange(date)}
                          locale={'es'}
                          format={'YYYY-MM-DD hh:mm'}
                        />
                      </MuiPickersUtilsProvider>
                      <Typography align='center' color='error'>
                        {this.validator.message('fecha', fecha, 'required')}
                      </Typography>
                    </FormControl>
                  </Grid>

                  {/* Participantes */}
                  <Grid item xs={6}>
                    <InputLabel id='participanteslbl'>Participantes</InputLabel>
                    <FormControl fullWidth>
                      <MultipleSelect
                        opciones={usuarios}
                        selecteds={participantes}
                        setSelecteds={(selecteds) =>
                          this.setState({ participantes: selecteds })
                        }
                      />
                      <Typography align='center' color='error'>
                        {this.validator.message(
                          'participantes',
                          participantes,
                          'required'
                        )}
                      </Typography>
                    </FormControl>
                  </Grid>

                  {/* Finalizada */}
                  <Grid item xs={12}>
                    <FormControlLabel
                      fullWidth
                      value='start'
                      control={
                        <Switch
                          checked={finalizada}
                          onChange={(event) =>
                            this.setState({ finalizada: event.target.checked })
                          }
                          color='primary'
                        />
                      }
                      label={
                        finalizada
                          ? 'Actividad Finalizada'
                          : 'Actividad en Proceso'
                      }
                      labelPlacement='end'
                    />
                  </Grid>

                  {/* observaciones */}
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <TextField
                        margin='dense'
                        id='observaciones'
                        name='observaciones'
                        label='Observaciones'
                        multiline
                        rows={4}
                        type='text'
                        fullWidth
                        value={observaciones}
                        onChange={this.handleInputChange}
                      />
                      <Typography align='center' color='error'>
                        {this.validator.message(
                          'observaciones',
                          observaciones,
                          'string|min:4|max:250'
                        )}
                      </Typography>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              {/* fotos */}
              <Grid item sm={4}>
                <FormControl fullWidth>
                  <ImagePicker
                    setFoto={this.setFoto}
                    nombre='Adjunto'
                    foto={foto}
                  />
                </FormControl>
              </Grid>
              {/* listado materiales */}
              <Grid item xs={12}>
                <Typography gutterBottom variant='subtitle1' component='h2'>
                  Materiales Usados
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <List>
                  {actividad?.materiales_actividads?.map((m) => {
                    return (
                      <ListItem key={m.id}>
                        <Grid
                          container
                          display='flex'
                          direction='row'
                          justify='space-between'
                          alignItems='center'
                          spacing={2}
                        >
                          <Grid item xs={6}>
                            <FormControl fullWidth>
                              <TextField
                                label='Nombre'
                                fullWidth
                                value={m.nombre}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={2}>
                            <FormControl fullWidth>
                              <TextField
                                label='U/M'
                                fullWidth
                                value={
                                  m.unidadMedida.nombre === 'm³'
                                    ? 'Sacos'
                                    : m.unidadMedida.nombre
                                }
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={2}>
                            <FormControl fullWidth>
                              <TextField
                                label='Disponible'
                                type='number'
                                fullWidth
                                value={
                                  m.unidadMedida.nombre === 'm³'
                                    ? (m.plan - m.real) * 18
                                    : m.plan - m.real
                                }
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={2}>
                            <FormControl fullWidth>
                              <TextField
                                label='Usado'
                                type='number'
                                fullWidth
                                value={m.value || 0}
                                onChange={(event) =>
                                  this.handleMaterialChange(event, m)
                                }
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </ListItem>
                    );
                  })}
                </List>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleClose}
              variant={'outlined'}
              color='primary'
            >
              Cancelar
            </Button>
            <Button
              onClick={this.handleSubmit}
              variant={'contained'}
              color='primary'
              disabled={loading}
            >
              {loading ? <CircularProgress /> : 'Agregar'}
            </Button>
          </DialogActions>
        </Dialog>
        {alerta.mensaje !== '' && (
          <MensajeFlotante mensaje={alerta.mensaje} tipo={alerta.tipo} />
        )}
      </Fragment>
    );
  }

  componentDidMount() {
    let config = {
      headers: {
        token: this.props.token,
      },
    };
    axios
      .all([
        axios.get(`${API_URL}/actividades`, config),
        axios.get(`${API_URL}/usuarios`, config),
      ])
      .then((res) => {
        this.setState({ actividades: res[0].data });
        this.setState({ usuarios: res[1].data });
        this.setState({ loading: false });
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    name !== 'cantidad'
      ? this.setState({ [name]: value })
      : value >= 0 && this.setState({ cantidad: value });
  }

  handleDateChange(event) {
    this.setState({
      fecha: moment(event).format('YYYY-MM-DD HH:mm:ss'),
    });
  }

  handleMaterialChange(event, material) {
    const { materialesActividad } = this.state;
    const val = event.target.value;
    let mat = materialesActividad.map((m) => {
      if (m.id === material.id && val >= 0) m.value = val;
      return m;
    });
    this.setState({ materiales: mat });
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    const {
      fecha,
      foto,
      cantidad,
      observaciones,
      participantes,
      materialesActividad,
      finalizada,
    } = this.state;
    const { actividad, token } = this.props;

    const bodyFormData = new FormData();
    bodyFormData.append('fecha', fecha);
    bodyFormData.append('foto', foto);
    bodyFormData.append('cantidad', cantidad);
    bodyFormData.append('valor', cantidad * actividad?.tipoActividad?.tarifa);
    bodyFormData.append('actividadId', actividad?.id);
    bodyFormData.append('observaciones', observaciones);

    if (this.validator.allValid()) {
      await axios({
        method: 'post',
        url: `${API_URL}/reportes`,
        data: bodyFormData,
        headers: {
          'Content-Type': 'multipart/form-data',
          token: token,
        },
      })
        .then(async (res) => {
          this.setState({ open: false, loading: false });
          this.props.getReportes();
          participantes.map(async (user) => {
            return await axios({
              method: 'post',
              url: `${API_URL}/usuarios_reporte`,
              data: {
                reporteId: res.data.id,
                usuarioId: user.id,
              },
              headers: {
                token: token,
              },
            }).catch((error) => console.log(error.message));
          });

          materialesActividad.map(async (m) => {
            return await axios({
              method: 'post',
              url: `${API_URL}/materiales_reporte`,
              data: {
                reporteId: res.data.id,
                materialesActividadId: m.id,
                cantidad: m.value || 0,
              },
              headers: {
                token: token,
              },
            })
              .then(async () => {
                await axios({
                  method: 'post',
                  url: `${API_URL}/materiales_actividad`,
                  data: {
                    ...m,
                    ...{
                      real:
                        m.unidadMedida.nombre === 'm³'
                          ? m.real + (m.value || 0) / 18
                          : m.real + (m.value || 0),
                    },
                  },
                  headers: {
                    token: token,
                  },
                }).catch((error) => console.log(error));
              })
              .catch((error) => console.log(error));
          });

          await axios({
            method: 'put',
            url: `${API_URL}/actividades/${actividad.id}`,
            data: {
              objetoObraId: actividad.objetoObraId,
              tipoActividadId: actividad.tipoActividadId,
              plan: actividad.plan,
              real: actividad.real + cantidad,
              finalizada: finalizada ? 1 : 0,
              costo: actividad.costo,
            },
            headers: {
              token: token,
            },
          })
            .then((res) => console.log(res.data))
            .catch((err) => console.log(err.message));
        })
        .catch((error) => console.log(error.message));
    } else {
      this.validator.showMessages();
      this.forceUpdate();
      this.setState({ loading: false });
    }
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
    this.props.getReportes();
  };

  setFoto = (url) => {
    this.setState({ foto: url });
  };
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    permissions: state.auth.permissions,
  };
};
export default connect(mapStateToProps)(ReporteForm);
