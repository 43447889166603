import React, { Component } from 'react';

import {
  Button,
  DialogActions,
  TextField,
  DialogTitle,
  DialogContent,
  Dialog,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import axios from 'axios';
import { connect } from 'react-redux';
import { API_URL } from '../../config';
import SimpleReactValidator from 'simple-react-validator';
import '../../locales/SimpleReactValidator';
import MensajeFlotante from '../../components/MensajeFlotante';

class ObjetoObraEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.objetoObra.id,
      nombre: props.objetoObra.nombre,
      obraId: props.objetoObra.obraId,
      open: false,
      loading: false,
      obras: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validator = new SimpleReactValidator({ locale: 'es' });
  }

  render() {
    const { nombre, obraId, obras } = this.state;
    const { permissions } = this.props;

    return !permissions.editarObjetoObra ? (
      <MensajeFlotante mensaje='Sin acceso' tipo='warning' />
    ) : (
      <div>
        <Button
          variant='contained'
          color='primary'
          onClick={this.handleClickOpen}
          m={2}
        >
          Editar
        </Button>
        <Dialog
          fullWidth
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby='form-dialog-title'
          maxWidth='xs'
        >
          <DialogTitle id='form-dialog-title'>
            Editar Objeto de Obra
          </DialogTitle>
          <DialogContent>
            <FormControl fullWidth>
              <TextField
                autoFocus
                margin='dense'
                id='nombre'
                name='nombre'
                label='Nombre'
                type='text'
                fullWidth
                value={nombre}
                onChange={this.handleChange}
              />
              <Typography align='center' color='error'>
                {this.validator.message(
                  'nombre',
                  nombre,
                  'required|string|min:4|max:50'
                )}
              </Typography>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id='obraIdlbl'>Obra</InputLabel>
              <Select
                labelId='obraIdlbl'
                id='obraId'
                name='obraId'
                value={obraId}
                onChange={this.handleChange}
              >
                {obras.map(function (item) {
                  return <MenuItem value={item.value}>{item.display}</MenuItem>;
                })}
              </Select>
              <Typography align='center' color='error'>
                {this.validator.message('obraId', obraId, 'required')}
              </Typography>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleClose}
              variant={'outlined'}
              color='primary'
            >
              Cancelar
            </Button>
            <Button
              onClick={this.handleSubmit}
              variant={'contained'}
              color='primary'
              disabled={this.state.loading}
            >
              {this.state.loading ? <CircularProgress /> : 'Editar'}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  componentDidMount() {
    const config = {
      headers: {
        token: this.props.token,
      },
    };
    axios
      .get(API_URL + '/obras', config)
      .then((res) => {
        let obras = res.data.map((item) => {
          return {
            value: item.id,
            display: item.nombre,
          };
        });
        this.setState({
          obras: obras,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    const { id, nombre, obraId } = this.state;
    const model = { nombre, obraId };
    const config = {
      headers: {
        token: this.props.token,
      },
    };
    if (this.validator.allValid()) {
      axios
        .put(API_URL + `/objetos_obra/${id}`, model, config)
        .then(() => {
          this.props.getObjetosObra();
          this.setState({ open: false, loading: false });
        })
        .catch((err) => {
          console.log(err.message);
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
      this.setState({ loading: false });
    }
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
    this.props.getObjetosObra();
  };
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    permissions: state.auth.permissions,
  };
};
export default connect(mapStateToProps)(ObjetoObraEdit);
