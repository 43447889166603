import React from 'react';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function MultipleSelect({
  opciones,
  selecteds,
  setSelecteds,
  disabled = false,
}) {
  const handleChange = (event) => {
    setSelecteds(event.target.value);
  };

  return (
    <Select
      labelId='mutiple-checkbox-label'
      id='mutiple-checkbox'
      multiple
      value={selecteds}
      onChange={handleChange}
      input={<Input multiline />}
      renderValue={(value) => {
        let render = value.map(
          (selected) => selected.nombre + ' ' + selected.apellidos
        );
        return render.join(', ');
      }}
      disabled={disabled}
      MenuProps={MenuProps}
      style={{ marginBottom: 10 }}
    >
      {opciones.map((option) => (
        <MenuItem key={option.id} value={option}>
          {/* <Checkbox checked={value.indexOf(option) > -1} /> */}
          <Checkbox
            checked={selecteds.filter(({ id }) => id === option.id).length > 0}
          />
          <ListItemText primary={option.nombre + ' ' + option.apellidos} />
        </MenuItem>
      ))}
    </Select>
  );
}
