import React, { Fragment, useState } from 'react';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

function YearMonthPicker({ setSelected }) {
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setSelected(date);
  };

  return (
    <Fragment>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DatePicker
          variant='inline'
          openTo='month'
          views={['year', 'month']}
          value={selectedDate}
          onChange={(date) => handleDateChange(date)}
        />
      </MuiPickersUtilsProvider>
    </Fragment>
  );
}

export default YearMonthPicker;
