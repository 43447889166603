import SimpleReactValidator from 'simple-react-validator';

SimpleReactValidator.addLocale('es', {
  accepted: 'Debe ser aceptado.',
  after: 'Debe ser una fecha posterior a :date.',
  after_or_equal: 'Debe ser una fecha posterior o igual a :date.',
  alpha: 'Sólo debe contener letras.',
  alpha_space: 'Solo se permiten letras y espacios',
  alpha_num: 'Solo se permiten letras y números',
  alpha_num_space: 'Solo se permiten letras, números y espacios',
  alpha_num_dash: 'Solo debe contener letras, números, guiones y guiones bajos',
  alpha_num_dash_space:
    'Solo debe contener letras, números, guiones, guiones bajos y espacios',
  array: 'Debe ser un conjunto.',
  before: 'Debe ser una fecha anterior a :date.',
  before_or_equal: 'Debe ser una fecha anterior o igual a :date.',
  between: 'Tiene que estar entre :min - :max.',
  boolean: 'El campo debe tener un valor verdadero o falso.',
  // card_exp             : 'The must be a valid expiration date.',
  // card_num             : 'The must be a valid credit card number.',
  // currency             : 'The must be a valid currency.',
  date: 'No es una fecha válida.',
  date_equals: 'Debe ser una fecha igual a :date.',
  email: 'No es un correo válido',
  in: 'es inválido :values.',
  integer: 'Debe ser un número entero.',
  max: 'No debe ser mayor a :max.',
  min: 'El tamaño de debe ser de al menos :min.',
  not_in: 'es inválido :values.',
  not_regex: 'El formato del campo no es válido.',
  numeric: 'Debe ser numérico.',
  phone: 'Debe ser un teléfono válido',
  regex:
    'Debe contener al menos 6 caracteres, una mayúscula o minúscula, un número o un caracter especial.',
  required: 'El campo es obligatorio.',
  size: 'El tamaño de debe ser :size.',
  string: 'El campo debe ser una cadena de caracteres.',
  //of               : 'The is not the correct of .',
  url: 'El formato es inválido.',
});
