import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  FormControl,
  Grid,
  InputLabel,
  TextField,
  Tabs,
  Tab,
  Typography,
  Box,
} from '@material-ui/core';

import MomentUtils from '@date-io/moment';
import moment from 'moment';
import 'moment/locale/es';
import SelectSearch from '../../components/SelectSearch';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Axios from 'axios';
import { API_URL } from '../../config';

export default function FullWidthTabs({
  tipoActividad,
  obraId,
  inicio,
  setInicio,
  token,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [actividad, setActividad] = React.useState({});
  const [actividades, setActividades] = React.useState([]);
  const [objeto, setObjeto] = React.useState({});
  const [objetos, setObjetos] = React.useState([]);
  const [cantidad, setCantidad] = React.useState(0);

  useEffect(() => {
    Axios({
      method: 'get',
      url: `${API_URL}/objetos_obra/obra/${obraId}`,
      headers: { token: token },
    })
      .then((res) => setObjetos(res.data))
      .catch((err) => console.log(err.message));
  }, [obraId, token]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setActividad({});
  };

  const filtrarObjeto = (objeto) => {
    setObjeto(objeto);
    getActividades(objeto?.id);
  };

  const getActividades = async (id) => {
    await Axios({
      method: 'get',
      url: `${API_URL}/actividades/objeto/${id}`,
      headers: { token: token },
    })
      .then((res) => setActividades(res.data))
      .catch((err) => console.log(err.message));
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <div className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor='primary'
        textColor='primary'
        centered
      >
        <Tab label='Manual' />
        <Tab label='Inicio-Inicio' />
        <Tab label='Inicio-Fin' />
        <Tab label='Inicio-Retardo' />
      </Tabs>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        {/* Inicio - Manual */}
        <TabPanel value={value} index={0} dir={theme.direction}>
          <InputLabel>Fecha de Inicio</InputLabel>
          <FormControl fullWidth>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DateTimePicker
                labelId='iniciolbl'
                name='inicio'
                value={inicio}
                onChange={(date) => setInicio(moment(date))}
                locale={'es'}
                format={'YYYY-MM-DD HH:mm'}
              />
            </MuiPickersUtilsProvider>
          </FormControl>
        </TabPanel>
        {/* Inicio - Inicio */}
        <TabPanel value={value} index={1} dir={theme.direction}>
          <InputLabel>
            La Actividad comenzará junto con la actividad seleccionada
          </InputLabel>
          <FormControl fullWidth>
            <SelectSearch
              label='Objeto de Obra'
              opciones={objetos}
              selected={objeto}
              disabled={!tipoActividad}
              setSelected={(objeto) => filtrarObjeto(objeto)}
            />
          </FormControl>
          <FormControl fullWidth>
            <SelectSearch
              label='Actividad'
              opciones={actividades}
              selected={actividad}
              disabled={!tipoActividad}
              setSelected={(act) => {
                setActividad(act);
                setInicio(moment(act?.inicio));
              }}
            />
            <Typography align='center' color='info'>
              Inicio: {inicio?.format('YYYY-MM-DD HH:mm')}
            </Typography>
          </FormControl>
        </TabPanel>
        {/* Inicio - Fin */}
        <TabPanel value={value} index={2} dir={theme.direction}>
          <InputLabel>
            La Actividad comenzará al finalizar la actividad seleccionada
          </InputLabel>
          <FormControl fullWidth>
            <SelectSearch
              label='Objeto de Obra'
              opciones={objetos}
              selected={objeto}
              disabled={!tipoActividad}
              setSelected={(objeto) => filtrarObjeto(objeto)}
            />
          </FormControl>
          <FormControl fullWidth>
            <SelectSearch
              label='Actividad'
              opciones={actividades}
              selected={actividad}
              disabled={!tipoActividad}
              setSelected={(act) => {
                setActividad(act);
                setInicio(moment(act?.fin));
              }}
            />
            <Typography align='center' color='info'>
              Inicio: {inicio?.format('YYYY-MM-DD HH:mm')}
            </Typography>
          </FormControl>
        </TabPanel>
        {/* Inicio - Retardo */}
        <TabPanel value={value} index={3} dir={theme.direction}>
          <Grid
            container
            direction='row'
            justify='flex-start'
            alignItems='flex-end'
            spacing={2}
          >
            {/* Fecha Inicio */}
            <InputLabel>
              La Actividad comenzará con los días de retardo indicados desde el
              comienzo de la actividad seleccionada
            </InputLabel>
            <FormControl fullWidth>
              <SelectSearch
                label='Objeto de Obra'
                opciones={objetos}
                selected={objeto}
                disabled={!tipoActividad}
                setSelected={(objeto) => filtrarObjeto(objeto)}
              />
            </FormControl>
            <Grid item xs={8}>
              <FormControl fullWidth>
                <SelectSearch
                  label='Actividad'
                  opciones={actividades}
                  selected={actividad}
                  disabled={!tipoActividad}
                  setSelected={(act) => {
                    setActividad(act);
                    setInicio(moment(act?.inicio).add(cantidad, 'days'));
                  }}
                />
              </FormControl>
            </Grid>
            {/* Cantidad */}
            <Grid item xs={4}>
              <FormControl fullWidth>
                <TextField
                  margin='dense'
                  id='cantidad'
                  name='cantidad'
                  label='Días de Retardo'
                  type='number'
                  fullWidth
                  disabled={!tipoActividad}
                  value={cantidad}
                  onChange={(e) => {
                    setCantidad(e.target.value);
                    setInicio(
                      moment(actividad?.inicio).add(e.target.value, 'days')
                    );
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography align='center' color='info'>
                Inicio: {inicio?.format('YYYY-MM-DD HH:mm')}
              </Typography>
            </Grid>
          </Grid>
        </TabPanel>
      </SwipeableViews>
    </div>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
}));
