import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from 'react-router-dom';

import PermIdentityOutlinedIcon from '@material-ui/icons/PermIdentityOutlined';
import PermDataSettingOutlinedIcon from '@material-ui/icons/PermDataSettingOutlined';
import FormatColorFillIcon from '@material-ui/icons/FormatColorFill';
import ViewQuiltOutlinedIcon from '@material-ui/icons/ViewQuiltOutlined';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import BookmarksIcon from '@material-ui/icons/Bookmarks';
import DashboardIcon from '@material-ui/icons/Dashboard';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import Logo from '../assets/logo.jpeg';
import { connect } from 'react-redux';

const styles = (theme) => ({
  categoryHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  categoryHeaderPrimary: {
    color: theme.palette.common.white,
  },
  item: {
    paddingTop: 1,
    paddingBottom: 1,
    color: 'rgba(255, 255, 255, 0.7)',
    '&:hover,&:focus': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
    },
  },
  itemCategory: {
    backgroundColor: '#232f3e',
    boxShadow: '0 -1px 0 #404854 inset',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  firebase: {
    fontSize: 24,
    color: theme.palette.common.white,
  },
  itemActiveItem: {
    color: '#4fc3f7',
  },
  itemPrimary: {
    fontSize: 'inherit',
  },
  itemIcon: {
    minWidth: 'auto',
    marginRight: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
  },
  logo: {
    minHeight: '20vh',
    background: `url(${Logo})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
});

function Navigator(props) {
  const { classes, to, ...other } = props;
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    let cat = [
      {
        id: 'Gestión',
        children: [
          {
            id: 'Pizarra',
            to: '/',
            granted: true,
            icon: <DashboardIcon />,
          },
          {
            id: 'Planificación de Actividades',
            to: '/planificacion',
            granted: !props.permissions.esObrero,
            icon: <ViewQuiltOutlinedIcon />,
          },
          {
            id: 'Gestión de Obras',
            to: '/gestion',
            granted: !props.permissions.esObrero,
            icon: <ViewQuiltOutlinedIcon />,
          },
        ],
      },
    ];
    cat.push({
      id: 'Estadística',
      children: [
        {
          id: 'Reportes de Tareas',
          to: '/estadisticas/reportes',
          granted: props.permissions.menuEstadisticaCostos,
          icon: <AssessmentOutlinedIcon />,
        },
        {
          id: 'Pagos a Trabajadores',
          to: '/estadisticas/pagos',
          granted: props.permissions.menuEstadisticaPagos,
          icon: <AssessmentOutlinedIcon />,
        },
        {
          id: 'Costos de Obras',
          to: '/estadisticas/costos',
          granted: props.permissions.menuEstadisticaCostos,
          icon: <AssessmentOutlinedIcon />,
        },

        {
          id: 'Planificación de Materiales',
          to: '/estadisticas/materiales',
          granted: props.permissions.menuEstadisticaMateriales,
          icon: <AssessmentOutlinedIcon />,
        },
      ],
    });
    props.permissions.esAdmin &&
      cat.push(
        {
          id: 'Base de Datos',
          children: [
            {
              id: 'Importar Base de Datos',
              to: '/importacion',
              granted: props.permissions.menuTipoActividad,
              icon: <CloudUploadIcon />,
            },
            {
              id: 'Actividades',
              to: '/actividades/tipos',
              granted: props.permissions.menuTipoActividad,
              icon: <BookmarksIcon />,
            },
            {
              id: 'Especialidades',
              to: '/especialidades',
              granted: props.permissions.menuEspecialidad,
              icon: <AccountTreeIcon />,
            },
            {
              id: 'Materiales',
              to: '/materiales',
              granted: props.permissions.menuMaterial,
              icon: <FormatColorFillIcon />,
            },
            {
              id: 'Unidades de Medida',
              to: '/um',
              granted: props.permissions.menuUnidadMedida,
              icon: <PermDataSettingOutlinedIcon />,
            },
          ],
        },
        {
          id: 'Seguridad',
          children: [
            {
              id: 'Usuarios',
              to: '/usuarios',
              granted: props.permissions.menuUnidadMedida,
              icon: <PermIdentityOutlinedIcon />,
            },
          ],
        }
      );
    setCategories(cat);
  }, [props]);

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <Link to={to} ref={ref} {...itemProps} />
      )),
    [to]
  );

  return (
    <Drawer variant='permanent' {...other}>
      <List disablePadding>
        <ListItem
          component={renderLink}
          to='/'
          className={clsx(
            classes.firebase,
            classes.item,
            classes.itemCategory,
            classes.logo
          )}
        />
        {categories.map(({ id, children }) => (
          <React.Fragment key={id}>
            <ListItem className={classes.categoryHeader}>
              <ListItemText
                classes={{
                  primary: classes.categoryHeaderPrimary,
                }}
              >
                {id}
              </ListItemText>
            </ListItem>
            {children.map(
              ({ id: childId, icon, active, granted, to }) =>
                granted && (
                  <ListItem
                    component={renderLink}
                    key={childId}
                    button
                    to={to}
                    className={clsx(
                      classes.item,
                      active && classes.itemActiveItem
                    )}
                  >
                    <ListItemIcon className={classes.itemIcon}>
                      {icon}
                    </ListItemIcon>
                    <ListItemText
                      classes={{
                        primary: classes.itemPrimary,
                      }}
                    >
                      {childId}
                    </ListItemText>
                  </ListItem>
                )
            )}

            <Divider className={classes.divider} />
          </React.Fragment>
        ))}
      </List>
    </Drawer>
  );
}

Navigator.propTypes = {
  classes: PropTypes.object.isRequired,
  icon: PropTypes.element,
  primary: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  return { permissions: state.auth.permissions };
};

export default withStyles(styles)(connect(mapStateToProps, null)(Navigator));
