import Axios from 'axios';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { API_URL } from '../../config';
import { Button, Grid } from '@material-ui/core';
import ReactExport from 'react-export-excel';
import ChromeReaderModeIcon from '@material-ui/icons/ChromeReaderMode';
import moment from 'moment';
import SelectSearch from '../../components/SelectSearch';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class Cronograma extends Component {
  constructor(props) {
    super(props);
    this.state = {
      obrasSelect: [],
      obra: null,
      obras: [],
      resumen: [],
      matObras: [],
      matObjetos: [],
    };
  }

  render() {
    const {
      obras,
      obrasSelect,
      obra,
      resumen,
      matObras,
      matObjetos,
    } = this.state;
    return (
      <Grid container spacing={2} justify='flex-start' alignItems='flex-end'>
        <Grid item xs={12} md={2}>
          <SelectSearch
            label='Obra'
            opciones={obrasSelect}
            selected={obra}
            setSelected={(obra) => this.setObras(obra)}
            openDialog={(opened) => this.setState({ obraDialog: opened })}
          />
        </Grid>
        <Grid item xs={12} md={10}>
          <ExcelFile
            element={
              <Button
                variant='contained'
                color='default'
                startIcon={<ChromeReaderModeIcon />}
              >
                Exportar Obras
              </Button>
            }
          >
            <ExcelSheet data={obras} name='Reporte de Obras'>
              <ExcelColumn label='Obra' value='obra' />
              <ExcelColumn label='Objeto' value='objeto' />
              <ExcelColumn label='Actividad' value='actividad' />
              <ExcelColumn label='U/M' value='um' />
              <ExcelColumn label='Cantidad Plan' value='cantPlan' />
              <ExcelColumn label='Cantidad Real' value='cantReal' />
              <ExcelColumn label='P/U' value='pu' />
              <ExcelColumn label='Costo Plan' value='costoPlan' />
              <ExcelColumn label='Costo Real' value='costoReal' />
              <ExcelColumn label='Tiempo Plan' value='tiempoPlan' />
              <ExcelColumn label='Tiempo Real' value='tiempoReal' />
              <ExcelColumn label='Inicio' value='inicio' />
              <ExcelColumn label='Fin' value='fin' />
            </ExcelSheet>
          </ExcelFile>
          <ExcelFile
            element={
              <Button
                style={{ marginLeft: 10 }}
                variant='contained'
                color='default'
                startIcon={<ChromeReaderModeIcon />}
              >
                Resumen de Obras
              </Button>
            }
          >
            <ExcelSheet data={resumen} name='Resumen de Obras'>
              <ExcelColumn label='Obra' value='obra' />
              <ExcelColumn label='Cantidad Plan' value='cantPlan' />
              <ExcelColumn label='Cantidad Real' value='cantReal' />
              <ExcelColumn label='Costo Plan' value='costoPlan' />
              <ExcelColumn label='Costo Real' value='costoReal' />
              <ExcelColumn label='Tiempo Plan' value='tiempoPlan' />
              <ExcelColumn label='Tiempo Real' value='tiempoReal' />
              <ExcelColumn label='Inicio' value='inicio' />
              <ExcelColumn label='Fin' value='fin' />
            </ExcelSheet>
          </ExcelFile>
          <ExcelFile
            element={
              <Button
                style={{ marginLeft: 10 }}
                variant='contained'
                color='default'
                startIcon={<ChromeReaderModeIcon />}
              >
                Materiales por Objetos
              </Button>
            }
          >
            <ExcelSheet data={matObjetos} name='Cronograma de Obras'>
              <ExcelColumn label='Obra' value='obra' />
              <ExcelColumn label='Objeto' value='objeto' />
              <ExcelColumn label='Material' value='material' />
              <ExcelColumn label='U/M' value='um' />
              <ExcelColumn label='Cantidad Plan' value='cantPlan' />
              <ExcelColumn label='Cantidad Real' value='cantReal' />
              <ExcelColumn label='Importe Plan' value='importePlan' />
              <ExcelColumn label='Importe Real' value='importeReal' />
              <ExcelColumn label='P/U' value='pu' />
            </ExcelSheet>
          </ExcelFile>
          <ExcelFile
            element={
              <Button
                style={{ marginLeft: 10 }}
                variant='contained'
                color='default'
                startIcon={<ChromeReaderModeIcon />}
              >
                Materiales por Obras
              </Button>
            }
          >
            <ExcelSheet data={matObras} name='Cronograma de Obras'>
              <ExcelColumn label='Obra' value='obra' />
              <ExcelColumn label='Material' value='material' />
              <ExcelColumn label='U/M' value='um' />
              <ExcelColumn label='Cantidad Plan' value='cantPlan' />
              <ExcelColumn label='Cantidad Real' value='cantReal' />
              <ExcelColumn label='Importe Plan' value='importePlan' />
              <ExcelColumn label='Importe Real' value='importeReal' />
              <ExcelColumn label='P/U' value='pu' />
            </ExcelSheet>
          </ExcelFile>
        </Grid>
      </Grid>
    );
  }

  componentDidMount() {
    this.getObras();
  }

  getObras = () => {
    const { token } = this.props;
    Axios({
      method: 'get',
      url: `${API_URL}/obras`,
      headers: { token: token },
    })
      .then((res) => {
        this.setState({ obrasSelect: res.data });
        let obras = [];
        let resumen = [];
        let matObjetos = [];
        let matObras = [];
        // console.log(res.data);

        res.data.map((obra) => {
          let resum = {
            obra: obra.nombre,
            cantPlan: 0,
            cantReal: 0,
            tiempoPlan: 0,
            tiempoReal: 0,
            costoPlan: 0,
            costoReal: 0,
            inicio: moment().format('YYYY-MM-DD HH:mm'),
            fin: moment().format('YYYY-MM-DD HH:mm'),
          };

          obra.objetoObras.map((objeto) =>
            objeto.actividads.map((actividad) => {
              resum.cantPlan += actividad.plan;
              resum.cantReal += actividad.real;
              resum.tiempoPlan +=
                actividad.tipoActividad?.horas * actividad.plan;
              resum.tiempoReal +=
                actividad.tipoActividad?.horas * actividad.real;
              resum.costoPlan +=
                actividad.tipoActividad?.tarifa * actividad.plan;
              resum.costoReal +=
                actividad.tipoActividad?.tarifa * actividad.real;
              resum.inicio = moment(actividad.inicio).isBefore(resum.inicio)
                ? moment(actividad.inicio).format('YYYY-MM-DD HH:mm')
                : moment(resum.inicio).format('YYYY-MM-DD HH:mm');
              resum.fin = moment(actividad.fin).isAfter(resum.fin)
                ? moment(actividad.fin).format('YYYY-MM-DD HH:mm')
                : moment(resum.fin).format('YYYY-MM-DD HH:mm');

              obras.push({
                obra: obra.nombre,
                objeto: objeto?.nombre,
                actividad: actividad.tipoActividad?.nombre,
                um: actividad.tipoActividad?.unidadMedida?.nombre,
                cantPlan: actividad.plan,
                cantReal: actividad.real,
                tiempoPlan: actividad.tipoActividad?.horas * actividad.plan,
                tiempoReal: actividad.tipoActividad?.horas * actividad.real,
                costoPlan: actividad.tipoActividad?.tarifa * actividad.plan,
                costoReal: actividad.tipoActividad?.tarifa * actividad.real,
                pu: actividad.tipoActividad?.tarifa,
                inicio: moment(actividad.inicio).format('YYYY-MM-DD HH:mm'),
                fin: moment(actividad.fin).format('YYYY-MM-DD HH:mm'),
              });

              actividad.materiales_actividads.map((material) => {
                let iObj = matObjetos.findIndex(
                  (k) =>
                    k.obra === obra.nombre &&
                    k.objeto === objeto?.nombre &&
                    k.material === material.nombre
                );
                if (iObj !== -1) {
                  matObjetos[iObj].cantPlan += material.plan;
                  matObjetos[iObj].cantReal += material.real;
                } else {
                  matObjetos.push({
                    obra: obra.nombre,
                    objeto: objeto?.nombre,
                    material: material.nombre,
                    um: material.unidadMedida?.nombre,
                    cantPlan: material.plan,
                    cantReal: material.real,
                    importePlan: 'por definir',
                    importeReal: 'por definir',
                    pu: 'por definir',
                  });
                }
                let iObr = matObras.findIndex(
                  (k) =>
                    k.obra === obra.nombre && k.material === material.nombre
                );
                if (iObr !== -1) {
                  matObras[iObr].cantPlan += material.plan;
                  matObras[iObr].cantReal += material.real;
                } else {
                  matObras.push({
                    obra: obra.nombre,
                    material: material.nombre,
                    um: material.unidadMedida?.nombre,
                    cantPlan: material.plan,
                    cantReal: material.real,
                    importePlan: 'por definir',
                    importeReal: 'por definir',
                    pu: 'por definir',
                  });
                }
                return true;
              });

              return true;
            })
          );
          resumen.push(resum);
          return true;
        });
        // console.log(resumen);
        this.setState({ obras, resumen, matObjetos, matObras });
      })
      .catch((err) => console.log(err.res?.data?.message || err.message));
  };

  setObras = (obra) => {
    let obras = [];
    let resumen = [];
    let matObjetos = [];
    let matObras = [];

    let resum = {
      obra: obra?.nombre,
      cantPlan: 0,
      cantReal: 0,
      tiempoPlan: 0,
      tiempoReal: 0,
      costoPlan: 0,
      costoReal: 0,
      inicio: moment().format('YYYY-MM-DD HH:mm'),
      fin: moment().format('YYYY-MM-DD HH:mm'),
    };
    obra?.objetoObras.map((objeto) =>
      objeto.actividads.map((actividad) => {
        console.log(actividad);
        resum.cantPlan += actividad.plan;
        resum.cantReal += actividad.real;
        resum.tiempoPlan += actividad.tipoActividad?.horas * actividad.plan;
        resum.tiempoReal += actividad.tipoActividad?.horas * actividad.real;
        resum.costoPlan += actividad.tipoActividad?.tarifa * actividad.plan;
        resum.costoReal += actividad.tipoActividad?.tarifa * actividad.real;
        resum.inicio = moment(actividad.inicio).isBefore(resum.inicio)
          ? moment(actividad.inicio).format('YYYY-MM-DD HH:mm')
          : moment(resum.inicio).format('YYYY-MM-DD HH:mm');
        resum.fin = moment(actividad.fin).isAfter(resum.fin)
          ? moment(actividad.fin).format('YYYY-MM-DD HH:mm')
          : moment(resum.fin).format('YYYY-MM-DD HH:mm');

        obras.push({
          obra: obra?.nombre,
          objeto: objeto?.nombre,
          actividad: actividad.tipoActividad?.nombre,
          um: actividad.tipoActividad?.unidadMedida?.nombre,
          cantPlan: actividad.plan,
          cantReal: actividad.real,
          tiempoPlan: actividad.tipoActividad?.horas * actividad.plan,
          tiempoReal: actividad.tipoActividad?.horas * actividad.real,
          costoPlan: actividad.tipoActividad?.tarifa * actividad.plan,
          costoReal: actividad.tipoActividad?.tarifa * actividad.real,
          pu: actividad.tipoActividad?.tarifa,
          inicio: moment(actividad.inicio).format('YYYY-MM-DD HH:mm'),
          fin: moment(actividad.fin).format('YYYY-MM-DD HH:mm'),
        });

        actividad.materiales_actividads.map((material) => {
          let iObj = matObjetos.findIndex(
            (k) =>
              k.obra === obra?.nombre &&
              k.objeto === objeto?.nombre &&
              k.material === material.nombre
          );
          if (iObj !== -1) {
            matObjetos[iObj].cantPlan += material.plan;
            matObjetos[iObj].cantReal += material.real;
          } else {
            matObjetos.push({
              obra: obra?.nombre,
              objeto: objeto?.nombre,
              material: material.nombre,
              um: material.unidadMedida?.nombre,
              cantPlan: material.plan,
              cantReal: material.real,
              importePlan: 'por definir',
              importeReal: 'por definir',
              pu: 'por definir',
            });
          }
          let iObr = matObras.findIndex(
            (k) => k.obra === obra?.nombre && k.material === material.nombre
          );
          if (iObr !== -1) {
            matObras[iObr].cantPlan += material.plan;
            matObras[iObr].cantReal += material.real;
          } else {
            matObras.push({
              obra: obra?.nombre,
              material: material.nombre,
              um: material.unidadMedida?.nombre,
              cantPlan: material.plan,
              cantReal: material.real,
              importePlan: 'por definir',
              importeReal: 'por definir',
              pu: 'por definir',
            });
          }
          return true;
        });

        return true;
      })
    );
    resumen.push(obras);
    this.setState({ obra, obras, resumen, matObjetos, matObras });
  };
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
  };
};

export default connect(mapStateToProps)(Cronograma);
