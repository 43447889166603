import React, { Component, Fragment } from 'react';
import Axios from 'axios';
import MUIDataTable from 'mui-datatables';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { actividadesOne } from '../../store/actions';

import { textLabels } from '../../locales/DataTables';
import Dialog from '../../components/Dialog';
import ActividadEdit from './Edit';
import ActividadForm from './Form';
import Reportes from '../Reportes';

import { CircularProgress, Grid, Button, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { API_URL } from '../../config';
import { Redirect } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/es';
import MensajeFlotante from '../../components/MensajeFlotante';

moment.locale('es');

const styles = (theme) => ({
  centered: {
    marginTop: theme.spacing(20),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
});

class Actividades extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rows: [],
      columns: [
        {
          name: 'tipoActividad.especialidad.nombre',
          label: 'Especialidad',
        },
        { name: 'tipoActividad.nombre', label: 'Tipo de Actividad' },
        {
          name: 'inicio',
          label: 'Inicio',
          options: {
            customBodyRender: (value, tableMeta, updateValue) =>
              moment(value).format('YYYY-MM-DD HH:mm'),
          },
        },
        {
          name: 'fin',
          label: 'Fin',
          options: {
            customBodyRender: (value, tableMeta, updateValue) =>
              moment(value).format('YYYY-MM-DD HH:mm'),
          },
        },
        {
          name: 'tipoActividad.tarifa',
          label: 'Tarifa',
        },
        {
          name: 'plan',
          label: 'Plan',
          options: {
            customBodyRender: (value, tableMeta, updateValue) =>
              value?.toFixed(2),
          },
        },
        {
          name: 'real',
          label: 'Real',
          options: {
            customBodyRender: (value, tableMeta, updateValue) =>
              value?.toFixed(2),
          },
        },
        {
          name: 'costo',
          label: 'Costo',
          options: {
            customBodyRender: (value, tableMeta, updateValue) =>
              '$' + value?.toFixed(2),
          },
        },
      ],
      selectedRows: [],
      open: false,
      sinAcceso: false,
      actividad: {
        id: null,
      },
      actividadesObra: [],
      loading: false,
    };
  }

  render() {
    const { classes, objeto, permissions, buttons = true } = this.props;
    const {
      rows,
      columns,
      actividad,
      open,
      loading,
      sinAcceso,
      actividadesObra,
    } = this.state;
    return objeto == null ? (
      <Redirect to={'/obras'} />
    ) : loading ? (
      <div className={classes.centered}>
        <CircularProgress />
      </div>
    ) : (
      <Fragment>
        <Grid
          container
          direction='row'
          justify='flex-start'
          alignItems='stretch'
          spacing={2}
        >
          <Grid item sm={12}>
            <Typography variant='h5'>Actividades del Objeto de Obra</Typography>
          </Grid>
          {permissions.crearActividad && buttons && (
            <Grid item>
              <ActividadForm
                getActividades={this.getActividades}
                actividades={actividadesObra}
                objeto={objeto}
              />
            </Grid>
          )}
          {permissions.editarActividad && buttons && (
            <Grid item>
              {actividad?.id ? (
                <ActividadEdit
                  getActividades={this.getActividades}
                  actividad={actividad}
                  actividades={actividadesObra}
                  objeto={objeto}
                />
              ) : (
                <Button variant='contained' color='primary' disabled m={2}>
                  Editar
                </Button>
              )}
            </Grid>
          )}
          {permissions.listarActividad && (
            <Grid item sm={12}>
              <MUIDataTable
                title={'Listado de Actividades'}
                data={rows}
                columns={columns}
                options={this.options}
              />
            </Grid>
          )}
          <Dialog
            fullWidth
            open={open}
            dialogTitle={'Eliminar Actividades seleccionados'}
            dialogText={
              'ATENCIÓN! Esta acción es irreversible. ¿Desea continuar?'
            }
            handleClose={this.handleClose}
            handleConfirm={this.handleDelete}
          />
          {sinAcceso && (
            <MensajeFlotante
              mensaje='No tiene permisos para realizar esta acción'
              tipo='warning'
            />
          )}
        </Grid>
        {actividad?.id && (
          <Grid item md={12}>
            {permissions.listarReporte && <Reportes actividad={actividad} />}
          </Grid>
        )}
      </Fragment>
    );
  }

  componentDidMount() {
    this.getActividades();
  }

  getActividades = () => {
    this.setState({ loading: true });
    const { objeto, token, permissions, userId } = this.props;
    let url = !permissions.esObrero
      ? `${API_URL}/actividades/objeto/${objeto.id}`
      : `${API_URL}/actividades/objeto/${objeto.id}/usuario/${userId}`;
    objeto &&
      Axios({
        method: 'get',
        url: url,
        headers: {
          token: token,
        },
      })
        .then((res) => {
          this.setState({
            rows: res.data || [],
            loading: false,
            actividad: { id: null },
          });
        })
        .catch((err) => console.log(err.message));

    Axios({
      method: 'get',
      url: `${API_URL}/actividades/obra/${objeto.obraId}`,
      headers: { token: token },
    })
      .then((res) => {
        this.setState({ actividadesObra: res.data, loading: false });
        !(res.data.length > 0) &&
          this.setState({ actividad: null, reportes: [] });
      })
      .catch((err) => console.log(err.message));
  };

  handleDelete = () => {
    this.setState({ open: false });
    this.state.selected.map((row) => {
      return this.eliminarActividad(this.state.rows[row.dataIndex].id);
    });
  };

  eliminarActividad = (id) => {
    const config = {
      headers: {
        token: this.props.token,
      },
    };
    Axios.delete(API_URL + `/actividades/${id}`, config)
      .then((res) => {
        this.getActividades();
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  handleClose = () => {
    this.setState({ open: false });
    this.getActividades();
  };

  setActividad = (index) => {
    const { rows } = this.state;
    this.setState({ actividad: rows[index] });
    this.props.dispatch(actividadesOne(rows[index]));
  };

  sinAcceso() {
    this.setState({ sinAcceso: true });
    this.getActividades();
  }

  options = {
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'rowSelectionChange':
          this.setState({
            selected: tableState.selectedRows.data,
          });
          if (tableState.selectedRows.data.length === 1) {
            this.setActividad(tableState.selectedRows.data[0].dataIndex);
          } else this.setState({ actividad: { id: null } });
          break;
        case 'rowDelete':
          this.props.permissions.eliminarActividad
            ? this.setState({ open: true })
            : this.sinAcceso();
          break;
        default:
          break;
      }
    },
    filter: true,
    selectableRows: 'multiple',
    filterType: 'dropdown',
    responsive: 'vertical',
    rowsPerPage: 5,
    selectableRowsOnClick: true,
    rowsPerPageOptions: [5, 10, 25, 50, 100],
    textLabels: textLabels,
    enableNestedDataAccess: '.',
  };
}

Actividades.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    userId: state.auth.userId,
    permissions: state.auth.permissions,
  };
};
export default connect(mapStateToProps)(withStyles(styles)(Actividades));
