import React, { Component } from 'react';
import Axios from 'axios';
import MUIDataTable from 'mui-datatables';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { obrasOne, obrasList, objetosList } from '../../store/actions';

import { textLabels } from '../../locales/DataTables';
import Dialog from '../../components/Dialog';
import ObraEdit from './Edit';
import ObraForm from './Form';
import ObjetosObra from '../ObjetosObra';
import ImportarObra from './ImportarObra';
import {
  CircularProgress,
  Grid,
  Typography,
  Link,
  Avatar,
  Button,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { API_URL } from '../../config';
import MensajeFlotante from '../../components/MensajeFlotante';
import moment from 'moment';

const styles = (theme) => ({
  centered: {
    marginTop: theme.spacing(20),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
});

class Obras extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      columns: [
        { name: 'nombre', label: 'Nombre' },
        {
          name: 'estado',
          label: 'Estado',
          options: {
            customBodyRender: (value, tableMeta, updateValue) =>
              value === '1' ? 'En Oferta' : 'En Ejecución',
          },
        },
        {
          name: 'inicio',
          label: 'Inicio',
          options: {
            customBodyRender: (value, tableMeta, updateValue) =>
              value ? moment(value).format('YYYY-MM-DD') : 'No definido',
          },
        },
        { name: 'direccion', label: 'Dirección' },
        { name: 'usuario.nombre', label: 'Jefe de Obra' },
        {
          name: 'foto',
          options: {
            filter: false,
            sort: false,
            empty: true,
            customBodyRender: (value, tableMeta, updateValue) => {
              return value ? (
                <Typography>
                  <Link href={value} target='_blank' rel='noopener'>
                    <Avatar alt={value} src={value} />
                  </Link>
                </Typography>
              ) : (
                ''
              );
            },
          },
        },
      ],
      selectedRows: [],
      deleteVisible: false,
      obra: {
        id: null,
        nombre: '',
        direccion: '',
        usuarioId: '',
        foto: '',
      },
      loading: false,
      sinAcceso: false,
    };
  }

  render() {
    const { classes, permissions } = this.props;
    const {
      loading,
      obra,
      rows,
      columns,
      deleteVisible,
      sinAcceso,
    } = this.state;
    return loading ? (
      <div className={classes.centered}>
        <CircularProgress />
      </div>
    ) : (
      <div>
        <Grid
          container
          direction='row'
          justify='flex-start'
          alignItems='stretch'
          spacing={2}
        >
          {permissions.crearObra && (
            <Grid item>
              <ObraForm getObras={this.getObras} />
            </Grid>
          )}
          {permissions.editarObra && (
            <Grid item>
              {!obra.id ? (
                <Button variant='contained' color='primary' disabled m={2}>
                  Editar
                </Button>
              ) : (
                <ObraEdit getObras={this.getObras} obra={obra} />
              )}
            </Grid>
          )}
          {permissions.editarObra && (
            <Grid item>
              {!obra.id ? (
                <Button variant='contained' color='primary' disabled m={2}>
                  Importar Obra
                </Button>
              ) : (
                <ImportarObra obraId={obra?.id} getObras={this.getObras} />
              )}
            </Grid>
          )}
          {permissions.listarObra && (
            <Grid item sm={12}>
              <MUIDataTable
                title={'Listado de Obras'}
                data={rows}
                columns={columns}
                options={this.options}
              />
            </Grid>
          )}
        </Grid>
        <Dialog
          fullWidth
          open={deleteVisible}
          dialogTitle={'Eliminar Obras seleccionadas'}
          dialogText={
            'ATENCIÓN! Al eliminar una obra se eliminarán los Objetos de Obra, las actividades y los reportes asociadas a ella. Esta acción es irreversible. ¿Desea continuar?'
          }
          handleClose={this.handleClose}
          handleConfirm={this.handleDelete}
        />
        {sinAcceso && (
          <MensajeFlotante
            mensaje='No tiene permisos para realizar esta acción'
            tipo='warning'
          />
        )}
        {permissions.listarObjetoObra && obra?.id !== null && (
          <Grid item>
            <ObjetosObra obra={obra} />
          </Grid>
        )}
      </div>
    );
  }

  componentDidMount() {
    this.getObras();
    this.setState({ obra: { id: null } });
  }

  getObras = () => {
    this.setState({ loading: true });
    const { token, userId, permissions } = this.props;
    let url = permissions.esAdmin
      ? '/obras'
      : permissions.esJefe
      ? `/obras_jefe/${userId}`
      : `/obras_usuario/${userId}`;
    Axios({
      method: 'get',
      url: `${API_URL}${url}`,
      headers: {
        token: token,
      },
    })
      .then((res) => {
        this.setState({ rows: res.data, loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err.message);
      });
  };

  cleanObra = () => {
    this.setState({
      obra: {
        id: null,
      },
    });
  };

  handleDelete = () => {
    this.setState({ loading: true });
    this.state.selected.map((row) => {
      return this.eliminarObra(this.state.rows[row.dataIndex].id);
    });
  };

  eliminarObra = (id) => {
    this.handleClose();
    Axios({
      method: 'delete',
      url: `${API_URL}/obras/${id}`,
      headers: {
        token: this.props.token,
      },
    })
      .then(() => this.getObras())
      .catch((err) => console.log(err));
  };

  handleClose = () => {
    this.setState({ deleteVisible: false });
    this.getObras();
  };

  setObra = (index) => {
    const { rows } = this.state;
    const { dispatch } = this.props;
    this.setState({ obra: rows[index] });
    dispatch(obrasList(rows));
    dispatch(obrasOne(rows[index]));
    dispatch(objetosList(rows[index]?.objetoObras));
  };

  sinAcceso() {
    this.setState({ sinAcceso: true });
    this.getObras();
  }

  options = {
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'rowSelectionChange':
          this.setState({
            selected: tableState.selectedRows.data,
          });
          if (tableState.selectedRows.data.length === 1) {
            this.setObra(tableState.selectedRows.data[0].dataIndex);
          } else this.setState({ obra: { id: null } });
          break;
        case 'rowDelete':
          this.props.permissions.eliminarObra
            ? this.setState({ deleteVisible: true })
            : this.sinAcceso();
          break;
        default:
          break;
      }
    },
    filter: true,
    selectableRows: 'multiple',
    filterType: 'dropdown',
    responsive: 'vertical',
    rowsPerPage: 5,
    selectableRowsOnClick: true,
    rowsPerPageOptions: [5, 10, 25, 50, 100],
    textLabels: textLabels,
    enableNestedDataAccess: '.',
  };
}

Obras.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    rolId: state.auth.rolId,
    userId: state.auth.userId,
    permissions: state.auth.permissions,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(Obras));
