import React, { Component } from 'react';
import moment from 'moment';

import Timeline from 'react-calendar-timeline';
import 'react-calendar-timeline/lib/Timeline.css';

var keys = {
  groupIdKey: 'id',
  groupTitleKey: 'title',
  groupRightTitleKey: 'rightTitle',
  itemIdKey: 'id',
  itemTitleKey: 'title',
  itemDivTitleKey: 'title',
  itemGroupKey: 'group',
  itemTimeStartKey: 'start',
  itemTimeEndKey: 'end',
  groupLabelKey: 'title',
};

export default class CronoActividades extends Component {
  constructor(props) {
    super(props);

    const visibleTimeStart = moment().startOf('month').valueOf();
    const visibleTimeEnd = moment().endOf('month').valueOf();

    this.state = {
      visibleTimeStart,
      visibleTimeEnd,
    };
  }

  onPrevClick = () => {
    const zoom = this.state.visibleTimeEnd - this.state.visibleTimeStart;
    this.setState((state) => ({
      visibleTimeStart: state.visibleTimeStart - zoom,
      visibleTimeEnd: state.visibleTimeEnd - zoom,
    }));
  };

  onNextClick = () => {
    const zoom = this.state.visibleTimeEnd - this.state.visibleTimeStart;
    this.setState((state) => ({
      visibleTimeStart: state.visibleTimeStart + zoom,
      visibleTimeEnd: state.visibleTimeEnd + zoom,
    }));
  };

  itemRenderer = ({
    item,
    timelineContext,
    itemContext,
    getItemProps,
    getResizeProps,
  }) => {
    const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
    const backgroundColor = itemContext.selected
      ? itemContext.dragging
        ? 'red'
        : item.selectedBgColor
      : item.bgColor;
    const borderColor = itemContext.resizing ? 'red' : item.color;
    return (
      <div
        {...getItemProps({
          style: {
            backgroundColor,
            color: item.color,
            borderColor,
            borderStyle: 'solid',
            borderWidth: 1,
            borderRadius: 4,
            borderLeftWidth: itemContext.selected ? 3 : 1,
            borderRightWidth: itemContext.selected ? 3 : 1,
          },
          onMouseDown: () => {
            console.log('on item click', item);
          },
        })}
      >
        {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : null}

        <div
          style={{
            height: itemContext.dimensions.height,
            overflow: 'hidden',
            paddingLeft: 3,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {itemContext.title}
        </div>

        {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : null}
      </div>
    );
  };

  render() {
    const { visibleTimeStart, visibleTimeEnd } = this.state;
    const { groups, items, handleTooltip } = this.props;

    return (
      <div style={{ maxWidth: 1024 }}>
        <button onClick={this.onPrevClick}>{'< Anterior'}</button>
        <button onClick={this.onNextClick}>{'Siguiente >'}</button>
        <Timeline
          scrollRef={(el) => (this.scrollRef = el)}
          groups={groups}
          items={items}
          keys={keys}
          itemTouchSendsClick={true}
          stackItems
          itemHeightRatio={0.75}
          showCursorLine
          canMove={false}
          canResize={false}
          itemRenderer={this.itemRenderer}
          onItemSelect={(itemId) => handleTooltip(itemId)}
          onItemDeselect={() => handleTooltip(-1)}
          visibleTimeStart={visibleTimeStart}
          visibleTimeEnd={visibleTimeEnd}
        />
      </div>
    );
  }
}
