import * as actionTypes from './actionTypes';

export const actividadesList = (actividades) => {
  return {
    type: actionTypes.ACTIVIDADES_LIST,
    actividades: actividades,
  };
};

export const actividadesOne = (actividad) => {
  return {
    type: actionTypes.ACTIVIDADES_ONE,
    actividad: actividad,
  };
};
