import React, { Component, Fragment } from 'react';
import Axios from 'axios';
import MUIDataTable from 'mui-datatables';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { objetosOne } from '../../store/actions';
import { textLabels } from '../../locales/DataTables';
import Dialog from '../../components/Dialog';
import { API_URL } from '../../config';

import ObjetoObraEdit from './Edit';
import ObjetoObraDuplicar from './Duplicar';
import ObjetoObraForm from './Form';
import Actividades from '../Actividades';

import { CircularProgress, Grid, Button, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import MensajeFlotante from '../../components/MensajeFlotante';

const styles = (theme) => ({
  centered: {
    marginTop: theme.spacing(20),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
});

class ObjetosObra extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rows: [],
      columns: [
        { name: 'nombre', label: 'Nombre' },
        { name: 'obra.nombre', label: 'Obra' },
      ],
      selectedRows: [],
      open: false,
      objetoObra: {
        id: null,
        nombre: '',
        obraId: '',
      },
      loading: false,
      sinAcceso: false,
    };
  }

  render() {
    const { classes, obra, permissions } = this.props;
    const { loading, rows, columns, open, objetoObra, sinAcceso } = this.state;
    return obra == null ? (
      <Redirect to={'/obras'} />
    ) : loading ? (
      <div className={classes.centered}>
        <CircularProgress />
      </div>
    ) : (
      <Fragment>
        <Grid
          container
          direction='row'
          justify='flex-start'
          alignItems='stretch'
          spacing={2}
        >
          <Grid item sm={12}>
            <Typography variant='h5'>Objetos de la Obra</Typography>
          </Grid>
          {permissions.crearObjetoObra && (
            <Grid item>
              <ObjetoObraForm
                getObjetosObra={this.getObjetosObra}
                obraId={obra?.id}
                disabled={obra == null}
              />
            </Grid>
          )}
          {permissions.editarObjetoObra && (
            <Grid item>
              {!objetoObra.id ? (
                <Button variant='contained' color='primary' disabled m={2}>
                  Editar
                </Button>
              ) : (
                <ObjetoObraEdit
                  getObjetosObra={this.getObjetosObra}
                  objetoObra={objetoObra}
                />
              )}
            </Grid>
          )}
          {permissions.editarObjetoObra && (
            <Grid item>
              {!objetoObra.id ? (
                <Button variant='contained' color='primary' disabled m={2}>
                  Duplicar
                </Button>
              ) : (
                <ObjetoObraDuplicar
                  getObjetosObra={this.getObjetosObra}
                  objetoObra={objetoObra}
                />
              )}
            </Grid>
          )}
          {permissions.listarObjetoObra && (
            <Grid item sm={12}>
              <MUIDataTable
                title={'Listado de Objetos de la Obra'}
                data={rows}
                columns={columns}
                options={this.options}
              />
            </Grid>
          )}
          <Dialog
            fullWidth
            open={open}
            dialogTitle={'Eliminar Objetos de Obra seleccionados'}
            dialogText={
              'ATENCIÓN! Esta acción es irreversible. ¿Desea continuar?'
            }
            handleClose={this.handleClose}
            handleConfirm={this.handleDelete}
          />
          {sinAcceso && (
            <MensajeFlotante
              mensaje='No tiene permisos para realizar esta acción'
              tipo='warning'
            />
          )}
        </Grid>
        {permissions.listarActividad && objetoObra.id !== null && (
          <Actividades objeto={objetoObra} />
        )}
      </Fragment>
    );
  }

  componentDidMount() {
    this.getObjetosObra();
  }

  getObjetosObra = () => {
    const { obra, token, userId, permissions } = this.props;
    let url = !permissions.esObrero
      ? `${API_URL}/objetos_obra/obra/${obra.id}`
      : `${API_URL}/objetos_obra/obra/${obra.id}/usuario/${userId}`;
    obra &&
      Axios({
        method: 'get',
        url: url,
        headers: {
          token: token,
        },
      })
        .then((res) =>
          this.setState({
            loading: false,
            rows: res.data,
            objetoObra: { id: null },
          })
        )
        .catch((err) => console.log(err.message));
  };

  handleDelete = () => {
    this.setState({ loading: true });
    this.state.selected.map((row) => {
      return this.eliminarObjetoObra(this.state.rows[row.dataIndex].id);
    });
  };

  eliminarObjetoObra = (id) => {
    this.handleClose();
    const config = {
      headers: {
        token: this.props.token,
      },
    };
    Axios.delete(API_URL + `/objetos_obra/${id}`, config)
      .then((res) => {
        this.getObjetosObra();
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  handleClose = () => {
    this.setState({ open: false });
    this.getObjetosObra();
  };

  setObjetoObra = (index) => {
    const { rows } = this.state;
    this.setState({ objetoObra: rows[index] });
    this.props.dispatch(objetosOne(rows[index]));
  };

  sinAcceso() {
    this.setState({ sinAcceso: true });
    this.getObjetosObra();
  }

  options = {
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'rowSelectionChange':
          this.setState({
            selected: tableState.selectedRows.data,
          });
          if (tableState.selectedRows.data.length === 1) {
            this.setObjetoObra(tableState.selectedRows.data[0].dataIndex);
          } else this.setState({ objetoObra: { id: null } });
          break;
        case 'rowDelete':
          this.props.permissions.eliminarObjetoObra
            ? this.setState({ open: true })
            : this.sinAcceso();
          break;
        default:
          break;
      }
    },
    filter: true,
    selectableRows: 'multiple',
    filterType: 'dropdown',
    responsive: 'vertical',
    rowsPerPage: 5,
    selectableRowsOnClick: true,
    rowsPerPageOptions: [5, 10, 25, 50, 100],
    textLabels: textLabels,
    enableNestedDataAccess: '.',
  };
}

ObjetosObra.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    userId: state.auth.userId,
    permissions: state.auth.permissions,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(ObjetosObra));
