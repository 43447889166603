import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  objetos: [],
  objeto: null,
};

const objetosList = (state, action) => {
  return updateObject(state, {
    objetos: action.objetos,
  });
};

const objetosOne = (state, action) => {
  return updateObject(state, {
    objeto: action.objeto,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.OBJETOS_LIST:
      return objetosList(state, action);
    case actionTypes.OBJETOS_ONE:
      return objetosOne(state, action);
    default:
      return state;
  }
};

export default reducer;
