import Axios from 'axios';
import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import { API_URL } from '../../../config';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import GantChar from '../../../components/GantChart';
import moment from 'moment';
import randomColor from 'randomcolor';
class Cronograma extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groups: [],
      items: [],
      totales: [],
    };
  }

  render() {
    const { groups, items, totales, selected } = this.state;
    return (
      <Grid
        container
        direction='row'
        justify='flex-start'
        alignItems='stretch'
        spacing={2}
      >
        <Grid item xs={12}>
          <GantChar
            groups={groups}
            items={items}
            handleTooltip={(id) => this.handleTooltip(id)}
          />
        </Grid>

        <TableContainer component={Paper} style={{ marginTop: 20 }}>
          <Table size='small' aria-label='a dense table'>
            <TableHead>
              <TableRow>
                <TableCell>Obra</TableCell>
                <TableCell align='right'>Progreso</TableCell>
                <TableCell align='right'>Objetos Completados</TableCell>
                <TableCell align='right'>Objetos Pendientes</TableCell>
                <TableCell align='right'>Costo Plan</TableCell>
                <TableCell align='right'>Costo Real</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {totales.map((row) => (
                <TableRow key={row.id}>
                  <TableCell component='th' scope='row'>
                    {row.nombre}
                  </TableCell>
                  <TableCell align='right'>
                    {row.progreso?.toFixed(2)}%
                  </TableCell>
                  <TableCell align='right'>{row.completados}</TableCell>
                  <TableCell align='right'>{row.pendientes}</TableCell>
                  <TableCell align='right'>
                    ${row.costoPlan?.toFixed(2)}
                  </TableCell>
                  <TableCell align='right'>
                    ${row.costoReal?.toFixed(2)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {selected && (
          <div
            style={{
              position: 'fixed',
              left: '40%',
              bottom: 100,
              background: 'rgba(0, 0, 0, 0.5)',
              color: 'white',
              padding: 10,
              fontSize: 20,
              borderRadius: 5,
              zIndex: 85,
            }}
          >
            <b>Objeto:</b> {selected.nombre}
            <br />
            <b>Progreso:</b> {selected.progreso}
            <br />
            <b>Actividades Completadas:</b> {selected.actCompletadas}
            <br />
            <b>Actividades Pendientes:</b> {selected.actPendientes}
            <br />
            <b>Costo Plan:</b> ${selected.costoPlan}
            <br />
            <b>Costo Real:</b> ${selected.costoReal}
            <br />
          </div>
        )}
      </Grid>
    );
  }

  componentDidMount() {
    this.getObras();
  }

  getObras = () => {
    const { token } = this.props;
    let totales = [];
    Axios({
      method: 'get',
      url: `${API_URL}/obras/ejecucion`,
      headers: {
        token: token,
      },
    })
      .then((res) => {
        let randomSeed = Math.floor(Math.random() * 1000);
        let groups = [];
        let items = [];
        res.data.map((obra) => {
          groups.push({
            id: obra.id,
            title: obra.nombre,
            rightTitle: obra.nombre,
            bgColor: randomColor({
              luminosity: 'light',
              seed: randomSeed + obra.id,
            }),
          });
          return obra.objetoObras.map((objeto) => {
            return objeto.actividads.map((actividad) => {
              const prog = (actividad.real * 100) / actividad.plan;
              return items.push({
                id: objeto.id,
                group: obra.id,
                title: prog.toFixed(2) + '% ' + objeto.nombre,
                start: moment(actividad.inicio).add(-1, 'month'),
                end: moment(actividad.fin).add(-1, 'month'),
                bgColor:
                  prog < 50
                    ? '#E74C3C'
                    : prog < 99
                    ? '#FFA533'
                    : prog === 100
                    ? '#7DFF33'
                    : '#FF33F9',
              });
            });
          });
        });
        res.data.map((obra) => {
          var progreso = 0;
          var completados = 0;
          var pendientes = 0;
          var costoPlan = 0;
          var costoReal = 0;
          obra.objetoObras.map((objeto) => {
            let objCompletado = true;
            let sumProgreso = 0;
            let sumPlan = 0;
            let sumReal = 0;

            objeto.actividads.map((actividad) => {
              if ((actividad.real * 100) / actividad.plan < 100)
                objCompletado = false;
              sumProgreso += (actividad.real * 100) / actividad.plan;
              sumPlan += actividad.plan;
              sumReal += actividad.real;
              return null;
            });

            objCompletado ? ++completados : ++pendientes;
            progreso += sumProgreso / (objeto.actividads.length || 1);
            costoPlan += sumPlan / (objeto.actividads.length || 1);
            costoReal += sumReal / (objeto.actividads.length || 1);

            return null;
          });

          return totales.push({
            id: obra.id,
            nombre: obra.nombre,
            progreso,
            completados,
            pendientes,
            costoPlan,
            costoReal,
          });
        });
        this.setState({ groups: groups, items: items, totales: totales });
      })
      .catch((err) => console.log(err.res?.data?.message || err.message));
  };

  handleTooltip = (id) => {
    const { token } = this.props;
    id === -1
      ? this.setState({ selected: null })
      : Axios({
          method: 'get',
          url: `${API_URL}/objetos_obra/${id}`,
          headers: { token: token },
        })
          .then((res) => {
            var actTotal = 0;
            var actCompletadas = 0;
            var actPendientes = 0;
            var costoPlan = 0;
            var costoReal = 0;
            var progreso = 0;
            let acts = res.data?.actividads.map((act) => {
              return {
                actTotal: ++actTotal,
                actCompletadas:
                  (act.real * 100) / act.plan >= 100
                    ? actCompletadas++
                    : actCompletadas,
                actPendientes:
                  (act.real * 100) / act.plan < 100
                    ? ++actPendientes
                    : actPendientes,
                costoPlan: (costoPlan += act.costo),
                costoReal: (costoReal += act.real * act.tipoActividad?.tarifa),
                progreso: (progreso += (act.real * 100) / act.plan),
              };
            });
            var dataItem = acts[acts.length - 1];
            let selected = {
              nombre: res.data.nombre,
              progreso: `${dataItem.progreso?.toFixed(2)}%`,
              totalActvidades: dataItem.actTotal,
              actCompletadas: dataItem.actCompletadas,
              actPendientes: dataItem.actPendientes,
              costoPlan: dataItem.costoPlan?.toFixed(2),
              costoReal: dataItem.costoReal?.toFixed(2),
            };
            this.setState({ selected: selected });
          })
          .catch((err) => console.log(err.res?.data?.message || err.message));
  };
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
  };
};

export default connect(mapStateToProps)(Cronograma);
