import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Login from './containers/Auth/Login';
import Main from './Layouts/Main';
import * as actions from './store/actions';

class App extends Component {
  componentDidMount() {
    this.props.onTryAutoSignup();
  }
  render() {
    return this.props.authed ? <Main /> : <Login />;
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState()),
  };
};

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    authed: state.auth.token != null,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
