import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function MensajeFlotante(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [vertical] = React.useState('top');
  const [horizontal] = React.useState('center');

  const handleClose = (event, reason) => {
    setOpen(false);
  };

  return (
    props.mensaje !== '' && (
      <div className={classes.root}>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical, horizontal }}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={props.tipo}>
            {props.mensaje}
          </Alert>
        </Snackbar>
      </div>
    )
  );
}
