import React, { Component, Fragment } from 'react';
import Axios from 'axios';
import MUIDataTable from 'mui-datatables';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { textLabels } from '../../locales/DataTables';
import Dialog from '../../components/Dialog';
import ReporteEdit from './Edit';
import ReporteForm from './Form';
import {
  CircularProgress,
  Grid,
  Button,
  Typography,
  Link,
  Avatar,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { API_URL } from '../../config';
import MensajeFlotante from '../../components/MensajeFlotante';
import MaterialesReporte from '../MaterialesReporte';
import UsuariosReporte from '../UsuariosReporte';
import moment from 'moment';

const styles = (theme) => ({
  centered: {
    marginTop: theme.spacing(20),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
});

class Reportes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 0,
      itemsPerPage: 10,
      rows: [],
      columns: [
        {
          name: 'fecha',
          label: 'Fecha',
          options: {
            customBodyRender: (value) => moment(value).format('YYYY-MM-DD'),
          },
        },
        { name: 'actividad.tipoActividad.nombre', label: 'Actividad' },
        {
          name: 'actividad.plan',
          label: 'Plan',
          options: {
            customBodyRender: (value) => value?.toFixed(2),
          },
        },
        {
          name: 'cantidad',
          label: 'Real',
          options: {
            customBodyRender: (value) => value?.toFixed(2),
          },
        },
        {
          name: 'actividad.costo',
          label: 'Costo Plan',
          options: {
            customBodyRender: (value) => `$${value?.toFixed(2)}`,
          },
        },
        {
          name: 'valor',
          label: 'Costo Real',
          options: {
            customBodyRender: (value) => `$${value?.toFixed(2)}`,
          },
        },
        {
          name: 'foto',
          options: {
            filter: false,
            sort: false,
            empty: true,
            customBodyRender: (value) => {
              return value ? (
                <Typography>
                  <Link href={value} target='_blank' rel='noopener'>
                    <Avatar alt={value} src={value} />
                  </Link>
                </Typography>
              ) : (
                ''
              );
            },
          },
        },
      ],
      selectedRows: [],
      open: false,
      reporte: { id: null },
      loading: false,
      sinAcceso: false,
    };
  }

  render() {
    const { classes, permissions, actividad } = this.props;
    let { loading, reporte, rows, columns, open, sinAcceso } = this.state;

    return loading ? (
      <Fragment className={classes.centered}>
        <CircularProgress />
      </Fragment>
    ) : (
      <Fragment>
        <Grid
          container
          direction='row'
          justify='flex-start'
          alignItems='stretch'
          spacing={2}
        >
          <Grid item sm={12}>
            <Typography variant='h5'>Reportes de la Actividad</Typography>
          </Grid>
          {permissions.crearReporte && (
            <Grid item>
              <ReporteForm
                getReportes={this.getReportes}
                actividad={actividad}
              />
            </Grid>
          )}
          {permissions.editarReporte && (
            <Grid item>
              {reporte.id === null ? (
                <Button disabled>Editar</Button>
              ) : (
                <ReporteEdit
                  getReportes={this.getReportes}
                  reporte={reporte}
                  actividad={actividad}
                />
              )}
            </Grid>
          )}
          {permissions.listarReporte && (
            <Grid item sm={12}>
              <MUIDataTable
                title={'Listado de Reportes'}
                data={rows}
                columns={columns}
                options={this.options}
              />
            </Grid>
          )}
        </Grid>
        <Dialog
          fullWidth
          open={open}
          dialogTitle={'Eliminar Reportes seleccionadas'}
          dialogText={
            'ATENCIÓN! Esta acción es irreversible. ¿Desea continuar?'
          }
          handleClose={this.handleClose}
          handleConfirm={this.handleDelete}
        />
        {sinAcceso && (
          <MensajeFlotante
            mensaje='No tiene permisos para realizar esta acción'
            tipo='warning'
          />
        )}
        {reporte?.id && (
          <Grid
            container
            direction='row'
            justify='flex-start'
            alignItems='stretch'
            spacing={2}
          >
            <Grid item md={5} lg={6}>
              {permissions.listarMaterialesReporte && (
                <MaterialesReporte reporte={reporte} actividad={actividad} />
              )}
            </Grid>
            <Grid item md={7} lg={6}>
              {permissions.listarMaterialesReporte && (
                <UsuariosReporte reporte={reporte} />
              )}
            </Grid>
          </Grid>
        )}
      </Fragment>
    );
  }

  componentDidMount() {
    this.getReportes();
  }

  getReportes = () => {
    this.setState({ loading: true });

    const { actividad, token, permissions, userId } = this.props;
    let url = !permissions.esObrero
      ? `${API_URL}/reportes/actividad/${actividad?.id}`
      : `${API_URL}/reportes/actividad/${actividad?.id}/usuario/${userId}`;
    Axios({
      method: 'get',
      url: url,
      headers: {
        token: token,
      },
    })
      .then((res) => {
        this.setState({
          loading: false,
          rows: res.data,
          reporte: { id: null },
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
      });
    this.setState({
      reporte: {
        id: null,
      },
    });
  };

  handleDelete = () => {
    this.setState({ loading: true });
    this.state.selected.map((row) => {
      return this.eliminarReporte(this.state.rows[row.dataIndex].id);
    });
  };

  eliminarReporte = (id) => {
    this.handleClose();
    const config = {
      headers: {
        token: this.props.token,
      },
    };
    Axios.delete(API_URL + `/reportes/${id}`, config)
      .then((res) => {
        this.getReportes();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleClose = () => {
    this.setState({ open: false });
    this.getReportes();
  };

  setReporte = (index) => {
    const { rows } = this.state;
    this.setState({ reporte: rows[index] });
  };

  sinAcceso() {
    this.setState({ sinAcceso: true });
    this.getReportes();
  }

  options = {
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'rowSelectionChange':
          this.setState({
            selected: tableState.selectedRows.data,
          });
          if (tableState.selectedRows.data.length === 1) {
            this.setReporte(tableState.selectedRows.data[0].dataIndex);
          } else
            this.setState({
              reporte: { id: null },
            });
          break;
        case 'rowDelete':
          this.props.permissions.eliminarReporte
            ? this.setState({ open: true })
            : this.sinAcceso();
          break;
        default:
          break;
      }
    },
    setRowProps: (row) => {
      if (parseFloat(row[3]) > parseFloat(row[2])) {
        return {
          style: { background: 'orange' },
        };
      }
    },
    filter: true,
    selectableRows: 'multiple',
    filterType: 'dropdown',
    responsive: 'vertical',
    rowsPerPage: 5,
    selectableRowsOnClick: true,
    rowsPerPageOptions: [5, 10, 25, 50, 100],
    textLabels: textLabels,
    enableNestedDataAccess: '.',
  };
}

Reportes.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    userId: state.auth.userId,
    permissions: state.auth.permissions,
    actividad: state.actividades.actividad,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(Reportes));
