import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
  CircularProgress,
  Grid,
  TableCell,
  TableFooter,
  TableRow,
} from '@material-ui/core';
import { connect } from 'react-redux';
import Axios from 'axios';
import { API_URL } from '../../config';
import MUIDataTable from 'mui-datatables';
import { headerTitle } from '../../store/actions';
import { textLabels } from '../../locales/DataTables';
import DateRange from '../../components/DateRange';
import moment from 'moment';

class EstadCostos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      itemsPerPage: 10,
      rows: [],
      columns: [
        {
          name: 'objetoObra.obra.nombre',
          label: 'Obra',
        },
        {
          name: 'objetoObra.nombre',
          label: 'Objeto de Obra',
        },
        {
          name: 'tipoActividad.nombre',
          label: 'Actividad',
        },
        {
          name: 'costo',
          label: 'Costo Planificado',
          options: {
            customBodyRender: (value, tableMeta, updateValue) =>
              '$' + value?.toFixed(2),
            filter: false,
          },
        },
        {
          name: 'real',
          label: 'Costo Real',
          options: {
            customBodyRender: (value, tableMeta) =>
              '$' + (tableMeta.rowData[5] * value)?.toFixed(2),
            filter: false,
          },
        },
        {
          name: 'tipoActividad.tarifa',
          label: 'Tarifa',
          options: {
            display: 'excluded',
          },
        },
      ],
      selectedRows: [],
      open: false,
      reporte: { id: null },
      loading: false,
      sinAcceso: false,
    };
  }

  render() {
    const { rows, columns, loading } = this.state;
    const { classes } = this.props;
    return loading ? (
      <div className={classes.centered}>
        <CircularProgress />
      </div>
    ) : (
      <Grid container spacing={2}>
        <Grid item sm={3}>
          <DateRange setRange={(s, e) => this.getActividades(s, e)} />
        </Grid>
        <Grid item sm={9}>
          {loading ? (
            <div className={classes.centered}>
              <CircularProgress />
            </div>
          ) : (
            <MUIDataTable
              data={rows}
              columns={columns}
              options={this.options}
            />
          )}
        </Grid>
      </Grid>
    );
  }

  componentDidMount() {
    this.getActividades();
    this.props.dispatch(headerTitle('Costos de Obras'));
  }

  getActividades = (
    s = moment().startOf('month'),
    e = moment().endOf('day')
  ) => {
    this.setState({ loading: true });
    const { token } = this.props;
    Axios({
      method: 'post',
      url: `${API_URL}/actividades/fechas`,
      data: { start: s, end: e },
      headers: { token: token },
    })
      .then((res) => {
        console.log(res.data);
        this.setState({
          rows: res.data || [],
          loading: false,
        });
      })
      .catch((err) => console.log(err.message));
  };

  sumTotalPlan = (columns) => {
    return columns
      .map((a) => parseFloat(a.data[3]?.replace('$', '')) || 0)
      .reduce((total, valor) => (total += valor), 0);
  };

  sumTotalReal = (columns) => {
    return columns
      .map((a) => parseFloat(a.data[4]?.replace('$', '')) || 0)
      .reduce((total, valor) => (total += valor), 0);
  };

  options = {
    filter: true,
    selectableRows: 'none',
    filterType: 'multiselect',
    responsive: 'vertical',
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 25, 50],
    textLabels: textLabels,
    enableNestedDataAccess: '.',
    customTableBodyFooterRender: (opts) => (
      <TableFooter>
        <TableRow>
          <TableCell colSpan={4} align='right'>
            Costo Total Planificado
          </TableCell>
          <TableCell colSpan={1} variant='head'>
            ${this.sumTotalPlan(opts?.data)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell colSpan={4} align='right'>
            Costo Total Real
          </TableCell>
          <TableCell colSpan={1} variant='head'>
            ${this.sumTotalReal(opts?.data)}
          </TableCell>
        </TableRow>
      </TableFooter>
    ),
  };
}

const styles = (theme) => ({
  centered: {
    marginTop: theme.spacing(20),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
});

EstadCostos.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    userId: state.auth.userId,
    permissions: state.auth.permissions,
    actividad: state.actividades.actividad,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(EstadCostos));
