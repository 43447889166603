import React, { Component } from 'react';
import Axios from 'axios';
import MUIDataTable from 'mui-datatables';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { headerTitle } from '../../store/actions';

import { textLabels } from '../../locales/DataTables';
import Dialog from '../../components/Dialog';
import MaterialEdit from './Edit';
import MaterialForm from './Form';
import { CircularProgress, Grid, Button } from '@material-ui/core';
import { connect } from 'react-redux';

import { API_URL } from '../../config';
import MensajeFlotante from '../../components/MensajeFlotante';

const styles = (theme) => ({
  centered: {
    marginTop: theme.spacing(20),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
});

class Materiales extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rows: [],
      columns: [
        { name: 'nombre', label: 'Material' },
        { name: 'unidadMedida.nombre', label: 'U/M' },
      ],
      selectedRows: [],
      open: false,
      material: {
        nombre: '',
      },
      loading: false,
      sinAcceso: false,
    };
  }

  render() {
    const { classes, permissions } = this.props;
    const { material, loading, rows, columns, open, sinAcceso } = this.state;

    return loading ? (
      <div className={classes.centered}>
        <CircularProgress />
      </div>
    ) : (
      <Grid
        container
        direction='row'
        justify='flex-start'
        alignItems='stretch'
        spacing={2}
      >
        {permissions.crearMaterial && (
          <Grid item>
            <MaterialForm
              getMateriales={this.getMateriales}
              material={material}
            />
          </Grid>
        )}
        {permissions.editarMaterial && (
          <Grid item>
            {material.id ? (
              <MaterialEdit
                getMateriales={this.getMateriales}
                material={material}
              />
            ) : (
              <Button variant='contained' color='primary' disabled m={2}>
                Editar
              </Button>
            )}
          </Grid>
        )}
        {permissions.listarMaterial && (
          <Grid item sm={12}>
            <MUIDataTable
              title={'Listado de Materiales'}
              data={rows}
              columns={columns}
              options={this.options}
            />
          </Grid>
        )}
        <Dialog
          fullWidth
          open={open}
          dialogTitle={'Eliminar Materiales seleccionadas'}
          dialogText={
            'ATENCIÓN! Esta acción es irreversible. ¿Desea continuar?'
          }
          handleClose={this.handleClose}
          handleConfirm={this.handleDelete}
        />
        {sinAcceso && (
          <MensajeFlotante
            mensaje='No tiene permisos para realizar esta acción'
            tipo='warning'
          />
        )}
      </Grid>
    );
  }

  componentDidMount() {
    this.getMateriales();
    this.props.dispatch(headerTitle('Materiales'));
  }

  getMateriales = () => {
    this.setState({ loading: true });
    Axios({
      method: 'get',
      url: `${API_URL}/materiales`,
      headers: {
        token: this.props.token,
      },
    })
      .then((res) =>
        this.setState({
          loading: false,
          rows: res.data,
          material: { id: null },
        })
      )
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err.message);
      });
  };

  handleDelete = () => {
    this.setState({ loading: true });
    this.state.selected.map((row) => {
      return this.eliminarMaterial(this.state.rows[row.dataIndex].id);
    });
  };

  eliminarMaterial = (id) => {
    this.handleClose();
    const config = {
      headers: {
        token: this.props.token,
      },
    };
    Axios.delete(API_URL + `/materiales/${id}`, config)
      .then((res) => {
        this.getMateriales();
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  handleClose = () => {
    this.setState({ open: false });
    this.getMateriales();
  };

  setMaterial = (index) => {
    const { rows } = this.state;
    this.setState({ material: rows[index] });
  };

  sinAcceso() {
    this.setState({ sinAcceso: true });
    this.getMateriales();
  }

  options = {
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'rowSelectionChange':
          this.setState({
            selected: tableState.selectedRows.data,
          });
          if (tableState.selectedRows.data.length === 1) {
            this.setMaterial(tableState.selectedRows.data[0].dataIndex);
          } else this.setState({ material: { id: null } });
          break;
        case 'rowDelete':
          this.props.permissions.eliminarMaterial
            ? this.setState({ open: true })
            : this.sinAcceso();
          break;
        default:
          break;
      }
    },
    filter: true,
    selectableRows: 'multiple',
    filterType: 'dropdown',
    responsive: 'vertical',
    rowsPerPage: 5,
    selectableRowsOnClick: true,
    rowsPerPageOptions: [5, 10, 25, 50, 100],
    textLabels: textLabels,
    enableNestedDataAccess: '.',
  };
}

Materiales.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    permissions: state.auth.permissions,
  };
};
export default connect(mapStateToProps)(withStyles(styles)(Materiales));
