import React, { Component } from 'react';
import Axios from 'axios';
import MUIDataTable from 'mui-datatables';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { textLabels } from '../../locales/DataTables';
import { CircularProgress, Typography, Link, Avatar } from '@material-ui/core';
import { connect } from 'react-redux';
import { API_URL } from '../../config';
import moment from 'moment';
import { headerTitle } from '../../store/actions';

const styles = (theme) => ({
  centered: {
    marginTop: theme.spacing(20),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
});

class Reportes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 0,
      itemsPerPage: 10,
      rows: [],
      columns: [
        {
          name: 'fecha',
          label: 'Fecha',
          options: {
            customBodyRender: (value) => moment(value).format('YYYY-MM-DD'),
            sortDirection: 'desc',
          },
        },
        { name: 'actividad.tipoActividad.nombre', label: 'Actividad' },
        { name: 'actividad.tipoActividad.unidadMedida.nombre', label: 'U/M' },
        {
          name: 'actividad.plan',
          label: 'Plan',
          options: {
            customBodyRender: (value, tableMeta) => value?.toFixed(2),
          },
        },
        {
          name: 'cantidad',
          label: 'Real',
          options: {
            customBodyRender: (value) => value?.toFixed(2),
          },
        },
        {
          name: 'actividad.costo',
          label: 'Costo Plan',
          options: {
            customBodyRender: (value) => `$${value?.toFixed(2)}`,
          },
        },
        {
          name: 'valor',
          label: 'Costo Real',
          options: {
            customBodyRender: (value) => `$${value?.toFixed(2)}`,
          },
        },
        {
          name: 'foto',
          options: {
            filter: false,
            sort: false,
            empty: true,
            customBodyRender: (value) => {
              return value ? (
                <Typography>
                  <Link href={value} target='_blank' rel='noopener'>
                    <Avatar alt={value} src={value} />
                  </Link>
                </Typography>
              ) : (
                ''
              );
            },
          },
        },
      ],
      selectedRows: [],
      open: false,
      reporte: { id: null },
      loading: false,
      sinAcceso: false,
    };
  }

  render() {
    const { classes } = this.props;
    let { loading, rows, columns } = this.state;

    return loading ? (
      <div className={classes.centered}>
        <CircularProgress />
      </div>
    ) : (
      <MUIDataTable
        title={'Últimos Reportes'}
        data={rows}
        columns={columns}
        options={this.options}
      />
    );
  }

  componentDidMount() {
    this.getReportes();
    this.props.dispatch(headerTitle('Reportes de Tareas'));
  }

  getReportes = () => {
    this.setState({ loading: true });

    const { token } = this.props;
    Axios({
      method: 'get',
      url: `${API_URL}/reportes`,
      headers: {
        token: token,
      },
    })
      .then((res) => {
        this.setState({
          loading: false,
          rows: res.data,
          reporte: { id: null },
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
      });
    this.setState({
      reporte: {
        id: null,
      },
    });
  };

  handleDelete = () => {
    this.setState({ loading: true });
    this.state.selected.map((row) => {
      return this.eliminarReporte(this.state.rows[row.dataIndex].id);
    });
  };

  eliminarReporte = (id) => {
    this.handleClose();
    const config = {
      headers: {
        token: this.props.token,
      },
    };
    Axios.delete(API_URL + `/reportes/${id}`, config)
      .then((res) => {
        this.getReportes();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleClose = () => {
    this.setState({ open: false });
    this.getReportes();
  };

  setReporte = (index) => {
    const { rows } = this.state;
    this.setState({ reporte: rows[index] });
  };

  sinAcceso() {
    this.setState({ sinAcceso: true });
    this.getReportes();
  }

  options = {
    setRowProps: (row) => {
      if (parseFloat(row[3]) > parseFloat(row[2])) {
        return {
          style: { background: 'orange' },
        };
      }
    },
    filter: true,
    selectableRows: 'none',
    filterType: 'dropdown',
    responsive: 'vertical',
    rowsPerPage: 5,
    selectableRowsOnClick: true,
    rowsPerPageOptions: [5, 10, 25, 50, 100],
    textLabels: textLabels,
    enableNestedDataAccess: '.',
  };
}

Reportes.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    permissions: state.auth.permissions,
    actividad: state.actividades.actividad,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(Reportes));
