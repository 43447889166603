import React, { Component } from 'react';
import moment from 'moment';

import Axios from 'axios';
import { API_URL } from '../../../config';
import randomColor from 'randomcolor';
import { connect } from 'react-redux';
import GantChar from '../../../components/GantChart';
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

class CronoActividades extends Component {
  constructor(props) {
    super(props);

    const visibleTimeStart = moment().startOf('month').valueOf();
    const visibleTimeEnd = moment().endOf('month').valueOf();

    this.state = {
      groups: [],
      items: [],
      totales: [],
      visibleTimeStart,
      visibleTimeEnd,
    };
  }

  render() {
    const { groups, items, totales, selected } = this.state;
    return (
      <Grid
        container
        direction='row'
        justify='flex-start'
        alignItems='stretch'
        spacing={2}
      >
        <Grid item xs={12}>
          <GantChar
            groups={groups}
            items={items}
            handleTooltip={(id) => this.handleTooltip(id)}
          />
        </Grid>
        <TableContainer component={Paper} style={{ marginTop: 10 }}>
          <Table size='small' aria-label='a dense table'>
            <TableHead>
              <TableRow>
                <TableCell>Objeto de Obra</TableCell>
                <TableCell align='right'>Progreso</TableCell>
                <TableCell align='right'>Actividades Completadas</TableCell>
                <TableCell align='right'>Actividades Pendientes</TableCell>
                <TableCell align='right'>Costo Plan</TableCell>
                <TableCell align='right'>Costo Real</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {totales.map((row) => (
                <TableRow key={row.id}>
                  <TableCell component='th' scope='row'>
                    {row.nombre}
                  </TableCell>
                  <TableCell align='right'>
                    {row.progreso?.toFixed(2)}%
                  </TableCell>
                  <TableCell align='right'>{row.completadas}</TableCell>
                  <TableCell align='right'>{row.pendientes}</TableCell>
                  <TableCell align='right'>
                    ${row.costoPlan?.toFixed(2)}
                  </TableCell>
                  <TableCell align='right'>
                    ${row.costoReal?.toFixed(2)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {selected && (
          <div
            style={{
              position: 'fixed',
              left: '40%',
              bottom: 100,
              background: 'rgba(0, 0, 0, 0.5)',
              color: 'white',
              padding: 10,
              fontSize: 20,
              borderRadius: 5,
              zIndex: 85,
            }}
          >
            <b>Actividad:</b> {selected.nombre}
            <br />
            <b>Progreso:</b> {selected.progreso}%
            <br />
            <b>Costo Plan:</b> ${selected.costoPlan}
            <br />
            <b>Costo Real:</b> ${selected.costoReal}
            <br />
          </div>
        )}
      </Grid>
    );
  }

  componentDidMount() {
    this.getObras();
  }

  getObras = () => {
    const { token } = this.props;
    Axios({
      method: 'get',
      url: `${API_URL}/objetos_obra/ejecucion`,
      headers: {
        token: token,
      },
    })
      .then((res) => {
        let randomSeed = Math.floor(Math.random() * 1000);
        let groups = [];
        let items = [];
        res.data.map((objeto) => {
          groups.push({
            id: objeto.id,
            title: objeto.nombre,
            rightTitle: objeto.nombre,
            bgColor: randomColor({
              luminosity: 'light',
              seed: randomSeed + objeto.id,
            }),
          });
          return objeto.actividads.map((actividad) => {
            const prog = (actividad.real * 100) / actividad.plan;
            return items.push({
              id: actividad.id,
              group: objeto.id,
              title: prog.toFixed(2) + '% ' + actividad.tipoActividad.nombre,
              start: moment(actividad.inicio).add(-1, 'month'),
              end: moment(actividad.fin).add(-1, 'month'),
              bgColor:
                prog < 50
                  ? '#E74C3C'
                  : prog < 99
                  ? '#FFA533'
                  : prog === 100
                  ? '#7DFF33'
                  : '#FF33F9',
            });
          });
        });
        let totales = res.data.map((objeto) => {
          let progreso = 0;
          let completadas = 0;
          let pendientes = 0;
          let costoPlan = 0;
          let costoReal = 0;
          let sumProgreso = 0;
          let sumPlan = 0;
          let sumReal = 0;
          objeto.actividads.map((actividad) => {
            (actividad.real * 100) / actividad.plan >= 100
              ? ++completadas
              : ++pendientes;
            sumProgreso += (actividad.real * 100) / actividad.plan;
            sumPlan += actividad.plan;
            sumReal += actividad.real;
            return null;
          });
          progreso = sumProgreso / (objeto.actividads.length || 1);
          costoPlan = sumPlan / (objeto.actividads.length || 1);
          costoReal = sumReal / (objeto.actividads.length || 1);
          return {
            id: objeto.id,
            nombre: objeto.nombre,
            progreso,
            completadas,
            pendientes,
            costoPlan,
            costoReal,
          };
        });
        this.setState({ groups: groups, items: items, totales: totales });
      })
      .catch((err) => console.log(err.res?.data?.message || err.message));
  };

  handleTooltip = (id) => {
    const { token } = this.props;
    id === -1
      ? this.setState({ selected: null })
      : Axios({
          method: 'get',
          url: `${API_URL}/actividades/${id}`,
          headers: { token: token },
        })
          .then((res) => {
            let act = res.data;
            this.setState({
              selected: {
                nombre: act.tipoActividad.nombre,
                progreso: (act.real * 100) / act.plan,
                costoPlan: act.plan?.toFixed(2),
                costoReal: act.real?.toFixed(2),
              },
            });
          })
          .catch((err) => console.log(err.res?.data?.message || err.message));
  };
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
  };
};

export default connect(mapStateToProps)(CronoActividades);
