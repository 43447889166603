import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  obras: [],
  obra: null,
};

const obrasList = (state, action) => {
  return updateObject(state, {
    obras: action.obras,
  });
};

const obrasOne = (state, action) => {
  return updateObject(state, {
    obra: action.obra,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.OBRAS_LIST:
      return obrasList(state, action);
    case actionTypes.OBRAS_ONE:
      return obrasOne(state, action);
    default:
      return state;
  }
};

export default reducer;
