import axios from 'axios';
import * as actionTypes from './actionTypes';
import { API_URL } from '../../config';
import { obrasDelLogueado } from './obras';

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const authSuccess = (token, userId, rolId) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    token: token,
    userId: userId,
    rolId: rolId,
  };
};

export const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error,
  };
};

export const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('expirationDate');
  localStorage.removeItem('userId');
  localStorage.removeItem('rolId');
  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

export const checkAuthTimeout = (expirationTime) => {
  return (dispatch) => {
    setTimeout(() => {
      dispatch(logout());
    }, expirationTime * 1000);
  };
};

export const auth = (user, password) => {
  return async (dispatch) => {
    dispatch(authStart());
    const authData = {
      usuario: user,
      contrasena: password,
    };
    await axios
      .post(API_URL + '/login', authData)
      .then((response) => {
        const expirationDate = new Date(new Date().getTime() + 3600 * 1000);
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('expirationDate', expirationDate);
        localStorage.setItem('userId', response.data.id);
        localStorage.setItem('rolId', response.data.rolId);
        dispatch(
          authSuccess(
            response.data.token,
            response.data.id,
            response.data.rolId
          )
        );
        dispatch(authPermissions(response.data.rolId));
        dispatch(checkAuthTimeout(3600));
      })
      .catch((err) => {
        dispatch(authFail(err.response?.data?.message || err.message));
      });
  };
};

export const authCheckState = () => {
  return (dispatch) => {
    const token = localStorage.getItem('token');
    if (!token) {
      dispatch(logout());
    } else {
      const expirationDate = new Date(localStorage.getItem('expirationDate'));
      if (expirationDate <= new Date()) {
        dispatch(logout());
      } else {
        const userId = localStorage.getItem('userId');
        const rolId = parseInt(localStorage.getItem('rolId'));
        dispatch(obrasDelLogueado());
        dispatch(authSuccess(token, userId, rolId));
        dispatch(authPermissions(rolId));
        dispatch(
          checkAuthTimeout(
            (expirationDate.getTime() - new Date().getTime()) / 1000
          )
        );
      }
    }
  };
};

export const authPermissions = () => {
  const rolId = parseInt(localStorage.getItem('rolId'));
  return !rolId
    ? { type: actionTypes.AUTH_PERMISSIONS, permissions: {} }
    : {
        type: actionTypes.AUTH_PERMISSIONS,
        permissions: {
          esAdmin: rolId === 1,
          esJefe: rolId === 2,
          esObrero: rolId === 3,
          //Obras
          menuObra: rolId === 1 || rolId === 2 || rolId === 3,
          listarObra: rolId === 1 || rolId === 2 || rolId === 3,
          crearObra: rolId === 1,
          editarObra: rolId === 1,
          eliminarObra: rolId === 1,
          gestionarObra: rolId === 1 || rolId === 2,
          //Objetos de Obra
          listarObjetoObra: rolId === 1 || rolId === 2 || rolId === 3,
          crearObjetoObra: rolId === 1,
          editarObjetoObra: rolId === 1,
          eliminarObjetoObra: rolId === 1,
          //Actividad
          listarActividad: rolId === 1 || rolId === 2 || rolId === 3,
          crearActividad: rolId === 1,
          editarActividad: rolId === 1,
          eliminarActividad: rolId === 1,
          //Reporte
          menuReporte: rolId === 1 || rolId === 2 || rolId === 3,
          listarReporte: rolId === 1 || rolId === 2 || rolId === 3,
          crearReporte: rolId === 1 || rolId === 2 || rolId === 3,
          editarReporte: rolId === 1 || rolId === 2,
          eliminarReporte: rolId === 1 || rolId === 2,
          //Estadistica
          menuEstadisticaCostos: rolId === 1,
          menuEstadisticaMateriales: rolId === 1,
          menuEstadisticaPagos: rolId === 1 || rolId === 2 || rolId === 3,
          //TipoActividad
          menuTipoActividad: rolId === 1,
          listarTipoActividad: rolId === 1,
          crearTipoActividad: rolId === 1,
          editarTipoActividad: rolId === 1,
          eliminarTipoActividad: rolId === 1,
          //Especialidad
          menuEspecialidad: rolId === 1,
          listarEspecialidad: rolId === 1,
          crearEspecialidad: rolId === 1,
          editarEspecialidad: rolId === 1,
          eliminarEspecialidad: rolId === 1,
          //Material
          menuMaterial: rolId === 1,
          listarMaterial: rolId === 1,
          crearMaterial: rolId === 1,
          editarMaterial: rolId === 1,
          eliminarMaterial: rolId === 1,
          asignarMaterial: rolId === 1 || rolId === 2 || rolId === 3,
          //MaterialesActividad
          listarMaterialesActividad: rolId === 1 || rolId === 2,
          crearMaterialesActividad: rolId === 1,
          editarMaterialesActividad: rolId === 1,
          eliminarMaterialesActividad: rolId === 1,
          //UsuariosActividad
          listarUsuariosActividad: rolId === 1 || rolId === 2 || rolId === 3,
          crearUsuariosActividad: rolId === 1 || rolId === 2,
          editarUsuariosActividad: rolId === 1 || rolId === 2,
          eliminarUsuariosActividad: rolId === 1 || rolId === 2,
          //MaterialesReporte
          listarMaterialesReporte: rolId === 1 || rolId === 2 || rolId === 3,
          crearMaterialesReporte: rolId === 1 || rolId === 2,
          editarMaterialesReporte: rolId === 1 || rolId === 2,
          eliminarMaterialesReporte: rolId === 1 || rolId === 2,
          //UsuariosReporte
          listarUsuariosReporte: rolId === 1 || rolId === 2 || rolId === 3,
          crearUsuariosReporte: rolId === 2,
          editarUsuariosReporte: rolId === 2,
          eliminarUsuariosReporte: rolId === 2,
          //UnidadMedida
          menuUnidadMedida: rolId === 1,
          listarUnidadMedida: rolId === 1,
          crearUnidadMedida: rolId === 1,
          editarUnidadMedida: rolId === 1,
          eliminarUnidadMedida: rolId === 1,
          //Usuario
          menuUsuario: rolId === 1,
          listarUsuario: rolId === 1,
          crearUsuario: rolId === 1,
          editarUsuario: rolId === 1,
          eliminarUsuario: rolId === 1,
        },
      };
};
