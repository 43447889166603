import React, { Component } from 'react';

import {
  Button,
  DialogActions,
  TextField,
  DialogTitle,
  DialogContent,
  Dialog,
  FormControl,
  Typography,
  Grid,
  List,
  ListItem,
  IconButton,
  ListItemSecondaryAction,
  CircularProgress,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { API_URL } from '../../config';
import SimpleReactValidator from 'simple-react-validator';
import '../../locales/SimpleReactValidator';
import MensajeFlotante from '../../components/MensajeFlotante';
import SelectSearch from './SelectSearch';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import ClearIcon from '@material-ui/icons/Clear';
import Axios from 'axios';
import moment from 'moment';

class ObreroForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nombre: '',
      unidadMedidaId: '',
      actividadId: '',
      cantidad: '',
      open: false,
      actividades: [],
      unidades_medida: [],
      usuariosActividad: props.usuariosActividad,
      usuarios: [],
      usuario: null,
      tipos: [],
      tipo: null,
      editar: false,
      dialog: false,
      loading: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validator = new SimpleReactValidator({ locale: 'es' });
  }

  render() {
    const { permissions } = this.props;
    const {
      open,
      usuario,
      usuarios,
      editar,
      usuariosActividad,
      loading,
    } = this.state;
    return !permissions.crearUsuariosActividad ? (
      <MensajeFlotante mensaje='Sin acceso' tipo='warning' />
    ) : (
      <div>
        <Button
          variant='outlined'
          color='primary'
          onClick={this.handleClickOpen}
          m={2}
        >
          Agregar Participantes
        </Button>
        <Dialog
          fullWidth
          open={open}
          onClose={this.handleClose}
          aria-labelledby='form-dialog-title'
        >
          <DialogTitle id='form-dialog-title'>Asignar Obrero</DialogTitle>
          <DialogContent>
            {/* formulario usuario */}
            <Grid item xs={12}>
              <Typography gutterBottom variant='subtitle1' component='h2'>
                {editar ? 'Editar' : 'Añadir'} Obrero
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <List>
                <ListItem>
                  <Grid
                    container
                    direction='row'
                    justify='flex-start'
                    alignItems='center'
                    spacing={2}
                  >
                    <Grid item xs={10}>
                      <SelectSearch
                        label='Obrero'
                        opciones={usuarios}
                        selected={usuario}
                        setSelected={(usuario) => this.setState({ usuario })}
                      />
                    </Grid>
                  </Grid>
                  <ListItemSecondaryAction>
                    <IconButton
                      edge='end'
                      aria-label='Guardar'
                      onClick={this.guardarObrero}
                      disabled={usuario === null}
                    >
                      <SaveIcon />
                    </IconButton>
                    <IconButton
                      edge='end'
                      aria-label='Cancelar'
                      onClick={() =>
                        this.setState({ usuario: null, editar: false })
                      }
                    >
                      <ClearIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
            </Grid>
            {/* Listado usuarios */}
            <Grid item xs={12}>
              <Typography gutterBottom variant='subtitle1' component='h2'>
                Obreros Asignados
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <List>
                {usuariosActividad?.map((u) => {
                  return (
                    <ListItem key={u.id}>
                      <Grid
                        container
                        display='flex'
                        direction='row'
                        justify='space-between'
                        alignItems='center'
                        spacing={2}
                      >
                        <Grid item xs={3}>
                          <FormControl fullWidth>
                            <TextField
                              label='CI'
                              type='number'
                              fullWidth
                              value={u.usuario.ci}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                          <FormControl fullWidth>
                            <TextField
                              label='Nombre'
                              fullWidth
                              value={u.usuario.nombre}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                          <FormControl fullWidth>
                            <TextField
                              label='Apellidos'
                              fullWidth
                              value={u.usuario.apellidos}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>

                      <ListItemSecondaryAction>
                        <IconButton
                          edge='end'
                          aria-label='Eliminar'
                          onClick={() => {
                            this.eliminarObrero(u);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                })}
              </List>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleClose}
              variant={'outlined'}
              color='primary'
            >
              Cancelar
            </Button>
            <Button
              onClick={this.handleSubmit}
              variant={'contained'}
              color='primary'
              disabled={loading || usuario}
            >
              {loading ? <CircularProgress /> : 'Guardar'}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  componentDidMount() {
    Axios({
      method: 'get',
      url: `${API_URL}/usuarios`,
      headers: {
        token: this.props.token,
      },
    })
      .then((res) => this.setState({ usuarios: res.data }))
      .catch((error) => console.log(error));
  }

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  guardarObrero = () => {
    const { usuariosActividad, usuario } = this.state;
    const { actividad } = this.props;

    var upd = usuariosActividad.filter((u) => u.usuario.ci !== usuario.ci);
    upd.push({
      actividadId: actividad?.id,
      usuario,
    });
    if (this.disponilbe(upd))
      this.setState({ usuariosActividad: upd, usuario: null, editar: false });
  };

  eliminarObrero = (usuario) => {
    const { usuariosActividad } = this.state;
    var upd = usuariosActividad.filter((m) => m.id !== usuario.id);
    this.setState({ usuariosActividad: upd });
  };

  disponilbe(participantes) {
    const { actividad, inicio, fin } = this.props;
    let disp = true;
    console.log(actividad.objetoObra?.obra?.estado);
    if (actividad.objetoObra?.obra?.estado === '2') {
      participantes.map((p) => {
        console.log(p);
        return p.usuario?.usuarios_actividads?.map((ua) => {
          if (
            moment(inicio).isBetween(
              moment(ua.actividad.inicio),
              moment(ua.actividad.fin)
            ) ||
            moment(fin).isBetween(
              moment(ua.actividad.inicio),
              moment(ua.actividad.fin)
            )
          ) {
            disp = false;
            alert(
              `El obrero: ${p.usuario.nombre} ${p.usuario.apellidos} está vinculado a una actividad que coincide con el rango de fechas actual`
            );
          }
          return false;
        });
      });
      return disp;
    }
    return true;
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
    this.props.getUsuariosActividad();
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    const { actividad, token } = this.props,
      actividadId = actividad.id;
    if (this.validator.allValid()) {
      this.state.usuariosActividad.map((ua) => {
        return Axios({
          method: 'post',
          url: `${API_URL}/usuarios_actividad`,
          data: {
            usuarioId: ua.usuario?.id,
            actividadId: actividadId,
          },
          headers: {
            token: token,
          },
        })
          .then(async () => {
            await this.props.getUsuariosActividad();
            this.setState({ open: false, loading: false });
          })
          .catch((error) => console.log(error));
      });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
      this.setState({ loading: false });
    }
  };
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    permissions: state.auth.permissions,
  };
};
export default connect(mapStateToProps)(ObreroForm);
