import React, { Component } from 'react';

import {
  Button,
  FormControl,
  Typography,
  Grid,
  TextField,
  Card,
  CardContent,
  CardActions,
  CircularProgress,
  Switch,
  FormControlLabel,
  InputLabel,
} from '@material-ui/core';
import Axios from 'axios';
import { connect } from 'react-redux';
import { API_URL } from '../../config';
import SimpleReactValidator from 'simple-react-validator';
import '../../locales/SimpleReactValidator';
import SelectSearch from '../../components/SelectSearch';
import VinculacionActividades from '../Actividades/ViculacionActividades';
import moment from 'moment';
import 'moment/locale/es';
import business from 'moment-business-time';

business.locale('es', {
  workinghours: {
    0: null,
    1: ['08:00:00', '12:00:00', '13:00:00', '17:00:00'],
    2: ['08:00:00', '12:00:00', '13:00:00', '17:00:00'],
    3: ['08:00:00', '12:00:00', '13:00:00', '17:00:00'],
    4: ['08:00:00', '12:00:00', '13:00:00', '17:00:00'],
    5: ['08:00:00', '12:00:00', '13:00:00', '17:00:00'],
    6: null,
  },
});

class ActividadEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.actividad.id,
      plan: props.actividad.plan,
      real: props.actividad.real,
      finalizada: props.actividad.finalizada,
      inicio: moment(props.actividad.inicio),
      fin: moment(props.actividad.fin),
      tipoActividad: props.actividad.tipoActividad,
      tipoActividadId: props.actividad.tipoActividadId,
      participantes: props.actividad?.usuarios_actividads?.map(
        (ua) => ua.usuario
      ),
      materialesActividad: props.actividad.materiales_actividads,
      materiales: [],
      material: null,
      editar: false,
      dialog: false,
      loading: false,
      usuarios: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validator = new SimpleReactValidator({ locale: 'es' });
  }

  render() {
    const {
      permissions,
      tipos,
      actividades,
      actividad,
      objeto,
      token,
    } = this.props;
    const {
      tipoActividad,
      plan,
      real,
      loading,
      finalizada,
      inicio,
      fin,
    } = this.state;

    return (
      <Card fullWidth elevation={4}>
        <CardContent>
          <Grid spacing={2}>
            {/* Formulario Actividad */}
            <Grid item xs={12}>
              <Typography gutterBottom variant='h6' component='h2'>
                Editar Actividad
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                direction='row'
                justify='flex-end'
                alignItems='flex-end'
                spacing={2}
              >
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <SelectSearch
                      label='Tipo de Actividad'
                      opciones={tipos}
                      selected={tipoActividad}
                      setSelected={(val) =>
                        this.setState({ tipoActividad: val })
                      }
                    />
                    <Typography align='center' color='error'>
                      {this.validator.message(
                        'tipoActividad',
                        tipoActividad,
                        'required'
                      )}
                    </Typography>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth>
                    <Typography align='center'>
                      U/M:{' '}
                      {tipoActividad && tipoActividad?.unidadMedida?.nombre}
                    </Typography>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth>
                    <Typography align='center'>
                      Tarifa: {tipoActividad && tipoActividad.tarifa}
                    </Typography>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth>
                    <TextField
                      autoFocus
                      margin='dense'
                      id='plan'
                      name='plan'
                      label='Plan'
                      type='number'
                      fullWidth
                      disabled={!tipoActividad}
                      value={plan}
                      onChange={this.handleCantidad}
                    />
                    <Typography align='center' color='error'>
                      {this.validator.message('plan', plan, 'required|numeric')}
                    </Typography>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth>
                    <TextField
                      autoFocus
                      margin='dense'
                      id='real'
                      name='real'
                      label='Real'
                      type='number'
                      fullWidth
                      disabled={!tipoActividad}
                      value={real}
                      onChange={this.handleChange}
                    />
                    <Typography align='center' color='error'>
                      {this.validator.message('real', real, 'required|numeric')}
                    </Typography>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    Duración de la Actividad{' '}
                    {(
                      ((tipoActividad?.horas || 0) * plan) /
                      (actividad.usuarios_actividads.length || 1)
                    ).toFixed(2)}{' '}
                    Hrs
                  </Typography>
                  <VinculacionActividades
                    tipoActividad={tipoActividad}
                    actividades={actividades}
                    inicio={inicio}
                    obraId={objeto.obraId}
                    token={token}
                    setInicio={(inicio) => this.handleInicio(inicio)}
                  />
                  <InputLabel>
                    Fin de la Actividad: {fin?.format('YYYY-MM-DD HH:mm')}
                  </InputLabel>
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    fullWidth
                    value='start'
                    control={
                      <Switch
                        checked={finalizada}
                        onChange={(event) =>
                          this.setState({ finalizada: event.target.checked })
                        }
                        color='primary'
                      />
                    }
                    label={
                      finalizada
                        ? 'Actividad Finalizada'
                        : 'Actividad en Proceso'
                    }
                    labelPlacement='end'
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Button
            onClick={this.handleSubmit}
            variant={'contained'}
            color='primary'
            disabled={!permissions.crearActividad || !tipoActividad || loading}
          >
            {loading ? <CircularProgress /> : 'Editar'}
          </Button>
        </CardActions>
      </Card>
    );
  }

  componentDidMount() {
    Axios({
      method: 'get',
      url: `${API_URL}/usuarios`,
      headers: {
        token: this.props.token,
      },
    })
      .then((res) => this.setState({ usuarios: res.data }))
      .catch((error) => console.log(error));
    Axios({
      method: 'get',
      url: `${API_URL}/materiales`,
      headers: {
        token: this.props.token,
      },
    })
      .then((res) => this.setState({ materiales: res.data }))
      .catch((error) => console.log(error));
  }

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    name !== 'cantidad'
      ? this.setState({ [name]: value })
      : value >= 0 && this.setState({ [name]: value });
  }

  handleCantidad = (event) => {
    const target = event.target;
    const value = target.value;
    const participantes = this.props.actividad.usuarios_actividads;
    const { inicio } = this.state;
    this.handleFin(inicio, value, participantes);
    this.setState({ plan: value });
  };

  handleInicio(inicio) {
    const { plan } = this.state;
    const participantes = this.props.actividad.usuarios_actividads;
    this.handleFin(inicio, plan, participantes);
    this.setState({ inicio });
  }

  handleFin(inicio, plan, participantes) {
    const { tipoActividad } = this.state;
    const start = inicio.format('YYYY-MM-DD HH:mm');
    const cantParticipantes = participantes.length;
    let addTime = 0;
    cantParticipantes === 0
      ? (addTime = plan * tipoActividad.horas)
      : (addTime = (plan * tipoActividad.horas) / cantParticipantes);
    let fin = business(start).addWorkingTime(addTime, 'hours');
    this.setState({ fin });
  }

  guardarMaterial = () => {
    const { materialesActividad, material } = this.state;
    var upd = materialesActividad.filter((m) => m.id !== material.id);
    upd.push(material);
    this.setState({ materialesActividad: upd });
  };

  eliminarMaterial = () => {
    const { materialesActividad, material } = this.state;
    var upd = materialesActividad.filter((m) => m.id !== material.id);
    this.setState({ materialesActividad: upd });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    const {
      id,
      tipoActividad,
      participantes,
      plan,
      real,
      finalizada,
      inicio,
      fin,
    } = this.state;
    const { objeto, token, getActividades } = this.props;
    if (this.validator.allValid()) {
      Axios({
        method: 'put',
        url: `${API_URL}/actividades/${id}`,
        data: {
          objetoObraId: objeto?.id,
          tipoActividadId: tipoActividad.id,
          plan,
          real,
          finalizada: finalizada ? 1 : 0,
          costo: plan * tipoActividad.tarifa,
          inicio,
          fin,
        },
        headers: {
          token: token,
        },
      })
        .then((act) => {
          participantes.map(async (user) => {
            return await Axios({
              method: 'post',
              url: `${API_URL}/usuarios_actividad`,
              data: {
                reporteId: act.data.id,
                usuarioId: user.id,
              },
              headers: {
                token: token,
              },
            }).catch((error) => console.log(error.message));
          });
          getActividades();
          this.setState({ loading: false });
        })
        .catch((err) => {
          console.log(err.message);
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
      this.setState({ loading: false });
    }
  };
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    permissions: state.auth.permissions,
    userId: state.auth.userId,
  };
};
export default connect(mapStateToProps)(ActividadEdit);
