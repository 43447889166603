import React, { Component } from 'react';

import {
  Button,
  DialogActions,
  TextField,
  DialogTitle,
  DialogContent,
  Dialog,
  FormControl,
  Typography,
  Grid,
  CircularProgress,
} from '@material-ui/core';
import Axios from 'axios';
import { connect } from 'react-redux';
import { API_URL } from '../../config';
import SimpleReactValidator from 'simple-react-validator';
import '../../locales/SimpleReactValidator';
import MensajeFlotante from '../../components/MensajeFlotante';
import SelectSearch from '../../components/SelectSearch';

class MaterialForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.material.id,
      nombre: props.material.nombre,
      unidadMedida: props.material.unidadMedida,
      tipoActividad: props.material.tipoActividad,
      cantidad: props.material.cantidad,
      open: false,
      tipos: [],
      unidades_medida: [],
      especialidades: [],
      especialidad: props.material.tipoActividad?.especialidad,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validator = new SimpleReactValidator({ locale: 'es' });
  }

  render() {
    const { permissions } = this.props;
    const { nombre, unidadMedida, open, unidades_medida, loading } = this.state;

    return !permissions.editarMaterial ? (
      <MensajeFlotante mensaje='Sin acceso' tipo='warning' />
    ) : (
      <div>
        <Button
          variant='contained'
          color='primary'
          onClick={this.handleClickOpen}
          m={2}
        >
          Editar
        </Button>
        <Dialog
          fullWidth
          open={open}
          onClose={this.handleClose}
          aria-labelledby='form-dialog-title'
        >
          <DialogTitle id='form-dialog-title'>Editar material</DialogTitle>
          <DialogContent>
            <Grid container direction='row' alignItems='flex-end' spacing={2}>
              {/* Nombre */}
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    autoFocus
                    margin='dense'
                    id='nombre'
                    name='nombre'
                    label='Nombre'
                    type='text'
                    fullWidth
                    value={nombre}
                    onChange={this.handleChange}
                  />
                  <Typography align='center' color='error'>
                    {this.validator.message(
                      'nombre',
                      nombre,
                      'required|string|min:4|max:50'
                    )}
                  </Typography>
                </FormControl>
              </Grid>
              {/* UM */}
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <SelectSearch
                    label='U/M'
                    opciones={unidades_medida}
                    selected={unidadMedida}
                    setSelected={(tipo) =>
                      this.setState({ unidadMedida: tipo })
                    }
                  />
                  <Typography align='center' color='error'>
                    {this.validator.message(
                      'unidadMedida',
                      unidadMedida,
                      'required'
                    )}
                  </Typography>
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleClose}
              variant={'outlined'}
              color='primary'
            >
              Cancelar
            </Button>
            <Button
              onClick={this.handleSubmit}
              variant={'contained'}
              color='primary'
              disabled={loading}
            >
              {loading ? <CircularProgress /> : 'Editar'}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  componentDidMount() {
    this.setState({ loading: true });
    const config = {
      headers: {
        token: this.props.token,
      },
    };
    Axios.get(API_URL + '/unidades_medida', config)
      .then((res) =>
        this.setState({ unidades_medida: res.data, loading: false })
      )
      .catch((error) => console.log(error));
  }

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    name !== 'cantidad'
      ? this.setState({ [name]: value })
      : value >= 0 && this.setState({ [name]: value });
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const {
      id,
      nombre,
      unidadMedidaId,
      cantidad,
      tipoActividadId,
    } = this.state;

    if (this.validator.allValid()) {
      Axios({
        method: 'put',
        url: `${API_URL}/materiales/${id}`,
        data: { nombre, unidadMedidaId, cantidad, tipoActividadId },
        headers: {
          token: this.props.token,
        },
      })
        .then(() => {
          this.props.getMateriales();
          this.setState({ open: false });
        })
        .catch((err) => console.log(err));
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    permissions: state.auth.permissions,
  };
};
export default connect(mapStateToProps)(MaterialForm);
