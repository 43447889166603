import React from 'react';
// import './App.css';
import * as XLSX from 'xlsx';
import MUIDataTable from 'mui-datatables';
import { textLabels } from '../../locales/DataTables';
import Axios from 'axios';
import { API_URL } from '../../config';
import { connect } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

class ExcelToJson extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      file: '',
      rows: [],
      cols: [],
    };
  }

  componentDidMount() {
    this.getEspecialidades();
    this.getUnidadesMedida();
    this.getTipos();
    this.getMateriales();
  }

  getEspecialidades = () => {
    this.setState({ loading: true });
    Axios({
      method: 'get',
      url: `${API_URL}/especialidades`,
      headers: { token: this.props.token },
    })
      .then((res) =>
        this.setState({
          loading: false,
          especialidades: res.data,
        })
      )
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err.message);
      });
  };

  getUnidadesMedida = () => {
    this.setState({ loading: true });
    Axios({
      method: 'get',
      url: `${API_URL}/unidades_medida`,
      headers: { token: this.props.token },
    })
      .then((res) =>
        this.setState({
          loading: false,
          unidadesMedida: res.data,
        })
      )
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err.message);
      });
  };

  getTipos = () => {
    this.setState({ loading: true });
    Axios({
      method: 'get',
      url: `${API_URL}/tipos_actividad`,
      headers: { token: this.props.token },
    })
      .then((res) =>
        this.setState({
          loading: false,
          tipos: res.data,
        })
      )
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err.message);
      });
  };

  getMateriales = () => {
    this.setState({ loading: true });
    Axios({
      method: 'get',
      url: `${API_URL}/materiales`,
      headers: { token: this.props.token },
    })
      .then((res) =>
        this.setState({
          loading: false,
          materiales: res.data,
        })
      )
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err.message);
      });
  };

  handleClick(e) {
    this.refs.fileUploader.click();
  }

  filePathset(e) {
    e.stopPropagation();
    e.preventDefault();
    var file = e.target.files[0];
    // console.log(file);
    this.setState({ file });
    // this.readFile();
    // console.log(this.state.file);
  }

  readFile() {
    this.setState({ loading: true });
    var f = this.state.file;
    // var name = f.name;
    const reader = new FileReader();
    reader.onload = (evt) => {
      // evt = on_file_select event
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      // const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      const data = XLSX.utils.sheet_to_row_object_array(ws, { header: 1 });
      // console.log(XLSX.utils.sheet_to_row_object_array(ws, { header: 1 }));
      /* Convert to Json */
      const JData = this.convertToJson(data);
      /* Update state */
      // JData.map((row) => console.log(row));
      this.setState({ rows: JData, loading: false });
    };
    reader.readAsBinaryString(f);
  }

  convertToJson(lines) {
    // var lines = csv.split('\n');

    var result = [];

    // var headers = lines[0].split(',');
    var headers = lines[0];

    for (var i = 1; i < lines.length; i++) {
      var obj = {};
      // var currentline = lines[i].split(',');
      var currentline = lines[i];

      for (var j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentline[j];
      }

      result.push(obj);
    }
    // console.log(headers, result);
    this.setState({
      cols: headers.map((col) => {
        // console.log(col);
        return { name: col, label: col };
      }),
    });
    return result; //JSON
  }

  guardarEspecialidades = () => {
    const { rows, especialidades } = this.state;
    const { token } = this.props;

    let esps = [];

    //llenar los arreglos con los valores a insertar
    rows.map(async (row) => {
      this.setState({ loading: true });
      //comprobar si existe la especialidad
      if (
        !especialidades.find(
          (element) => element.nombre === row.especialidad
        ) &&
        !esps.find((element) => element.nombre === row.especialidad) &&
        row.especialidad !== '' &&
        row.especialidad !== undefined
      ) {
        esps.push({ nombre: row.especialidad });
        Axios({
          method: 'post',
          url: `${API_URL}/especialidades`,
          data: { nombre: row.especialidad },
          headers: { token },
        })
          .then(() => {
            this.getEspecialidades();
            this.setState({ loading: false });
          })
          .catch((err) => {
            console.log(err);
            this.setState({ loading: false });
          });
      } else this.setState({ loading: false });
    });
  };

  guardarUms = () => {
    const { rows, unidadesMedida } = this.state;
    const { token } = this.props;
    let ums = [];

    //llenar los arreglos con los valores a insertar
    rows.map(async (row) => {
      this.setState({ loading: true });
      //comprobar si existe
      if (
        !unidadesMedida.find((element) => element.nombre === row.um_act) &&
        !ums.find((element) => element.nombre === row.um_act) &&
        row.um_act !== '' &&
        row.um_act !== undefined
      ) {
        ums.push({ nombre: row.um_act });
        Axios({
          method: 'post',
          url: `${API_URL}/unidades_medida`,
          data: { nombre: row.um_act, descripcion: row.um_act },
          headers: { token },
        })
          .then(() => {
            this.getUnidadesMedida();
            this.setState({ loading: false });
          })
          .catch((err) => {
            console.log(err);
            this.setState({ loading: false });
          });
      } else this.setState({ loading: false });

      //comprobar si existe
      if (
        !unidadesMedida.find((element) => element.nombre === row.um_act) &&
        !ums.find((element) => element.nombre === row.um_mat) &&
        row.um_mat !== '' &&
        row.um_mat !== undefined
      ) {
        ums.push({ nombre: row.um_mat });
        Axios({
          method: 'post',
          url: `${API_URL}/unidades_medida`,
          data: { nombre: row.um_mat, descripcion: row.um_mat },
          headers: { token },
        })
          .then(() => {
            this.getUnidadesMedida();
            this.setState({ loading: false });
          })
          .catch((err) => {
            console.log(err);
            this.setState({ loading: false });
          });
      } else this.setState({ loading: false });
    });
  };

  guardarTipos = () => {
    const { rows, especialidades, unidadesMedida, tipos } = this.state;
    const { token } = this.props;

    let tips = [];

    rows.map((row) => {
      this.setState({ loading: true });
      // comprobar si existe el Tipo de Actividad
      if (
        !tipos.find((element) => element.codigo === `${row.codigo}`) &&
        !tips.find((element) => element.codigo === `${row.codigo}`) &&
        row.codigo !== '' &&
        row.codigo !== undefined
      ) {
        let espId = especialidades.find(
          (element) => element.nombre === row.especialidad
        )?.id;
        let umId = unidadesMedida.find(
          (element) => element.nombre === row.um_act
        )?.id;
        tips.push({ codigo: `${row.codigo}` });
        Axios({
          method: 'post',
          url: `${API_URL}/tipos_actividad`,
          data: {
            codigo: row.codigo,
            nombre: row.tipo_actividad,
            horas: row.horas,
            tarifa: row.pu_act,
            unidadMedidaId: umId,
            especialidadId: espId,
          },
          headers: { token },
        })
          .then(() => {
            this.getTipos();
            this.setState({ loading: false });
          })
          .catch((err) => console.log(err));
      } else this.setState({ loading: false });
      return true;
    });
  };

  guardarMateriales = () => {
    const { rows, unidadesMedida, materiales } = this.state;
    const { token } = this.props;

    let mats = [];

    rows.map((row) => {
      this.setState({ loading: true });
      // comprobar si existe el Tipo de Actividad
      if (
        !materiales.find((element) => element.nombre === row.material) &&
        !mats.find((element) => element.nombre === row.material) &&
        row.material !== '' &&
        row.material !== 0 &&
        row.material !== undefined
      ) {
        let umId = unidadesMedida.find(
          (element) => element.nombre === row.um_mat
        )?.id;
        mats.push({ nombre: row.material });
        Axios({
          method: 'post',
          url: `${API_URL}/materiales`,
          data: {
            nombre: row.material,
            unidadMedidaId: umId,
          },
          headers: { token },
        })
          .then(() => {
            this.getMateriales();
            this.setState({ loading: false });
          })
          .catch((err) => console.log(err));
      } else this.setState({ loading: false });
      return true;
    });
    this.setState({ loading: false });
  };

  vincularMateriales = () => {
    const { rows, materiales, tipos } = this.state;
    const { token } = this.props;

    rows.map((row) => {
      this.setState({ loading: true });
      // comprobar si existe el Tipo de Actividad
      if (
        row.material !== '' &&
        row.material !== 'SEGÚN ESPECIFICACIÓN' &&
        row.material !== 0 &&
        row.material !== undefined
      ) {
        let matId = materiales.find(
          (element) => element.nombre === row.material
        )?.id;
        let actId = tipos.find((element) => element.codigo === `${row.codigo}`)
          ?.id;

        Axios({
          method: 'post',
          url: `${API_URL}/materiales_tipo_actividad`,
          data: {
            tipoActividadId: actId,
            materialId: matId,
            cantidad: row.cant_mat,
          },
          headers: { token },
        })
          .then(() => {
            this.setState({ loading: false });
          })
          .catch((err) => console.log(err));
      } else this.setState({ loading: false });
      return true;
    });
    this.setState({ loading: false });
  };

  render() {
    const { classes } = this.props;
    const { rows, cols, loading } = this.state;

    return (
      <div>
        <input
          type='file'
          id='file'
          ref='fileUploader'
          onChange={this.filePathset.bind(this)}
        />
        <button onClick={() => this.readFile()}>Cargar datos</button>
        <br />
        <br />
        <button onClick={() => this.guardarEspecialidades()}>
          Cargar Especialidades
        </button>
        <button onClick={() => this.guardarUms()}>
          Cargar Unidades de Medida
        </button>
        <button onClick={() => this.guardarTipos()}>
          Cargar Tipos de Actividad
        </button>
        <button onClick={() => this.guardarMateriales()}>
          Cargar Materiales
        </button>
        <button onClick={() => this.vincularMateriales()}>
          Vincular Materiales - Actividades
        </button>
        <br />
        <br />
        {loading ? (
          <div className={classes.centered}>
            Cargando... <br />
            <CircularProgress />
          </div>
        ) : (
          <MUIDataTable
            title={'Importación desde Excel'}
            data={rows}
            columns={cols}
            options={{
              filter: true,
              selectableRows: 'none',
              filterType: 'dropdown',
              responsive: 'vertical',
              rowsPerPage: 5,
              selectableRowsOnClick: true,
              rowsPerPageOptions: [5, 25, 50, 100],
              textLabels: textLabels,
              enableNestedDataAccess: '.',
            }}
          />
        )}
      </div>
    );
  }
}

const styles = (theme) => ({
  centered: {
    marginTop: theme.spacing(20),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
});

ExcelToJson.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return { token: state.auth.token };
};
export default connect(mapStateToProps)(withStyles(styles)(ExcelToJson));
