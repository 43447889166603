import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import {
  Button,
  CssBaseline,
  TextField,
  Link,
  Box,
  Typography,
  Container,
  CircularProgress,
  Card,
  CardContent,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import Background from '../../assets/login.jpg';
import Logo from '../../assets/logo.jpeg';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usuario: '',
      password: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = (event) => {
    const { usuario, password } = this.state;
    event.preventDefault();
    this.props.onAuth(usuario, password);
  };

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  render() {
    const { classes, error, authed } = this.props;
    const { usuario, password, loading } = this.state;

    let form = (
      <form className={classes.root} onSubmit={(e) => this.handleSubmit(e)}>
        <TextField
          margin='dense'
          id='usuario'
          name='usuario'
          label='Usuario'
          type='text'
          fullWidth
          required
          value={usuario}
          onChange={this.handleChange}
        />
        <TextField
          margin='dense'
          id='password'
          name='password'
          label='Contraseña'
          type='password'
          fullWidth
          required
          value={password}
          onChange={this.handleChange}
        />
        <Button
          type='submit'
          fullWidth
          variant='contained'
          color='primary'
          className={classes.submit}
        >
          Acceder
        </Button>
      </form>
    );

    let errorMessage = null;

    if (error) {
      errorMessage = <p style={{ color: 'red' }}>{error}</p>;
    }

    if (authed) {
      return <Redirect back />;
    }

    if (loading) {
      return (
        <div className={classes.centered}>
          <CircularProgress />
        </div>
      );
    }

    return (
      <div className={classes.background}>
        <Container component='main' maxWidth='sm'>
          <CssBaseline />
          <Card className={classes.paper}>
            <CardContent>
              <img src={Logo} alt='Logo' className={classes.avatar} />
            </CardContent>
            <CardContent>
              {errorMessage}
              {form}
            </CardContent>
          </Card>
          <Box className={classes.footer}>
            <Copyright />
          </Box>
        </Container>
      </div>
    );
  }
}

function Copyright() {
  return (
    <Typography variant='body2' color='textSecondary' align='center'>
      {'Copyright © '}
      <Link color='inherit' href='http://dreamsolutionsco.com/'>
        DreamSolutions
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const styles = (theme) => ({
  background: {
    marginVertical: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '100vh',
    background: `url(${Background})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '25vh',
    backgroundColor: addOpacity(brandColor, 0.6),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      marginTop: '15vh',
    },
  },
  input: {
    color: 'white',
  },
  avatar: {
    maxWidth: 250,
  },
  form: {
    width: '100%',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  footer: {
    position: 'relative',
    left: 0,
    bottom: 20,
    right: 0,
  },
});

const brandColor = 'rgba(255, 255, 255)';

function addOpacity(rgbString, opacity) {
  return rgbString.split(')')[0] + ',' + opacity + ')';
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    authed: state.auth.token != null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (user, password) => dispatch(actions.auth(user, password)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Login));
