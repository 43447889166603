import React, { Component } from 'react';
import { connect } from 'react-redux';
import { headerTitle } from '../../store/actions';
import Obras from '../Obras';

class Gestion extends Component {
  componentDidMount() {
    this.props.dispatch(headerTitle('Gestión'));
  }
  render() {
    return <Obras />;
  }
}
const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    rolId: state.auth.rolId,
    userId: state.auth.userId,
    permissions: state.auth.permissions,
  };
};

export default connect(mapStateToProps)(Gestion);
