import React from 'react';
import {
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import 'moment/locale/es';

const App = ({ setRange }) => {
  const [start, setStart] = React.useState(moment().startOf('month'));
  const [end, setEnd] = React.useState(moment().endOf('day'));

  const handlePicker = (e) => {
    const value = e.target.value;
    const id = e.target.id;
    if (id === 'start') {
      setStart(moment(value));
      setRange(value, end);
    } else {
      setEnd(moment(value));
      setRange(start, value);
    }
  };

  const setDates = (n) => {
    let { s, e } = {};
    switch (n) {
      case 0:
        s = moment().startOf('day');
        e = moment().endOf('day');
        break;
      case 1:
        s = moment().startOf('week');
        e = moment().endOf('week');
        break;
      case 2:
        s = moment().startOf('week').subtract(1, 'weeks');
        e = moment().endOf('week').subtract(1, 'weeks');
        break;
      case 3:
        s = moment().startOf('month');
        e = moment().endOf('month');
        break;
      case 4:
        s = moment().startOf('month').subtract(1, 'months');
        e = moment().endOf('month').subtract(1, 'months');
        break;
      case 5:
        s = moment().startOf('year');
        e = moment().endOf('year');
        break;
      case 6:
        s = moment().startOf('year').subtract(1, 'years');
        e = moment().endOf('year').subtract(1, 'years');
        break;

      default:
        s = moment().startOf('month');
        e = {};
        break;
    }
    setStart(s);
    setEnd(e);
    setRange(s, e);
  };

  return (
    <Card fullWidth elevation={4}>
      <CardContent>
        <Typography variant='h6'>Filtro de Fechas</Typography>
        <TextField
          fullWidth
          style={{ marginBottom: 10 }}
          id='start'
          label='Inicio'
          type='date'
          onChange={handlePicker}
          value={start.format('YYYY-MM-DD')}
        />
        <TextField
          fullWidth
          style={{ marginBottom: 10 }}
          id='end'
          label='Fin'
          type='date'
          onChange={handlePicker}
          value={end.format('YYYY-MM-DD')}
        />
        <Button fullWidth onClick={() => setDates(0)}>
          Hoy
        </Button>
        <Button fullWidth onClick={() => setDates(1)}>
          Esta semana
        </Button>
        <Button fullWidth onClick={() => setDates(2)}>
          Semana Pasada
        </Button>
        <Button fullWidth onClick={() => setDates(3)}>
          Este Mes
        </Button>
        <Button fullWidth onClick={() => setDates(4)}>
          Mes Pasado
        </Button>
        <Button fullWidth onClick={() => setDates(5)}>
          Este Año
        </Button>
        <Button fullWidth onClick={() => setDates(6)}>
          Año Pasado
        </Button>
      </CardContent>
    </Card>
  );
};

export default App;
