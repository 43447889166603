import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardMedia,
  CardActions,
  CardHeader,
  IconButton,
} from '@material-ui/core';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';

export default function ImageUploader(props) {
  const [foto, setFoto] = useState(props.foto);
  const [message, setMessage] = useState('Seleccione una foto');

  const handleFotoChange = (event) => {
    let foto = event.target.files[0];
    if (foto.size > 2 * 1024 * 1024) setMessage('La foto es mayor de 2 MB');
    else {
      setFoto(URL.createObjectURL(foto));
      props.setFoto(foto);
      setMessage(foto.name);
    }
  };

  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardHeader subheader={props.nombre} />
      <CardMedia className={classes.media} image={foto} />

      <CardActions>
        <input
          accept='image/*'
          className={classes.input}
          id='icon-button-file'
          type='file'
          onChange={handleFotoChange}
        />
        <label htmlFor='icon-button-file'>
          <IconButton
            color='primary'
            aria-label='upload picture'
            component='span'
          >
            <AddAPhotoIcon />
          </IconButton>
          {message}
        </label>
      </CardActions>
    </Card>
  );
}

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  input: {
    display: 'none',
  },
});
