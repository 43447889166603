import React, { Component, Fragment } from 'react';

import {
  Button,
  DialogActions,
  TextField,
  DialogTitle,
  DialogContent,
  Dialog,
  FormControl,
  Typography,
  Grid,
  CircularProgress,
} from '@material-ui/core';
import Axios from 'axios';
import { connect } from 'react-redux';
import { API_URL } from '../../config';
import SimpleReactValidator from 'simple-react-validator';
import '../../locales/SimpleReactValidator';
import MensajeFlotante from '../../components/MensajeFlotante';
import SelectSearch from '../../components/SelectSearch';

class MaterialForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nombre: '',
      unidadMedida: '',
      tipoActividad: '',
      cantidad: '',
      open: false,
      loading: false,
      tipos: [],
      unidades_medida: [],
      especialidades: [],
      especialidad: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validator = new SimpleReactValidator({ locale: 'es' });
  }

  render() {
    const { permissions } = this.props;
    const {
      nombre,
      unidadMedida,
      tipoActividad,
      cantidad,
      open,
      tipos,
      unidades_medida,
      especialidades,
      especialidad,
      loading,
    } = this.state;

    return !permissions.crearMaterial ? (
      <MensajeFlotante mensaje='Sin acceso' tipo='warning' />
    ) : (
      <div>
        <Button
          variant='contained'
          color='primary'
          onClick={this.handleClickOpen}
          m={2}
        >
          Nuevo
        </Button>
        <Dialog
          fullWidth
          open={open}
          onClose={this.handleClose}
          aria-labelledby='form-dialog-title'
        >
          <DialogTitle id='form-dialog-title'>Agregar material</DialogTitle>
          <DialogContent>
            <Grid container direction='row' alignItems='flex-end' spacing={2}>
              {/* Filtro Especialidades */}
              {especialidades && (
                <Grid item xs={12}>
                  <SelectSearch
                    label='Especialidad'
                    opciones={especialidades}
                    selected={especialidad}
                    setSelected={(esp) => this.setEspecialidad(esp)}
                  />
                </Grid>
              )}
              {especialidad && (
                <Fragment>
                  {/* Filtro Tipos de Actividad */}
                  <Grid item xs={12}>
                    <SelectSearch
                      label='Tipo de Actividad'
                      opciones={tipos}
                      selected={tipoActividad}
                      setSelected={(tipo) =>
                        this.setState({ tipoActividad: tipo })
                      }
                    />
                  </Grid>
                  {tipoActividad && (
                    <Fragment>
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            autoFocus
                            margin='dense'
                            id='nombre'
                            name='nombre'
                            label='Nombre'
                            type='text'
                            fullWidth
                            value={nombre}
                            onChange={this.handleChange}
                          />
                          <Typography align='center' color='error'>
                            {this.validator.message(
                              'nombre',
                              nombre,
                              'required|string|min:4|max:50'
                            )}
                          </Typography>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl fullWidth>
                          <SelectSearch
                            label='U/M'
                            opciones={unidades_medida}
                            selected={unidadMedida}
                            setSelected={(tipo) =>
                              this.setState({ unidadMedida: tipo })
                            }
                          />
                          <Typography align='center' color='error'>
                            {this.validator.message(
                              'unidadMedida',
                              unidadMedida,
                              'required'
                            )}
                          </Typography>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl fullWidth>
                          <TextField
                            margin='dense'
                            id='cantidad'
                            name='cantidad'
                            label='Cantidad'
                            type='text'
                            fullWidth
                            value={cantidad}
                            onChange={this.handleChange}
                          />
                          <Typography align='center' color='error'>
                            {this.validator.message(
                              'cantidad',
                              cantidad,
                              'required|numeric'
                            )}
                          </Typography>
                        </FormControl>
                      </Grid>
                    </Fragment>
                  )}
                </Fragment>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleClose}
              variant={'outlined'}
              color='primary'
            >
              Cancelar
            </Button>
            <Button
              onClick={this.handleSubmit}
              variant={'contained'}
              color='primary'
              disabled={loading || !tipoActividad}
            >
              {loading ? <CircularProgress /> : 'Agregar'}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  componentDidMount() {
    this.setState({ loading: true });
    const config = {
      headers: {
        token: this.props.token,
      },
    };
    Axios.get(API_URL + '/unidades_medida', config)
      .then((res) =>
        this.setState({ unidades_medida: res.data, loading: false })
      )
      .catch((error) => console.log(error));
    this.getEspecialidades();
  }

  getEspecialidades = async () => {
    this.setState({ loading: true });
    const { token } = this.props;
    await Axios({
      method: 'get',
      url: `${API_URL}/especialidades`,
      headers: {
        token: token,
      },
    })
      .then((res) =>
        this.setState({ especialidades: res.data, loading: false })
      )
      .catch((err) => console.log(err.message));
  };

  setEspecialidad = (especialidad) => {
    this.setState({ especialidad: especialidad });
    especialidad?.id && this.getTipos(especialidad.id);
  };

  getTipos(espId) {
    this.setState({ loading: true });
    const { token } = this.props;
    Axios({
      method: 'get',
      url: `${API_URL}/tipos_actividad/especialidad/${espId}`,
      headers: {
        token: token,
      },
    })
      .then((res) => {
        this.setState({ tipos: res.data, loading: false });
      })
      .catch((error) => console.log(error));
  }

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    name !== 'cantidad'
      ? this.setState({ [name]: value })
      : value >= 0 && this.setState({ [name]: value });
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    const { nombre, unidadMedida, cantidad, tipoActividad } = this.state;
    const { token } = this.props;

    if (this.validator.allValid()) {
      await Axios({
        method: 'post',
        url: `${API_URL}/materiales`,
        data: {
          nombre,
          cantidad,
          unidadMedidaId: unidadMedida.id,
          tipoActividadId: tipoActividad.id,
        },
        headers: {
          token: token,
        },
      })
        .then(() => {
          this.props.getMateriales();
          this.setState({ open: false, loading: false });
        })
        .catch((err) => console.log(err));
    } else {
      this.validator.showMessages();
      this.forceUpdate();
      this.setState({ loading: false });
    }
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
    this.props.getMateriales();
  };
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    permissions: state.auth.permissions,
  };
};
export default connect(mapStateToProps)(MaterialForm);
