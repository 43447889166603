import * as actionTypes from './actionTypes';
import Axios from 'axios';
import { API_URL } from '../../config';

export const obrasList = (obras) => {
  return {
    type: actionTypes.OBRAS_LIST,
    obras: obras,
  };
};

export const obrasOne = (obra) => {
  return {
    type: actionTypes.OBRAS_ONE,
    obra: obra,
  };
};

export const obrasDelLogueado = () => {
  return async (dispatch) => {
    const token = localStorage.getItem('token');
    await Axios({
      method: 'get',
      url: `${API_URL}/obras`,
      headers: {
        token: token,
      },
    })
      .then((res) => dispatch(obrasList(res?.data)))
      .catch((err) => console.log(err.res.data.message));
  };
};
