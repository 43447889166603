export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_PERMISSIONS = 'AUTH_PERMISSIONS';

export const HEADER_TITLE = 'HEADER_TITLE';

export const OBJETOS_LIST = 'OBJETOS_LIST';
export const OBJETOS_ONE = 'OBJETOS_ONE';

export const OBRAS_LIST = 'OBRAS_LIST';
export const OBRAS_ONE = 'OBRAS_ONE';

export const ACTIVIDADES_LIST = 'ACTIVIDADES_LIST';
export const ACTIVIDADES_ONE = 'ACTIVIDADES_ONE';
