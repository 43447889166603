import React, { Component, Fragment } from 'react';

import {
  Button,
  DialogActions,
  TextField,
  DialogTitle,
  DialogContent,
  Dialog,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import axios from 'axios';
import { connect } from 'react-redux';
import { API_URL } from '../../config';
import SimpleReactValidator from 'simple-react-validator';
import '../../locales/SimpleReactValidator';
import MensajeFlotante from '../../components/MensajeFlotante';

class ActividadEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.tipo.id,
      codigo: props.tipo.codigo,
      nombre: props.tipo.nombre,
      horas: props.tipo.horas,
      unidadMedidaId: props.tipo.unidadMedidaId,
      tarifa: props.tipo.tarifa,
      especialidadId: props.tipo.especialidadId,
      unidadesMedida: [],
      especialidades: [],
      open: false,
      loading: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validator = new SimpleReactValidator({ locale: 'es' });
  }

  render() {
    const { permissions } = this.props;

    return !permissions.crearTipoActividad ? (
      <MensajeFlotante mensaje='Sin acceso' tipo='warning' />
    ) : (
      <Fragment>
        <Button
          variant='contained'
          color='primary'
          onClick={this.handleClickOpen}
          m={2}
        >
          Editar
        </Button>
        <Dialog
          fullWidth
          maxWidth='sm'
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby='form-dialog-title'
        >
          <DialogTitle id='form-dialog-title'>
            Editar Tipo de Actividad
          </DialogTitle>
          <DialogContent>
            {/* codigo */}
            <FormControl fullWidth>
              <TextField
                autoFocus
                margin='dense'
                id='codigo'
                name='codigo'
                label='Código'
                type='numeric'
                fullWidth
                value={this.state.codigo}
                onChange={this.handleChange}
              />
              <Typography align='center' color='error'>
                {this.validator.message(
                  'codigo',
                  this.state.codigo,
                  'required|string|min:4|max:50'
                )}
              </Typography>
            </FormControl>
            {/* nombre */}
            <FormControl fullWidth>
              <TextField
                margin='dense'
                id='nombre'
                name='nombre'
                label='Nombre'
                type='text'
                fullWidth
                value={this.state.nombre}
                onChange={this.handleChange}
              />
              <Typography align='center' color='error'>
                {this.validator.message(
                  'nombre',
                  this.state.nombre,
                  'required|string|min:4|max:50'
                )}
              </Typography>
            </FormControl>
            {/* especialidad */}
            <FormControl fullWidth>
              <InputLabel id='especialidadIdlbl'>Especialidad</InputLabel>
              <Select
                labelId='especialidadIdlbl'
                id='especialidadId'
                name='especialidadId'
                value={this.state.especialidadId}
                onChange={this.handleChange}
              >
                {this.state.especialidades.map(function (item) {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.nombre}
                    </MenuItem>
                  );
                })}
              </Select>
              <Typography align='center' color='error'>
                {this.validator.message(
                  'especialidadId',
                  this.state.especialidadId,
                  'required'
                )}
              </Typography>
            </FormControl>
            {/* tarifa */}
            <FormControl fullWidth>
              <TextField
                margin='dense'
                id='tarifa'
                name='tarifa'
                label='Tarifa'
                type='text'
                fullWidth
                value={this.state.tarifa}
                onChange={this.handleChange}
              />
              <Typography align='center' color='error'>
                {this.validator.message(
                  'tarifa',
                  this.state.tarifa,
                  'required|numeric'
                )}
              </Typography>
            </FormControl>
            {/* um */}
            <FormControl fullWidth>
              <InputLabel id='unidadMedidaIdlbl'>Unidad de Medida</InputLabel>
              <Select
                labelId='unidadMedidaIdlbl'
                id='unidadMedidaId'
                name='unidadMedidaId'
                value={this.state.unidadMedidaId}
                onChange={this.handleChange}
              >
                {this.state.unidadesMedida.map(function (item) {
                  return <MenuItem value={item.value}>{item.display}</MenuItem>;
                })}
              </Select>
              <Typography align='center' color='error'>
                {this.validator.message(
                  'unidadMedidaId',
                  this.state.unidadMedidaId,
                  'required'
                )}
              </Typography>
            </FormControl>
            {/* horas */}
            <FormControl fullWidth>
              <TextField
                margin='dense'
                id='horas'
                name='horas'
                label='Horas'
                type='numeric'
                fullWidth
                value={this.state.horas}
                onChange={this.handleChange}
              />
              <Typography align='center' color='error'>
                {this.validator.message(
                  'horas',
                  this.state.horas,
                  'required|string|min:4|max:50'
                )}
              </Typography>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleClose}
              variant={'outlined'}
              color='primary'
            >
              Cancelar
            </Button>
            <Button
              onClick={this.handleSubmit}
              variant={'contained'}
              color='primary'
              disabled={this.state.loading}
            >
              {this.state.loading ? <CircularProgress /> : 'Editar'}
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }

  componentDidMount() {
    this.cargarSelectores();
  }

  cargarSelectores() {
    const config = {
      headers: {
        token: this.props.token,
      },
    };
    axios
      .get(`${API_URL}/unidades_medida`, config)
      .then((res) => {
        let unidadesMedida = res.data.map((item) => {
          return {
            value: item.id,
            display: item.nombre,
          };
        });
        this.setState({
          unidadesMedida: unidadesMedida,
        });
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(`${API_URL}/especialidades`, config)
      .then((res) => {
        this.setState({
          especialidades: res.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    name !== 'tarifa'
      ? this.setState({ [name]: value })
      : value >= 0 && this.setState({ [name]: value });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    const {
      id,
      codigo,
      nombre,
      horas,
      unidadMedidaId,
      tarifa,
      especialidadId,
    } = this.state;

    if (this.validator.allValid()) {
      axios({
        method: 'put',
        url: `${API_URL}/tipos_actividad/${id}`,
        data: {
          codigo,
          nombre,
          horas,
          unidadMedidaId,
          tarifa,
          especialidadId,
        },
        headers: {
          token: this.props.token,
        },
      })
        .then(() => {
          this.props.getTipos();
          this.setState({ open: false, loading: false });
        })
        .catch((err) => {
          console.log(err.message);
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
      this.setState({ loading: false });
    }
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
    this.props.getTipos();
  };
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    permissions: state.auth.permissions,
  };
};
export default connect(mapStateToProps)(ActividadEdit);
