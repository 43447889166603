export const textLabels = {
  body: {
    noMatch: 'No hay resultados para esa búsqueda',
    toolTip: 'Ordenar',
    columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
  },
  pagination: {
    next: 'Página siguiente',
    previous: 'Página anterior',
    rowsPerPage: 'Filas por página:',
    displayRows: 'de',
  },
  toolbar: {
    search: 'Buscar',
    downloadCsv: 'Descargar CSV',
    print: 'Imprimir',
    viewColumns: 'Ver Columnas',
    filterTable: 'Filtrar Tabla',
  },
  filter: {
    all: 'Todo',
    title: 'FILTROS',
    reset: 'RESTAURAR',
  },
  viewColumns: {
    title: 'Mostrar Columnas',
    titleAria: 'Mostrar/Esconder Columnas de la Tabla',
  },
  selectedRows: {
    text: 'fila(s) seleccionada(s)',
    delete: 'Eliminar',
    deleteAria: 'Eliminar Fila Seleccionada',
  },
};
