import React, { Fragment } from 'react';
import { TextField, CircularProgress } from '@material-ui/core';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';

const filter = createFilterOptions();

export default function SelectSearch({
  opciones,
  setSelected,
  selected,
  label,
  dialog,
  openDialog,
  granted,
  disabled = false,
}) {
  const [open, setOpen] = React.useState(false);
  const loading = open && opciones?.length === 0;

  const handleChange = (selected) => {
    openDialog && openDialog(false);
    if (selected && selected.inputValue && !selected.inputValue.id)
      openDialog && openDialog(true);
    else {
      setSelected(selected);
    }
  };
  return (
    <React.Fragment>
      <Autocomplete
        id='asynchronous-demo'
        open={open}
        disabled={disabled}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        onChange={(event, selected) => handleChange(selected)}
        getOptionSelected={(option, value) => option.nombre === value.nombre}
        getOptionLabel={(option) => {
          return option.apellidos
            ? option.nombre + ' ' + option.apellidos
            : option.material
            ? option.material.nombre
            : option.tipoActividad
            ? option.tipoActividad.codigo + ' ' + option.tipoActividad.nombre
            : option.nombre;
        }}
        options={opciones && opciones}
        value={selected}
        // loading={loading}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        freeSolo
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (params.inputValue !== '' && granted) {
            filtered.push({
              inputValue: params.inputValue,
              nombre: `¿Agregar ${label}?`,
            });
          }

          return filtered;
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            style={{ width: '100%' }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <Fragment>
                      Sin resultados{'  '}
                      <CircularProgress color='inherit' size={20} />
                    </Fragment>
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
      {dialog}
    </React.Fragment>
  );
}
