import React, { Component } from 'react';

import {
  Button,
  DialogActions,
  TextField,
  DialogTitle,
  DialogContent,
  Dialog,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import axios from 'axios';
import { connect } from 'react-redux';
import { API_URL } from '../../config';
import MensajeFlotante from '../../components/MensajeFlotante';
import SimpleReactValidator from 'simple-react-validator';
import '../../locales/SimpleReactValidator';

class UnidadMedidaForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nombre: '',
      descripcion: '',
      open: false,
      loading: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validator = new SimpleReactValidator({ locale: 'es' });
  }

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    const { nombre, descripcion } = this.state;
    const model = { nombre, descripcion };
    const config = {
      headers: {
        token: this.props.token,
      },
    };
    if (this.validator.allValid()) {
      axios
        .post(API_URL + '/unidades_medida', model, config)
        .then((res) => {
          this.props.getUnidadesMedida();
          this.setState({ open: false, loading: false });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
      this.setState({ loading: false });
    }
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { permissions } = this.props;

    return !permissions.crearUnidadMedida ? (
      <MensajeFlotante mensaje='Sin acceso' tipo='warning' />
    ) : (
      <div>
        <Button
          variant='contained'
          color='primary'
          onClick={this.handleClickOpen}
          m={2}
        >
          Nueva
        </Button>
        <Dialog
          fullWidth
          maxWidth='xs'
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby='form-dialog-title'
        >
          <DialogTitle id='form-dialog-title'>
            Agregar Unidad de Medida
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin='dense'
              id='nombre'
              name='nombre'
              label='Nombre'
              type='text'
              fullWidth
              value={this.state.nombre}
              onChange={this.handleChange}
            />
            <Typography align='center' color='error'>
              {this.validator.message('nombre', this.state.nombre, 'required')}
            </Typography>
            <TextField
              margin='dense'
              id='descripcion'
              name='descripcion'
              label='Descripción'
              type='text'
              fullWidth
              value={this.state.descripcion}
              onChange={this.handleChange}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleClose}
              variant={'outlined'}
              color='primary'
            >
              Cancelar
            </Button>
            <Button
              onClick={this.handleSubmit}
              variant={'contained'}
              color='primary'
              disabled={this.state.loading}
            >
              {this.state.loading ? <CircularProgress /> : 'Agregar'}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    permissions: state.auth.permissions,
  };
};
export default connect(mapStateToProps)(UnidadMedidaForm);
