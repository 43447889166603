import React, { Component } from 'react';
import Axios from 'axios';
import MUIDataTable from 'mui-datatables';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { headerTitle } from '../../store/actions';

import { textLabels } from '../../locales/DataTables';
import Dialog from '../../components/Dialog';
import UsuarioEdit from './Edit';
import UsuarioForm from './Form';
import {
  CircularProgress,
  Link,
  Typography,
  Avatar,
  Grid,
  Button,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { API_URL } from '../../config';
import MensajeFlotante from '../../components/MensajeFlotante';

const styles = (theme) => ({
  centered: {
    marginTop: theme.spacing(20),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
});

class Usuarios extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rows: [],
      fotos: [],
      columns: [
        { name: 'ci', label: 'CI' },
        { name: 'nombre', label: 'Nombre' },
        { name: 'apellidos', label: 'Apellidos' },
        { name: 'usuario', label: 'Usuario' },
        { name: 'rol.nombre', label: 'Rol' },
        {
          name: 'foto',
          options: {
            filter: false,
            sort: false,
            empty: true,
            customBodyRender: (value, tableMeta, updateValue) => {
              return value ? (
                <Typography>
                  <Link href={value} target='_blank' rel='noopener'>
                    <Avatar alt={value} src={value} />
                  </Link>
                </Typography>
              ) : (
                ''
              );
            },
          },
        },
      ],
      selectedRows: [],
      open: false,
      usuario: {
        id: null,
      },
      loading: false,
      sinAcceso: false,
      falloEliminar: false,
    };
  }

  render() {
    const { classes, permissions } = this.props;
    const {
      usuario,
      rows,
      columns,
      open,
      sinAcceso,
      loading,
      falloEliminar,
    } = this.state;

    return loading ? (
      <div className={classes.centered}>
        <CircularProgress />
      </div>
    ) : (
      <Grid
        container
        direction='row'
        justify='flex-start'
        alignItems='stretch'
        spacing={2}
      >
        {permissions.crearUsuario && (
          <Grid item>
            <UsuarioForm getUsuarios={this.getUsuarios} usuario={usuario} />
          </Grid>
        )}
        {permissions.editarUsuario && (
          <Grid item>
            {usuario.id === null ? (
              <Button disabled>Editar</Button>
            ) : (
              <UsuarioEdit getUsuarios={this.getUsuarios} usuario={usuario} />
            )}
          </Grid>
        )}
        {permissions.listarUsuario && (
          <Grid item xs={12}>
            <MUIDataTable
              title={'Listado de Usuarios'}
              data={rows}
              columns={columns}
              options={this.options}
            />
          </Grid>
        )}
        <Dialog
          fullWidth
          open={open}
          dialogTitle={'Eliminar Usuarios seleccionados'}
          dialogText={
            'ATENCIÓN! Esta acción es irreversible. ¿Desea continuar?'
          }
          handleClose={this.handleClose}
          handleConfirm={this.handleDelete}
        />
        {sinAcceso && (
          <MensajeFlotante
            mensaje='No tiene permisos para realizar esta acción'
            tipo='warning'
          />
        )}
        {falloEliminar && (
          <MensajeFlotante
            mensaje='No se ha podido eliminar el usuario porque está asociado a una o varias Obras como Jefe de Obra'
            tipo='error'
          />
        )}
      </Grid>
    );
  }

  componentDidMount() {
    this.getUsuarios();
    this.props.dispatch(headerTitle('Usuarios'));
  }

  getUsuarios = () => {
    this.setState({ loading: true });
    Axios({
      method: 'get',
      url: `${API_URL}/usuarios_all`,
      headers: {
        token: this.props.token,
      },
    })
      .then((res) =>
        this.setState({
          loading: false,
          rows: res.data,
          usuario: { id: null },
        })
      )
      .catch((err) => {
        console.log(err.message);
      });
  };

  handleDelete = () => {
    this.setState({ open: false });
    this.state.selected.map((row) => {
      return this.eliminarUsuario(this.state.rows[row.dataIndex].id);
    });
  };

  eliminarUsuario = (id) => {
    const config = {
      headers: {
        token: this.props.token,
      },
    };
    Axios.delete(API_URL + `/usuarios/${id}`, config)
      .then((res) => {
        console.log(res.data);
        this.setState({ falloEliminar: !res.data });
        this.getUsuarios();
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  handleClose = () => {
    this.setState({ open: false });
    this.getUsuarios();
  };

  sinAcceso() {
    this.setState({ sinAcceso: true });
    this.getUsuarios();
  }

  options = {
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'rowSelectionChange':
          this.setState({
            selected: tableState.selectedRows.data,
          });
          if (tableState.selectedRows.data.length === 1) {
            let data = this.state.rows[
              tableState.selectedRows.data[0].dataIndex
            ];
            this.setState({
              usuario: data,
            });
          } else
            this.setState({
              usuario: {
                id: null,
              },
            });
          break;
        case 'rowDelete':
          this.props.permissions.eliminarUsuario
            ? this.setState({ open: true })
            : this.sinAcceso();
          break;
        default:
          break;
      }
    },
    filter: true,
    selectableRows: 'multiple',
    filterType: 'dropdown',
    responsive: 'vertical',
    rowsPerPage: 5,
    selectableRowsOnClick: true,
    rowsPerPageOptions: [5, 10, 25, 50],
    textLabels: textLabels,
    enableNestedDataAccess: '.',
  };
}

Usuarios.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    permissions: state.auth.permissions,
  };
};
export default connect(mapStateToProps)(withStyles(styles)(Usuarios));
