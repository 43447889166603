import React, { Component } from 'react';

import {
  Button,
  DialogActions,
  DialogTitle,
  DialogContent,
  Dialog,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Grid,
  TextField,
  CircularProgress,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import Axios from 'axios';
import { connect } from 'react-redux';
import { API_URL } from '../../config';
import SimpleReactValidator from 'simple-react-validator';
import '../../locales/SimpleReactValidator';
import MensajeFlotante from '../../components/MensajeFlotante';
import SelectSearch from '../../components/SelectSearch';
import VinculacionActividades from '../Actividades/ViculacionActividades';
import MaterialesActividad from '../MaterialesActividad';
import UsuariosActividad from '../UsuariosActividad';

import moment from 'moment';
import 'moment/locale/es';
import business from 'moment-business-time';

business.locale('es', {
  workinghours: {
    0: null,
    1: ['08:00:00', '12:00:00', '13:00:00', '17:00:00'],
    2: ['08:00:00', '12:00:00', '13:00:00', '17:00:00'],
    3: ['08:00:00', '12:00:00', '13:00:00', '17:00:00'],
    4: ['08:00:00', '12:00:00', '13:00:00', '17:00:00'],
    5: ['08:00:00', '12:00:00', '13:00:00', '17:00:00'],
    6: null,
  },
});

class ActividadEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.actividad.id,
      plan: props.actividad.plan,
      real: props.actividad.real,
      inicio: moment(props.actividad.inicio),
      fin: moment(props.actividad.fin),
      finalizada: props.actividad.finalizada,
      objetoObraId: props.actividad.objetoObraId,
      tipoActividad: props.actividad.tipoActividad,
      especialidadId: props.actividad.tipoActividad.especialidadId,
      especialidades: [],
      tipos: [],
      open: false,
      loading: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validator = new SimpleReactValidator({ locale: 'es' });
  }

  render() {
    const { permissions, actividades, actividad, objeto, token } = this.props;
    const {
      open,
      tipoActividad,
      tipos,
      plan,
      real,
      especialidades,
      especialidadId,
      loading,
      finalizada,
      inicio,
      fin,
    } = this.state;
    return !permissions.editarActividad ? (
      <MensajeFlotante mensaje='Sin acceso' tipo='warning' />
    ) : (
      <div>
        <Button
          variant='contained'
          color='primary'
          onClick={this.handleClickOpen}
          m={2}
        >
          Editar
        </Button>
        <Dialog
          fullScreen
          open={open}
          onClose={this.handleClose}
          aria-labelledby='form-dialog-title'
          maxWidth='md'
        >
          <DialogTitle id='form-dialog-title'>Editar Actividad</DialogTitle>
          <DialogContent>
            <Grid
              container
              direction='row'
              justify='flex-start'
              alignItems='stretch'
              spacing={2}
            >
              {/* Especialidad */}
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id='especialidadIdlbl'>Especialidad</InputLabel>
                  <Select
                    labelId='especialidadIdlbl'
                    id='especialidadId'
                    name='especialidadId'
                    value={especialidadId}
                    onChange={this.handleChange}
                  >
                    {especialidades.map((item) => (
                      <MenuItem value={item.id}>{item.nombre}</MenuItem>
                    ))}
                  </Select>
                  <Typography align='center' color='error'>
                    {this.validator.message(
                      'especialidadId',
                      especialidadId,
                      'required'
                    )}
                  </Typography>
                </FormControl>
              </Grid>
              {/* Tipo de Actividad */}
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <SelectSearch
                    label='Tipo de Actividad'
                    opciones={tipos}
                    selected={tipoActividad}
                    setSelected={(val) =>
                      this.setState({
                        tipoActividad: val,
                        materialesActividad: val?.materials,
                      })
                    }
                  />
                  <Typography align='center' color='error'>
                    {this.validator.message(
                      'tipoActividad',
                      tipoActividad,
                      'required'
                    )}
                  </Typography>
                </FormControl>
              </Grid>
              {/* UM */}
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <InputLabel>U/M</InputLabel>
                  <Typography
                    align='center'
                    color='primary'
                    style={{ marginTop: 20 }}
                  >
                    {tipoActividad && tipoActividad?.unidadMedida?.nombre}
                  </Typography>
                </FormControl>
              </Grid>
              {/* Tarifa */}
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <InputLabel>Tarifa</InputLabel>
                  <Typography
                    align='center'
                    color='primary'
                    style={{ marginTop: 20 }}
                  >
                    {tipoActividad && tipoActividad.tarifa}
                  </Typography>
                </FormControl>
              </Grid>
              {/* Plan */}
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <TextField
                    autoFocus
                    margin='dense'
                    id='plan'
                    name='plan'
                    label='Plan'
                    type='number'
                    fullWidth
                    disabled={especialidadId == null}
                    value={plan}
                    onChange={this.handleCantidad}
                  />
                  <Typography align='center' color='error'>
                    {this.validator.message('plan', plan, 'required|numeric')}
                  </Typography>
                </FormControl>
              </Grid>
              {/* Real */}
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <TextField
                    autoFocus
                    margin='dense'
                    id='real'
                    name='real'
                    label='Real'
                    type='number'
                    fullWidth
                    disabled={especialidadId == null}
                    value={real}
                    onChange={this.handleChange}
                  />
                  <Typography align='center' color='error'>
                    {this.validator.message('real', real, 'required|numeric')}
                  </Typography>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  Duración de la Actividad{' '}
                  {(
                    ((tipoActividad?.horas || 0) * plan) /
                    (actividad.usuarios_actividads.length || 1)
                  ).toFixed(2)}{' '}
                  Hrs
                </Typography>
                <VinculacionActividades
                  tipoActividad={tipoActividad}
                  actividades={actividades}
                  obraId={objeto?.obraId}
                  token={token}
                  inicio={inicio}
                  setInicio={(inicio) => this.handleInicio(inicio)}
                />
                <FormControl fullWidth>
                  <InputLabel>
                    Fin de la Actividad: {fin?.format('YYYY-MM-DD HH:mm')}
                  </InputLabel>
                </FormControl>
              </Grid>
              {/* Finalizada */}
              <Grid item xs={12}>
                <FormControlLabel
                  fullWidth
                  value='start'
                  control={
                    <Switch
                      checked={finalizada}
                      onChange={(event) =>
                        this.setState({ finalizada: event.target.checked })
                      }
                      color='primary'
                    />
                  }
                  label={
                    finalizada ? 'Actividad Finalizada' : 'Actividad en Proceso'
                  }
                  labelPlacement='end'
                />
              </Grid>
              <Grid item xs={12}>
                <MaterialesActividad
                  actividad={actividad}
                  getMateriales={this.getMateriales}
                />
              </Grid>
              {inicio !== undefined && (
                <Grid item xs={12}>
                  <UsuariosActividad
                    inicio={inicio}
                    fin={fin}
                    actividad={actividad}
                    getMateriales={this.getUsuarios}
                  />
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleClose}
              variant={'outlined'}
              color='primary'
            >
              Cancelar
            </Button>
            <Button
              onClick={this.handleSubmit}
              variant={'contained'}
              color='primary'
              disabled={loading}
            >
              {loading ? <CircularProgress /> : 'Editar'}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  componentDidMount() {
    Axios({
      method: 'get',
      url: `${API_URL}/especialidades`,
      headers: {
        token: this.props.token,
      },
    })
      .then((res) => this.setState({ especialidades: res.data }))
      .catch((error) => console.log(error));

    Axios({
      method: 'get',
      url: `${API_URL}/objetos_obra`,
      headers: {
        token: this.props.token,
      },
    })
      .then((res) => this.setState({ objetosObra: res.data }))
      .catch((error) => console.log(error));

    Axios({
      method: 'get',
      url: `${API_URL}/usuarios`,
      headers: {
        token: this.props.token,
      },
    })
      .then((res) => this.setState({ usuarios: res.data }))
      .catch((error) => console.log(error));

    Axios({
      method: 'get',
      url: `${API_URL}/tipos_actividad`,
      headers: {
        token: this.props.token,
      },
    })
      .then((res) => this.setState({ tipos: res.data }))
      .catch((error) => console.log(error));
  }

  cargarTipos(esp) {
    Axios({
      method: 'get',
      url: `${API_URL}/tipos_actividad/especialidad/${esp}`,
      headers: {
        token: this.props.token,
      },
    })
      .then((res) => {
        this.setState({ tipos: res.data });
      })
      .catch((error) => console.log(error));
  }

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    name !== 'plan'
      ? this.setState({ [name]: value })
      : value >= 0 && this.setState({ [name]: value });

    name === 'especialidadId' && this.cargarTipos(value);
  }

  handleCantidad = (event) => {
    const target = event.target;
    const value = target.value;
    const participantes = this.props.actividad.usuarios_actividads;
    const { inicio } = this.state;
    this.handleFin(inicio, value, participantes);
    this.setState({ plan: value });
  };

  handleParticipantes(selecteds) {
    const { inicio, real } = this.state;
    this.handleFin(inicio, real, selecteds);
    if (this.disponilbe(selecteds)) this.setState({ participantes: selecteds });
  }

  disponilbe(participantes) {
    const { inicio, fin } = this.state;
    let disp = true;
    participantes.map((p) =>
      p.usuarios_actividads.map((ua) => {
        if (moment(inicio).isBefore(ua.fin) || moment(fin).isAfter(ua.inicio)) {
          disp = false;
          alert(
            `El obrero: ${p.nombre} ${p.apellidos} está vinculado a una actividad que coincide con el rango de fechas actual`
          );
        }
        return false;
      })
    );
    return disp;
  }

  handleInicio(inicio) {
    const { plan } = this.state;
    const participantes = this.props.actividad.usuarios_actividads;
    this.handleFin(inicio, plan, participantes);
    this.setState({ inicio });
  }

  handleFin(inicio, plan, participantes) {
    const { tipoActividad } = this.state;
    const start = inicio.format('YYYY-MM-DD HH:mm');
    const cantParticipantes = participantes.length;
    let addTime = 0;
    cantParticipantes === 0
      ? (addTime = plan * tipoActividad.horas)
      : (addTime = (plan * tipoActividad.horas) / cantParticipantes);
    let fin = business(start).addWorkingTime(addTime, 'hours');
    this.setState({ fin });
  }

  getMateriales = async () => {
    this.setState({ loading: true });
    const { actividad } = this.props,
      id = actividad?.id;

    await Axios({
      method: 'get',
      url: `${API_URL}/materiales_actividad/actividad/${id}`,
      headers: {
        token: this.props.token,
      },
    })
      .then((res) => {
        // console.log(res.data);
        this.setState({
          loading: false,
          rows: res.data,
          material: { id: null },
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err.message);
      });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    const {
      id,
      tipoActividad,
      plan,
      real,
      finalizada,
      inicio,
      fin,
    } = this.state;
    const { token, objetoObra } = this.props;
    if (this.validator.allValid()) {
      Axios({
        method: 'put',
        url: `${API_URL}/actividades/${id}`,
        data: {
          objetoObraId: objetoObra?.id,
          tipoActividadId: tipoActividad.id,
          plan,
          real,
          finalizada: finalizada ? 1 : 0,
          costo: plan * tipoActividad.tarifa,
          inicio,
          fin,
        },
        headers: {
          token: token,
        },
      })
        .then(() => {
          this.props.getActividades();
          this.setState({ open: false, loading: false });
        })
        .catch((err) => console.log(err.message));
    } else {
      this.validator.showMessages();
      this.forceUpdate();
      this.setState({ loading: false });
    }
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
    this.props.getActividades();
  };
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    permissions: state.auth.permissions,
    userId: state.auth.userId,
  };
};
export default connect(mapStateToProps)(ActividadEdit);
