import React, { Component } from 'react';

import {
  Button,
  DialogActions,
  TextField,
  DialogTitle,
  DialogContent,
  Dialog,
  FormControl,
  Typography,
  Grid,
  List,
  ListItem,
  IconButton,
  ListItemSecondaryAction,
  CircularProgress,
  Card,
  CardContent,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { API_URL } from '../../config';
import SimpleReactValidator from 'simple-react-validator';
import '../../locales/SimpleReactValidator';
import MensajeFlotante from '../../components/MensajeFlotante';
import SelectSearch from '../../components/SelectSearch';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ClearIcon from '@material-ui/icons/Clear';
import Axios from 'axios';

class MaterialForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nombre: '',
      unidadMedidaId: '',
      actividadId: '',
      cantidad: '',
      open: false,
      actividades: [],
      unidades_medida: [],
      materialesActividad: props.materialesActividad,
      materiales: [],
      material: null,
      tipos: [],
      tipo: null,
      editar: false,
      dialog: false,
      loading: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validator = new SimpleReactValidator({ locale: 'es' });
  }

  render() {
    const { permissions } = this.props;
    const {
      open,
      material,
      materiales,
      editar,
      materialesActividad,
    } = this.state;
    return !permissions.crearMaterialesActividad ? (
      <MensajeFlotante mensaje='Sin acceso' tipo='warning' />
    ) : (
      <div>
        <Button
          variant='outlined'
          color='primary'
          onClick={this.handleClickOpen}
          m={2}
        >
          Agregar Materiales
        </Button>
        <Dialog
          fullWidth
          maxWidth='md'
          open={open}
          onClose={this.handleClose}
          aria-labelledby='form-dialog-title'
        >
          <DialogTitle id='form-dialog-title'>
            {editar ? 'Editar' : 'Asignar'} Material
          </DialogTitle>
          <DialogContent>
            {/* formulario material */}
            <Card>
              <CardContent>
                <List>
                  <ListItem>
                    <Grid
                      container
                      direction='row'
                      justify='flex-start'
                      alignItems='center'
                      spacing={2}
                    >
                      <Grid item xs={10}>
                        <Typography variant='caption'>Material</Typography>
                        <SelectSearch
                          opciones={materiales}
                          selected={material}
                          setSelected={(material) =>
                            this.setState({ material })
                          }
                          openDialog={(opened) =>
                            this.setState({ dialog: opened })
                          }
                          granted={true}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant='caption'>U/M</Typography>
                        <FormControl fullWidth>
                          <TextField
                            fullWidth
                            value={material?.unidadMedida?.nombre}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={3}>
                        <FormControl fullWidth>
                          <Typography variant='caption'>Plan</Typography>
                          <TextField
                            type='number'
                            fullWidth
                            value={material?.cantidad || material?.plan}
                            onChange={(e) =>
                              e.target.value >= 0 &&
                              this.setState({
                                material: {
                                  ...material,
                                  plan: e.target.value,
                                },
                              })
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={3}>
                        <FormControl fullWidth>
                          <Typography variant='caption'>Real</Typography>
                          <TextField
                            type='number'
                            fullWidth
                            value={material?.real || ''}
                            onChange={(e) =>
                              e.target.value >= 0 &&
                              this.setState({
                                material: {
                                  ...material,
                                  real: e.target.value,
                                },
                              })
                            }
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <ListItemSecondaryAction>
                      <IconButton
                        edge='end'
                        aria-label='Guardar'
                        onClick={this.guardarMaterial}
                        disabled={
                          !material?.plan || parseFloat(material?.plan) === 0
                        }
                      >
                        <SaveIcon />
                      </IconButton>
                      <IconButton
                        edge='end'
                        aria-label='Cancelar'
                        onClick={() =>
                          this.setState({
                            material: {
                              id: null,
                              plan: '',
                              real: '',
                              unidadMedida: { nombre: '' },
                            },
                            editar: false,
                          })
                        }
                      >
                        <ClearIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              </CardContent>
            </Card>
            <br />
            {/* Listado materiales */}
            <TableContainer component={Paper}>
              <Table aria-label='simple table'>
                <TableHead>
                  <TableRow>
                    <TableCell>Materiales Asignados</TableCell>
                    <TableCell align='right'>U/M</TableCell>
                    <TableCell align='right'>Plan</TableCell>
                    <TableCell align='right'>Real</TableCell>
                    <TableCell align='center'>Acciones</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {materialesActividad?.map((row) => (
                    <TableRow key={row.name}>
                      <TableCell component='th' scope='row'>
                        {row.nombre}
                      </TableCell>
                      <TableCell align='right'>
                        {row.unidadMedida.nombre}
                      </TableCell>
                      <TableCell align='right'>
                        {row.plan || row.cantidad}
                      </TableCell>
                      <TableCell align='right'>{row.real || 0}</TableCell>
                      <TableCell align='center'>
                        <IconButton
                          edge='end'
                          aria-label='Editar'
                          onClick={() =>
                            this.setState({ editar: true, material: row })
                          }
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          edge='end'
                          aria-label='Eliminar'
                          onClick={() => {
                            this.eliminarMaterial(row);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleClose}
              variant={'outlined'}
              color='primary'
            >
              Cancelar
            </Button>
            <Button
              onClick={this.handleSubmit}
              variant={'contained'}
              color='primary'
              disabled={this.state.loading || material?.id}
            >
              {this.state.loading ? <CircularProgress /> : 'Guardar'}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  componentDidMount() {
    Axios({
      method: 'get',
      url: `${API_URL}/materiales`,
      headers: {
        token: this.props.token,
      },
    })
      .then((res) => this.setState({ materiales: res.data }))
      .catch((error) => console.log(error));
  }

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  guardarMaterial = () => {
    const { materialesActividad, material } = this.state;
    var upd = materialesActividad.filter((m) => m.nombre !== material.nombre);
    upd.push(material);
    this.setState({
      materialesActividad: upd,
      material: {
        id: null,
        cantidad: '',
        unidadMedida: { nombre: '' },
      },
      editar: false,
    });
  };

  eliminarMaterial = (material) => {
    const { materialesActividad } = this.state;
    var upd = materialesActividad.filter((m) => m.id !== material.id);
    this.setState({ materialesActividad: upd });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    const { actividad, token } = this.props,
      actividadId = actividad.id;
    if (this.validator.allValid()) {
      this.state.materialesActividad.map(async (material) => {
        return await Axios({
          method: 'post',
          url: `${API_URL}/materiales_actividad`,
          data: {
            nombre: material.nombre,
            plan: material.plan || material.cantidad,
            real: material.real || 0,
            unidadMedidaId: material.unidadMedidaId,
            actividadId: actividadId,
          },
          headers: {
            token: token,
          },
        })
          .then(() => this.handleClose())
          .catch((error) => console.log(error));
      });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
      this.setState({ loading: false });
    }
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
    this.props.getMateriales();
  };
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    permissions: state.auth.permissions,
  };
};
export default connect(mapStateToProps)(MaterialForm);
