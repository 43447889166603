import * as actionTypes from './actionTypes';

export const objetosList = (objetos) => {
  return {
    type: actionTypes.OBJETOS_LIST,
    objetos: objetos,
  };
};

export const objetosOne = (objeto) => {
  return {
    type: actionTypes.OBJETOS_ONE,
    objeto: objeto,
  };
};
