import React, { Component } from 'react';

import {
  Button,
  DialogActions,
  DialogTitle,
  DialogContent,
  Dialog,
  FormControl,
  InputLabel,
  Typography,
  Grid,
  TextField,
  List,
  ListItem,
  IconButton,
  ListItemSecondaryAction,
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Card,
  CardHeader,
  CardContent,
  CardActions,
} from '@material-ui/core';
import Axios from 'axios';
import { connect } from 'react-redux';
import { API_URL } from '../../config';
import SimpleReactValidator from 'simple-react-validator';
import '../../locales/SimpleReactValidator';
import MensajeFlotante from '../../components/MensajeFlotante';
import DialogMaterial from '../Planificacion/DialogMaterial';

import SelectSearch from '../../components/SelectSearch';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ClearIcon from '@material-ui/icons/Clear';
import MultipleSelect from '../../components/MultiSelect';
import VinculacionActividades from './ViculacionActividades';

import moment from 'moment';
import 'moment/locale/es';
import business from 'moment-business-time';

business.locale('es', {
  workinghours: {
    0: null,
    1: ['08:00:00', '12:00:00', '13:00:00', '17:00:00'],
    2: ['08:00:00', '12:00:00', '13:00:00', '17:00:00'],
    3: ['08:00:00', '12:00:00', '13:00:00', '17:00:00'],
    4: ['08:00:00', '12:00:00', '13:00:00', '17:00:00'],
    5: ['08:00:00', '12:00:00', '13:00:00', '17:00:00'],
    6: null,
  },
});

class ActividadForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      especialidades: [],
      especialidad: null,
      tipos: [],
      tipoActividad: null,
      cantidad: '',
      inicio: business().nextWorkingTime(),
      fin: business().lastWorkingTime(),
      participantes: [],
      materialesActividad: [],
      materiales: [],
      material: null,
      editar: false,
      dialog: false,
      loading: false,
      usuarios: [],
      open: false,
    };
    this.handleCantidad = this.handleCantidad.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validator = new SimpleReactValidator({ locale: 'es' });
  }

  render() {
    const { permissions, actividades, objeto, token } = this.props;
    const {
      especialidades,
      especialidad,
      tipos,
      tipoActividad,
      usuarios,
      cantidad,
      participantes,
      materiales,
      materialesActividad,
      material,
      dialog,
      editar,
      loading,
      open,
      inicio,
      fin,
    } = this.state;

    return !permissions.crearActividad ? (
      <MensajeFlotante mensaje='Sin acceso' tipo='warning' />
    ) : (
      <div>
        <Button
          variant='contained'
          color='primary'
          onClick={() => this.setState({ open: true })}
          m={2}
        >
          Nueva
        </Button>
        <Dialog
          fullScreen
          open={open}
          onClose={this.handleClose}
          aria-labelledby='form-dialog-title'
          maxWidth='md'
        >
          <DialogTitle id='form-dialog-title'>Agregar actividad</DialogTitle>
          <DialogContent>
            <Grid spacing={2}>
              {/* Formulario Actividad */}
              <Grid item xs={12}>
                <Grid
                  container
                  direction='row'
                  justify='flex-end'
                  alignItems='flex-end'
                  spacing={2}
                >
                  {/* Filtro Especialidades */}
                  <Grid item xs={12}>
                    <SelectSearch
                      label='Especialidad'
                      opciones={especialidades}
                      selected={especialidad}
                      setSelected={(esp) => this.setEspecialidad(esp)}
                    />
                  </Grid>
                  {/* Tipo de Actividad */}
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      {tipos.length > 0 ? (
                        <SelectSearch
                          label='Tipo de Actividad'
                          opciones={tipos}
                          selected={tipoActividad}
                          setSelected={(val) =>
                            this.setState({
                              tipoActividad: val,
                              materialesActividad:
                                val?.materiales_tipoActividads,
                            })
                          }
                        />
                      ) : (
                        'Seleccione una especialidad'
                      )}

                      <Typography align='center' color='error'>
                        {this.validator.message(
                          'tipoActividad',
                          tipoActividad,
                          'required'
                        )}
                      </Typography>
                    </FormControl>
                  </Grid>
                  {/* U/M */}
                  <Grid item xs={2}>
                    <FormControl fullWidth>
                      <Typography>
                        U/M:{' '}
                        {tipoActividad && tipoActividad?.unidadMedida?.nombre}
                      </Typography>
                    </FormControl>
                  </Grid>
                  {/* Tarifa */}
                  <Grid item xs={2}>
                    <FormControl fullWidth>
                      <Typography>
                        Tarifa:
                        {tipoActividad &&
                          '$' + tipoActividad.tarifa?.toFixed(2)}
                      </Typography>
                    </FormControl>
                  </Grid>
                  {/* Cantidad */}
                  <Grid item xs={2}>
                    <FormControl fullWidth>
                      <TextField
                        margin='dense'
                        id='cantidad'
                        name='cantidad'
                        label='Cantidad'
                        type='number'
                        fullWidth
                        disabled={!tipoActividad}
                        value={cantidad}
                        onChange={this.handleCantidad}
                        style={{ marginBottom: 10 }}
                      />
                      <Typography align='center' color='error'>
                        {this.validator.message(
                          'cantidad',
                          cantidad,
                          'numeric|min:0,num'
                        )}
                      </Typography>
                    </FormControl>
                  </Grid>
                  {/* Participantes */}
                  <Grid item xs={6}>
                    <InputLabel id='participanteslbl'>Participantes</InputLabel>
                    <FormControl fullWidth>
                      <MultipleSelect
                        opciones={usuarios}
                        selecteds={participantes}
                        setSelecteds={(selecteds) =>
                          this.handleParticipantes(selecteds)
                        }
                        disabled={!tipoActividad}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Card style={{ marginTop: 20 }}>
                <CardHeader
                  subheader={`Duración de la Actividad ${(
                    ((tipoActividad?.horas || 0) * cantidad) /
                    (participantes.length || 1)
                  ).toFixed(2)} Hrs`}
                />
                <VinculacionActividades
                  tipoActividad={tipoActividad}
                  actividades={actividades}
                  obraId={objeto?.obraId}
                  token={token}
                  inicio={inicio}
                  setInicio={(inicio) => this.handleInicio(inicio)}
                />
                {/* Fecha Fin */}
                <CardContent>
                  <FormControl fullWidth>
                    <InputLabel>
                      Fin de la Actividad: {fin?.format('YYYY-MM-DD HH:mm')}
                    </InputLabel>
                  </FormControl>
                </CardContent>
              </Card>
              {/* formulario material */}
              {tipoActividad && (
                <Card style={{ marginTop: 20 }}>
                  <CardHeader
                    subheader={editar ? 'Editar Material' : 'Añadir Material'}
                  />
                  <CardContent>
                    <List>
                      <ListItem>
                        <Grid
                          container
                          direction='row'
                          justify='flex-start'
                          alignItems='center'
                          spacing={2}
                        >
                          <Grid item xs={10}>
                            <Typography>Material</Typography>
                            <SelectSearch
                              label='Material'
                              opciones={materiales}
                              selected={material}
                              setSelected={(material) =>
                                this.setState({ material })
                              }
                              openDialog={(opened) =>
                                this.setState({ dialog: opened })
                              }
                              dialog={
                                <DialogMaterial
                                  getMateriales={this.getMateriales}
                                  tipo={tipoActividad}
                                  open={dialog}
                                  handleClose={() =>
                                    this.setState({ dialog: false })
                                  }
                                />
                              }
                              granted={true}
                            />
                          </Grid>
                          <Grid item xs={5}>
                            <Typography>U/M</Typography>
                            <FormControl fullWidth>
                              <TextField
                                fullWidth
                                value={
                                  material?.unidadMedida?.nombre ||
                                  material?.material?.unidadMedida?.nombre
                                }
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={5}>
                            <Typography>Cantidad</Typography>
                            <FormControl fullWidth>
                              <TextField
                                type='number'
                                fullWidth
                                max={2}
                                value={material?.cantidad || 0}
                                onChange={(e) =>
                                  e.target.value >= 0 &&
                                  this.setState({
                                    material: {
                                      ...material,
                                      cantidad: e.target.value,
                                    },
                                  })
                                }
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                        <ListItemSecondaryAction>
                          <IconButton
                            edge='end'
                            aria-label='Guardar'
                            onClick={material?.id && this.guardarMaterial}
                            disabled={!material?.id}
                          >
                            <SaveIcon />
                          </IconButton>
                          <IconButton
                            edge='end'
                            aria-label='Cancelar'
                            onClick={() =>
                              this.setState({
                                material: {
                                  id: null,
                                  cantidad: '',
                                  unidadMedida: { nombre: '' },
                                  material: { unidadMedida: { nombre: '' } },
                                },
                                editar: false,
                              })
                            }
                          >
                            <ClearIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    </List>
                  </CardContent>
                  <CardActions>
                    {/* Listado materiales */}
                    {tipoActividad && (
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Materiales Asignados</TableCell>
                              <TableCell align='right'>U/M</TableCell>
                              <TableCell align='right'>Plan</TableCell>
                              <TableCell align='center'></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {materialesActividad?.map((row) => (
                              <TableRow key={row.name}>
                                <TableCell component='th' scope='row'>
                                  {row.nombre || row.material?.nombre}
                                </TableCell>
                                <TableCell align='right'>
                                  {row.unidadMedida?.nombre ||
                                    row.material?.unidadMedida?.nombre}
                                </TableCell>
                                <TableCell align='right'>
                                  {row.cantidad}
                                </TableCell>
                                <TableCell align='center'>
                                  <IconButton
                                    edge='end'
                                    aria-label='Editar'
                                    onClick={() =>
                                      this.setState({
                                        editar: true,
                                        material: row,
                                      })
                                    }
                                  >
                                    <EditIcon />
                                  </IconButton>
                                  <IconButton
                                    edge='end'
                                    aria-label='Eliminar'
                                    onClick={() => {
                                      this.eliminarMaterial(row);
                                    }}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}
                  </CardActions>
                </Card>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleClose}
              variant={'outlined'}
              color='primary'
            >
              Cancelar
            </Button>
            <Button
              onClick={this.handleSubmit}
              variant={'contained'}
              color='primary'
              disabled={loading || !tipoActividad || material?.id}
            >
              {loading ? <CircularProgress /> : 'Agregar'}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  componentDidMount() {
    this.getEspecialidades();
    this.getMateriales();
    this.getUsuarios();
  }

  getEspecialidades = () =>
    Axios({
      method: 'get',
      url: `${API_URL}/especialidades`,
      headers: {
        token: this.props.token,
      },
    })
      .then((res) => this.setState({ especialidades: res.data }))
      .catch((error) => console.log(error));

  getMateriales = () =>
    Axios({
      method: 'get',
      url: `${API_URL}/materiales`,
      headers: {
        token: this.props.token,
      },
    })
      .then((res) => this.setState({ materiales: res.data }))
      .catch((error) => console.log(error));

  getUsuarios = () =>
    Axios({
      method: 'get',
      url: `${API_URL}/usuarios`,
      headers: {
        token: this.props.token,
      },
    })
      .then((res) => this.setState({ usuarios: res.data }))
      .catch((error) => console.log(error));

  setEspecialidad = (especialidad) => {
    this.setState({
      loading: true,
      especialidad: especialidad,
      actividad: null,
      reportes: false,
    });
    especialidad?.id
      ? this.getTipos(especialidad.id)
      : this.setState({ loading: false });
  };

  getTipos(espId) {
    const { token } = this.props;
    this.setState({ loading: true });
    Axios({
      method: 'get',
      url: `${API_URL}/tipos_actividad/especialidad/${espId}`,
      headers: {
        token: token,
      },
    })
      .then((res) => {
        this.setState({ tipos: res.data, loading: false });
      })
      .catch((error) => console.log(error));
  }

  handleCantidad(event) {
    const target = event.target;
    const value = target.value;
    const { inicio, participantes } = this.state;
    this.handleFin(inicio, value, participantes);
    this.setState({ cantidad: value });
  }

  handleParticipantes(selecteds) {
    const { inicio, cantidad } = this.state;
    this.handleFin(inicio, cantidad, selecteds);
    if (this.disponilbe(selecteds)) this.setState({ participantes: selecteds });
  }

  disponilbe(participantes) {
    const { inicio, fin } = this.state;
    let disp = true;
    if (this.props.objeto.obra.estado === '2') {
      participantes.map((p) =>
        p.usuarios_actividads.map((ua) => {
          if (
            moment(inicio).isBetween(
              moment(ua.actividad.inicio),
              moment(ua.actividad.fin)
            ) ||
            moment(fin).isBetween(
              moment(ua.actividad.inicio),
              moment(ua.actividad.fin)
            )
          ) {
            disp = false;
            alert(
              `El obrero: ${p.nombre} ${p.apellidos} está vinculado a una actividad que coincide con el rango de fechas actual`
            );
          }
          return false;
        })
      );
      return disp;
    }
    return true;
  }

  handleInicio(inicio) {
    const { participantes, cantidad } = this.state;
    this.handleFin(inicio, cantidad, participantes);
    this.setState({ inicio });
  }

  handleFin(inicio, cantidad, participantes) {
    const { tipoActividad } = this.state;
    const start = inicio.format('YYYY-MM-DD HH:mm');
    const cantParticipantes = participantes.length;
    let addTime = 0;
    cantParticipantes === 0
      ? (addTime = cantidad * tipoActividad.horas)
      : (addTime = (cantidad * tipoActividad.horas) / cantParticipantes);
    let fin = business(start).addWorkingTime(addTime, 'hours');
    this.setState({ fin });
  }

  handleClose = () => {
    this.setState({ open: false });
    this.props.getActividades();
  };

  guardarMaterial = () => {
    const { materialesActividad, material } = this.state;
    var upd = materialesActividad.filter((m) => m.nombre !== material.nombre);
    upd.push(material);
    this.setState({
      materialesActividad: upd,
      material: {
        id: null,
        cantidad: '',
        unidadMedida: { nombre: '' },
      },
      editar: false,
    });
  };

  eliminarMaterial = (material) => {
    const { materialesActividad } = this.state;
    var upd = materialesActividad.filter((m) => m.id !== material.id);
    this.setState({
      materialesActividad: upd,
      material: {
        cantidad: '',
        unidadMedida: { nombre: '' },
      },
    });
  };

  handleCantidadMultiple = (event) => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    this.setState({ participantes: value });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ loading: true });

    const {
      tipoActividad,
      participantes,
      cantidad,
      materialesActividad,
      especialidad,
      inicio,
      fin,
    } = this.state;
    const { objeto, token, getActividades } = this.props;
    if (tipoActividad?.especialidadId !== especialidad?.id) {
      alert(
        'La actividad seleccionada no pertenece a la Especialidad seleccionada'
      );
      this.setState({ loading: false });
    } else if (cantidad === '' || cantidad === 0) {
      alert(
        `La actividad no debe tener un cantidad de 0 ${tipoActividad.unidadMedida.nombre}`
      );
      this.setState({ loading: false });
    } else if (this.validator.allValid()) {
      await Axios({
        method: 'post',
        url: `${API_URL}/actividades`,
        data: {
          objetoObraId: objeto?.id,
          tipoActividadId: tipoActividad.id,
          plan: cantidad,
          real: 0,
          costo: cantidad * tipoActividad.tarifa,
          inicio,
          fin,
        },
        headers: {
          token: token,
        },
      })
        .then(async (act) => {
          participantes.map(async (user) => {
            return await Axios({
              method: 'post',
              url: `${API_URL}/usuarios_actividad`,
              data: {
                actividadId: act.data.id,
                usuarioId: user.id,
              },
              headers: {
                token: token,
              },
            }).catch((error) => console.log(error.message));
          });
          materialesActividad.map(async (mta) => {
            return await Axios({
              method: 'post',
              url: `${API_URL}/materiales_actividad`,
              data: {
                nombre: mta.material?.nombre || mta.nombre,
                plan: mta.cantidad * cantidad,
                real: 0,
                unidadMedidaId:
                  mta.material?.unidadMedidaId || mta.unidadMedidaId,
                actividadId: act.data.id,
              },
              headers: {
                token: token,
              },
            }).catch((error) => console.log(error.message));
          });
          this.setState({
            cantidad: 0,
            tipoActividad: null,
            participantes: [],
            materialesActividad: [],
            material: null,
            editar: false,
            dialog: false,
          });
          this.validator.hideMessages();
          await getActividades();
          this.setState({ loading: false, open: false });
        })
        .catch((err) => console.log(err.message));
    } else {
      this.validator.showMessages();
      this.forceUpdate();
      this.setState({ loading: false });
    }
  };
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    permissions: state.auth.permissions,
    userId: state.auth.userId,
  };
};
export default connect(mapStateToProps)(ActividadForm);
